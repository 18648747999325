import { useTheme } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core";
import React, { FC, useState } from "react";

export interface IDialogProps {
  title?: String;
  text?: String;
  buttonAgree?: String;
  buttonDesagree?: String;
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const DialogModal: FC<IDialogProps> = ({
  title,
  text,
  buttonAgree,
  buttonDesagree,
  open,
  onOk,
  onCancel,
}) => {
  const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      //fullScreen={fullScreen}
      open={open}
      onClose={onCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonDesagree && (
          <Button onClick={onCancel} color="primary" variant="contained">
            {buttonDesagree}
          </Button>
        )}
        {buttonAgree && (
          <Button onClick={onOk} color="primary" variant="contained">
            {buttonAgree}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogModal;
