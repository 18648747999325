import {
  Button,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory } from "react-router-dom";

const StoreKO: FC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <div style={{ textAlign: "center" }}>
      <CancelIcon color="error" className={classes.checkIcon} />
      <Typography variant="h5">{t("storeScreen.errorOrder")}</Typography>
      <Typography variant="subtitle1">
        {t("storeScreen.errorOrderText")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/login")}
      >
        Volver
      </Button>
    </div>
  );
};

export default StoreKO;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkIcon: {
      width: "14rem",
      height: "auto",
    },
  })
);
