import React, {FC, useEffect, useMemo, useState} from "react";
import {
    Card,
    CardActions,
    CardHeader,
    CardMedia,
    createStyles, DialogContentText,
    Grid,
    makeStyles,
    Theme, Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { red } from "@material-ui/core/colors";
import {useLazyQuery, useQuery} from "@apollo/client";
import {CHECK_DATA_PROCESSED, GET_POSITIONS_QUERY} from "../lib/queries";
import { convertStupidCoordinate } from "../Services/Utils";
import MapContainer from "./Map";
import useAuth from "../Services/Auth";
import RemoveMatchDialog from "./removeMatchDialog";
import background from "../lib/assets/match-item-background.png";
import useAdmin from "../Services/Admin";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {animated, Keyframes} from "react-spring/renderprops";

export interface IMatchItem {
  name: string;
  matchId: Number;
  userId: Number;
  date: string;
  team1Score: number;
  team2Score: number;
  onClick: () => void;
  onDelete: () => void;
}

const MatchItem: FC<IMatchItem> = ({
  name,
  matchId,
  userId,
  date,
  team1Score,
  team2Score,
  onClick,
  onDelete,
}) => {
    const {t} = useTranslation();
  const classes = useStyles();
  const auth = useAuth();
  const location = useLocation();

  const admin = location && location.state && location.state.id;

  let year = new Date(date).getFullYear();
  let month = new Date(date).getMonth();
  let day = new Date(date).getDate();
  let hour = new Date(date).getHours();
  let min = new Date(date).getMinutes();
  let sec = new Date(date).getSeconds();
  let segundos = dayjs(Date.UTC(year, month, day, hour, min, sec)).valueOf();
  let parsedDate = dayjs(new Date(segundos)).format("YYYY-MM-DD HH:mm:ss");

  const [removeDialog, setRemoveDialog] = useState(false);

    const Content = Keyframes.Spring(async (next) => {
        while (true) {
            await next({
                from: { opacity: 0 },
                opacity: 1,
            });
            await next({
                from: { opacity: 1 },
                opacity: 0,
            });
        }
    });
        /*
            Función CHECK_DATA_PROCESSED (match.queries.ts) consulta a graphiQl
            en useLazyQuery junto con pollInterval hace que la función se ejecute que cada 10 seg.
         */
    const [
        checkDataProcessed,
        {data: dataProcessed, stopPolling: stopCheckDataProcessed},
    ] = useLazyQuery(CHECK_DATA_PROCESSED, {
        variables: {matchId: matchId, userId: userId},
        pollInterval: 10000,
    });
    /*
        dataProcessed devuelve valores númericos para saber en que estado está el procesado
        0- No procesado o no ha empezado a procesarse
        1- Procesando
        2- Procesado
        3- Procesado incompleto o erróneo
     */

    useEffect(() => {
        if(dataProcessed && dataProcessed.checkdataprocessed != 1){
            stopCheckDataProcessed();
        }else {
            checkDataProcessed()

        }

    }, [dataProcessed]);


  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <RemoveMatchDialog
        name={date}
        open={removeDialog}
        onOk={() => {
          setRemoveDialog(false);
          onDelete();
        }}
        onCancel={() => setRemoveDialog(false)}
      />
      <Card className={classes.root}>
        <div onClick={onClick}>
          <CardHeader
            title={date}
            // subheader={`${team1Score} - ${team2Score}`}
          />
            {dataProcessed &&  dataProcessed.checkdataprocessed == 1 && (

            <div className={classes.pater}>
                <DialogContentText
                    className={classes.textPointRed}
                >
                        {t("matchesScreen.processing")}


                </DialogContentText>
                <Content native>
                    {(props) => (
                        <animated.div
                            style={{
                                backgroundColor: "red",
                                padding: 8,
                                borderRadius: "50%",
                                width: 8,
                                height: 8,
                                marginRight: 5,
                                display: "inline-block",


                                ...props,
                            }}
                        />
                    )}
                </Content>
                </div>

            )}

        </div>
        <CardActions
          className={classes.deleteButtonContainer}
          style={{
            display:
              (auth && auth.userData && auth.userData.id == userId) ||
              admin == userId
                ? "block"
                : "none",
          }}
        >
          <DeleteIcon
            style={{ fontSize: 26 }}
            fontSize="large"
            onClick={() => setRemoveDialog(true)}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

export default MatchItem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto",
      },
      position: "relative",
      cursor: "pointer",
    },
    backgroundImage: {
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
      pater:{
        float: "right",
      },
      textPointRed:{
        textAlign: "right",
          marginRight: "5px",
        display: "inline-block",
      },
    avatar: {
      backgroundColor: red[500],
    },
    deleteButtonContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 1,
      cursor: "pointer",
    },
  })
);
