import gql from "graphql-tag";

export const STATUS_MUTATION = gql`
  mutation Status($imei: Float!, $userId: Float!) {
    shinguardsStatus(imei: $imei, userId: $userId) {
      bl
      br
      rssi
      nos
      sq
      temperature
      fwversion
    }
  }
`;

export const STATUS_SHINGUARDS_PLAYERS = gql`
  mutation StatusShinguardsPlayers($imei: Float!) {
    shinguardsStatusplayer(imei: $imei) {
      bl
      br
      rssi
      nos
      sq
      temperature
      fwversion
      fixdone
    }
  }
`;

export const RESET_SHINGUARDS = gql`
  mutation resetShinguards($imei: Float!, $userId: Float!) {
    reset(imei: $imei, userId: $userId)
  }
`;

export const RESETCAL_MUTATION = gql`
  mutation ResetCal($imei: Float!) {
    resetcal(imei: $imei)
  }
`;

export const CONTROLCAL_MUTATION = gql`
  mutation ControlCal($imei: Float!, $step: Int!) {
    calibrationcontrol(imei: $imei, step: $step)
  }
`;
export const POWER_OFF = gql`
  mutation PowerOff($imei: Float!, $userId: Float!) {
    poweroff(imei: $imei, userId: $userId)
  }
`;

export const UPDATE_RIGHT_SHINGUARD = gql`
  mutation updateRightShinguard($imei: Float!) {
    updaterightshinguards(imei: $imei)
  }
`;
export const UPDATE_LEFT_SHINGUARD = gql`
  mutation updateLeftShinguard($imei: Float!) {
    updateleftshinguards(imei: $imei)
  }
`;

export const CHANGE_TO_1HZ = gql`
  mutation changeShinguardTo1Hz($imei: Float!) {
    changeto1hz(imei: $imei)
  }
`;
export const CHANGE_TO_2HZ = gql`
  mutation changeShinguardTo2Hz($imei: Float!) {
    changeto2hz(imei: $imei)
  }
`;
export const CHANGE_TO_5HZ = gql`
  mutation changeShinguardTo5Hz($imei: Float!) {
    changeto5hz(imei: $imei)
  }
`;
export const CHANGE_TO_10HZ = gql`
  mutation changeShinguardTo10Hz($imei: Float!) {
    changeto10hz(imei: $imei)
  }
`;

export const UPDATE_RIGHT_SHINGUARD_PERCENTAJE = gql`
  query updateRightPercentaje {
    updaterightpercentaje
  }
`;
export const UPDATE_LEFT_SHINGUARD_PERCENTAJE = gql`
  query updateLeftPercentaje {
    updateleftpercentaje
  }
`;
export const LAST_SHINGUARDS_VERSION = gql`
  query lastShinguardsVersion {
    lastshinguardsversion
  }
`;

export const FIX_DONE = gql`
  query fixDone {
    fixdone
  }
`;
