import React, { FC, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { USER_QUERY } from "../../lib/queries/user.queries";
import { CONTROLCAL_MUTATION } from "../../lib/queries/shinguards.queries";
import useAuth from "../../Services/Auth";
import {
  Button,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import ballImage from "../../lib/assets/calibrate-ball.png";
import hitImage from "../../lib/assets/calibrate-hit.png";

import Shinguards, {
  ShinguardState,
  ShinguardType,
} from "../../Components/Shinguards";
import DialogModal from "../../Components/DialogModal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export enum CalibrationType {
  Ball,
  Hit,
}

const CalibrationScreen: FC = ({}) => {
  const auth = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { data: userData } = useQuery(USER_QUERY, {
    variables: { id: auth && auth.userData && auth.userData.id },
  });
  const player = userData && userData.getUser;
  const shinguards = player && parseFloat(player.current_shinguards);

  const [controlcal] = useMutation(CONTROLCAL_MUTATION);
  const [passed, setPassed] = useState(false);
  const [type, setType] = useState(CalibrationType.Ball);
  const [shinguardType, setShinguardType] = useState(ShinguardType.Right);
  const [calibrationStep, setCalibrationStep] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(true);
  const [calibrationFinished, setCalibrationFinished] = useState(false);

  useEffect(() => {
    if (shinguards && !passed) {
      let cancel = false;
      const checkCalibartion = async () => {
        try {
          const { data } = await controlcal({
            variables: { imei: shinguards, step: calibrationStep },
          });
          if (
            data &&
            data.calibrationcontrol == "ok" &&
            modalVisible !== true
          ) {
            if (successCount < 2) {
              setModalVisible(true);
            }
            setSuccessCount(successCount + 1);
          } else {
            if (!cancel) {
              checkCalibartion();
            }
          }
        } catch (e) {
          console.log(e);
          if (!cancel) {
            checkCalibartion();
          }
        }
      };
      if (successCount < 3 && calibrationStep > 0) {
        checkCalibartion();
      }
      return () => {
        cancel = true;
      };
    }
  }, [shinguards, successCount, calibrationStep, modalVisible]);

  useEffect(() => {
    if (successCount >= 3 && calibrationStep == 1) {
      setCalibrationStep(2);
      setType(CalibrationType.Hit);
      setSuccessCount(0);
      setModalVisible(true);
    } else if (successCount >= 3 && calibrationStep == 2) {
      setCalibrationStep(3);
      setType(CalibrationType.Ball);
      setShinguardType(ShinguardType.Left);
      setSuccessCount(0);
      setModalVisible(true);
    } else if (successCount >= 3 && calibrationStep == 3) {
      setCalibrationStep(4);
      setType(CalibrationType.Hit);
      setSuccessCount(0);
      setModalVisible(true);
    } else if (successCount >= 3 && calibrationStep == 4) {
      setCalibrationFinished(true);
    }
  });

  const showModal = (ball: boolean, right: boolean) => {
    return (
      <Dialog
        open={modalVisible}
        onClose={() => console.log("Cerrar")}
        aria-labelledby="responsive-dialog-title"
      >
        {ball && (
          <DialogTitle id="responsive-dialog-title">
            {t("calibrationScreen.hitBall")}
          </DialogTitle>
        )}
        {!ball && (
          <DialogTitle id="responsive-dialog-title">
            {t("calibrationScreen.hitShinguard")}
          </DialogTitle>
        )}
        <DialogContent>
          {ball && right && (
            <DialogContentText>
              {t("calibrationScreen.beReadyKickRight")}
            </DialogContentText>
          )}
          {!ball && right && (
            <DialogContentText>
              {t("calibrationScreen.beReadyHitRight")}
            </DialogContentText>
          )}
          {ball && !right && (
            <DialogContentText>
              {t("calibrationScreen.beReadyKickLeft")}
            </DialogContentText>
          )}
          {!ball && !right && (
            <DialogContentText>
              {t("calibrationScreen.beReadyHitLeft")}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModalVisible(false)}
            color="primary"
            variant="contained"
          >
            oK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  let countText = "";
  switch (successCount) {
    case 0:
      countText = t("calibrationScreen.counText3");
      break;
    case 1:
      countText = t("calibrationScreen.counText2");
      break;
    case 2:
      countText = t("calibrationScreen.counText1");
      break;
  }

  return (
    <Container>
      <DialogModal
        open={dialogVisible}
        onOk={() => {
          setCalibrationStep(1);
          setDialogVisible(false);
        }}
        onCancel={() => history.push("/shinguards")}
        buttonDesagree={t("common.cancel")}
        buttonAgree={t("common.accept")}
        title={t("calibrationScreen.calibrationWillStart")}
        text={t("calibrationScreen.getReady")}
      ></DialogModal>
      <DialogModal
        open={calibrationFinished}
        onOk={() => {
          setCalibrationFinished(false);
          history.push("/shinguards");
        }}
        onCancel={() => history.push("/shinguards")}
        buttonAgree={t("common.accept")}
        title={"Espinilleras calibradas"}
        text={"Se han calibrado las espinilleras correctamente."}
      ></DialogModal>
      <Shinguards
        state={passed ? ShinguardState.On : ShinguardState.Sync}
        calibrating={shinguardType}
      ></Shinguards>
      <Grid container justify="center">
        {type === CalibrationType.Ball &&
          shinguardType === ShinguardType.Right && (
            <Grid item xs={12}>
              {showModal(true, true)}
              <div style={{ textAlign: "center" }}>
                <p>
                  {t("calibrationScreen.kickBall", { countText })}
                  <span className={classes.greenText}>
                    {t("calibrationScreen.rightleg")}
                  </span>
                </p>
                <img src={ballImage} className={classes.ballImageRight} />
              </div>
            </Grid>
          )}
        {type === CalibrationType.Ball && shinguardType === ShinguardType.Left && (
          <Grid item>
            {showModal(true, false)}
            <div style={{ textAlign: "center" }}>
              <p>
                {t("calibrationScreen.kickBall", { countText })}
                <span className={classes.greenText}>
                  {t("calibrationScreen.leftleg")}
                </span>
              </p>
              <img src={ballImage} className={classes.ballImageLeft} />
            </div>
          </Grid>
        )}
        {type === CalibrationType.Hit && shinguardType === ShinguardType.Right && (
          <Grid item>
            {showModal(false, true)}
            <div style={{ textAlign: "center" }}>
              <p>
                {t("calibrationScreen.hitShinguard")}
                <span className={classes.greenText}>
                  {t("calibrationScreen.right")}
                </span>
                {t("calibrationScreen.hitShinguard2", { countText })}
              </p>
              <img src={hitImage} className={classes.hitImageRight} />
            </div>
          </Grid>
        )}
        {type === CalibrationType.Hit && shinguardType === ShinguardType.Left && (
          <Grid item>
            {showModal(false, false)}
            <div style={{ textAlign: "center" }}>
              <p>
                {t("calibrationScreen.hitShinguard")}
                <span className={classes.greenText}>
                  {t("calibrationScreen.left")}
                </span>
                {t("calibrationScreen.hitShinguard2", { countText })}
              </p>
              <img src={hitImage} className={classes.hitImageRLeft} />
            </div>
          </Grid>
        )}
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            onClick={() => history.push("/shinguards")}
            color="primary"
            variant="contained"
            style={{ marginTop: 20 }}
          >
            Atras
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CalibrationScreen;

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    ballImageRight: {
      width: 100,
      height: 100,
      transform: "scaleX(-1)",
    },
    ballImageLeft: {
      width: 100,
      height: 100,
    },
    hitImageRight: {
      width: 80,
      height: 100,
      transform: "scaleX(-1)",
    },
    hitImageRLeft: {
      width: 80,
      height: 100,
    },
    greenText: {
      color: palette.primary.main,
    },
  })
);
