import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";

export interface ITimerProps {
  recording: boolean;
  startTime: number;
}

const Timer: FC<ITimerProps> = ({ recording, startTime }) => {
  const classes = useStyles();
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (recording) {
      let initialTime;
      if (dayjs().hour() != dayjs(startTime).hour()) {
        let year = dayjs(startTime).year();
        let month = dayjs(startTime).month();
        let day = dayjs(startTime).date();
        let hour = dayjs(startTime).hour();
        let min = dayjs(startTime).minute();
        let sec = dayjs(startTime).second();
        initialTime = dayjs(Date.UTC(year, month, day, hour, min, sec));
      } else {
        initialTime = dayjs(initialTime);
      }
      //initialTime = startTime ? startTime : dayjs();
      const interval = setInterval(() => {
        setTime(dayjs() - dayjs(initialTime));
      });
      return () => {
        clearInterval(interval);
      };
    }
  }, [recording]);
  const seconds = Math.floor(time / 1000) % 60;
  const minutes = Math.floor(time / 60000) % 60;
  const hours = Math.floor(time / 3600000);
  const formatNumber = (number: number) => {
    const str = number.toString();
    return str.length === 1 ? `0${str}` : str;
  };
  return (
    <h2 className={classes.timer}>
      {formatNumber(hours)}:{formatNumber(minutes)}:{formatNumber(seconds)}
    </h2>
  );
};
export default Timer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timer: {
      textAlign: "center",
      fontSize: 40,
      margin: "10px auto",
    },
  })
);
