import React, { FC, useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import LiveTag from "./Live";
import UnFollowDialog from "./unFollowDialog";
import humanoxLogo from "../lib/assets/logo2.png";

import gamerIcon from "../lib/assets/ico_gamer.png";
import gamerProIcon from "../lib/assets/ico_gamer_pro.png";
import pro from "../lib/assets/ico_professional.png";
import trainer from "../lib/assets/ico_trainer.png";
import physical from "../lib/assets/ico_phystrainer.png";
import enviromentIcon from "../lib/assets/ico_environment.png";
import follower from "../lib/assets/ico_follower.png";

const playerType = [
  {
    id: 1,
    type: "GAMER",
    icon: gamerIcon,
  },
  {
    id: 2,
    type: "ProGamer",
    icon: gamerProIcon,
  },
  {
    id: 3,
    type: "Professional",
    icon: pro,
  },
  {
    id: 4,
    type: "Trainer",
    icon: trainer,
  },
  {
    id: 5,
    type: "PhysicalTrainer",
    icon: physical,
  },
  {
    id: 6,
    type: "Enviroment",
    icon: enviromentIcon,
  },
  {
    id: 7,
    type: "Follower",
    icon: follower,
  },
];

export interface IFollowUserProps {
  id: Number;
  name: String;
  live: Boolean;
  profile: Number;
  onClick?: () => void;
  onDelete?: () => void;
}

const nullImageUrl = "https://humanox.nointerface.ai/img/null";
const FollowUser: FC<IFollowUserProps> = ({
  id,
  name,
  live,
  profile,
  onClick,
  onDelete,
}) => {
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [unFollow, setUnFollow] = useState(false);

  const checkImgUrl = (url: String) => {
    fetch(url)
      .then((res) => {
        if (res.status == 404) {
          setImage("https://live.humanox.com/usermedia/userpics/9705.jpg");
        } else {
          setImage(`https://live.humanox.com/usermedia/userpics/${id}.jpg`);
        }
      })
      .catch((e) => {
        setImage("https://live.humanox.com/usermedia/userpics/9705.jpg");
      });
  };
  useEffect(() => {
    checkImgUrl(`https://live.humanox.com/usermedia/userpics/${id}.jpg`);
  });
  return (
    <Grid item xs={12}>
      <UnFollowDialog
        name={name}
        open={unFollow}
        onOk={() => {
          setUnFollow(false);
          onDelete();
        }}
        onCancel={() => setUnFollow(false)}
      />
      <Card className={classes.root}>
        {/* <CardMedia className={classes.cover} image={image} title="stadium" /> */}
        <div className={classes.followingDetails}>
          <CardContent className={classes.content} onClick={onClick}>
            <CardHeader title={name} />
            <Grid container alignItems="center">
              <Grid item xs={6} className={classes.subcontainer}>
                <div className={classes.playetTypeContainer}>
                  <div className={classes.playerTypeImage}>
                    <CardMedia
                      image={playerType[profile].icon}
                      className={classes.cardImage}
                    ></CardMedia>
                  </div>

                  <span>{playerType[profile].type}</span>
                </div>
              </Grid>
              {live && (
                <Grid item xs={6} className={classes.subcontainer}>
                  <LiveTag />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions className={classes.deleteButtonContainer}>
            <DeleteIcon
              style={{ fontSize: 26 }}
              fontSize="large"
              onClick={() => setUnFollow(true)}
            />
          </CardActions>
        </div>
      </Card>
    </Grid>
  );
};

export default FollowUser;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
      position: "relative",
      cursor: "pointer",
      display: "flex",
      margin: "20px auto",
    },
    followingDetails: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    playetTypeContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    playerTypeImage: {
      width: 120,
      height: 50,
    },
    cardImage: {
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
    },
    deleteButtonContainer: {
      position: "absolute",
      bottom: 0,
      right: 0,
      zIndex: 2,
      cursor: "pointer",
    },
    subcontainer: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  })
);
