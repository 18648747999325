import {useQuery} from "@apollo/client";
import React, {FC, useEffect, useMemo, useState} from "react";
import {GET_REQUEST_DATA} from "../../../lib/queries";
import {USER_PROFILE} from "../../../lib/queries/user.queries";
import Gauge from "../../../Components/Gauge";
import {Container, createStyles, Grid, makeStyles, Typography,} from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import MapContainer from "../../../Components/Map";
import RoomIcon from "@material-ui/icons/Room";
import SpeedIcon from "@material-ui/icons/Speed";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import FavoriteIcon from "@material-ui/icons/Favorite";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import {convertStupidCoordinate} from "../../../Services/Utils";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

const MatchScreen: FC = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    console.log("[MatchScreen] ===> props", props);
    let id = props && props.userData && props.userData.id;
    let uuid = props && props.userData && props.userData.uuid;
    if (
        location &&
        location.state &&
        location.state.userId &&
        location.state.uuid
    ) {
        id = parseFloat(location.state.userId);
        uuid = location.state.uuid;
    }
    const matchId = parseFloat(props.match.params.id);
    console.log("Valor del matchid", matchId);
    console.log("id", id);
    console.log("uuid", uuid);
    // console.log("matchId", matchId);

    const [speeds, setSpeeds] = useState();
    const [maxSpeed, setMaxSpeed] = useState(0);
    const [hrs, setHrs] = useState();
    const [kcalsChart, setKcalsChart] = useState();
    const [totalWalking, setWalking] = useState(0);
    const [isAdmin, setAdmin] = useState(false);
    const {data: profileData} = useQuery(USER_PROFILE, {
        variables: {id: id, uuid},
    });
    const hasImperialSystem = profileData ? profileData.getProfile.measurement_format == 'imperial' : '';
    console.log("USER DATA", hasImperialSystem);
    const {data: getRequestData} = useQuery(GET_REQUEST_DATA, {
        variables: {matchId, userId: id, uuid},
    });

    let {
        positionData,
        kickpositions,
        hitpositions,
        dataMatch,
        matchSpeed,
        matchHr,
        matchKcal,
    } = (getRequestData) || {};

    if (getRequestData) {
        dataMatch = getRequestData.getrequestdata.getmatchdata;
        positionData = getRequestData.getrequestdata.getgeopositionmatch;
        kickpositions = getRequestData.getrequestdata.getkickpositions;
        hitpositions = getRequestData.getrequestdata.gethitspositions;
        matchSpeed = getRequestData.getrequestdata.getmatchspeed;
        matchHr = getRequestData.getrequestdata.getmatchhr;
        matchKcal = getRequestData.getrequestdata.getmatchkcals;
    }

    useEffect(() => {
        if (matchSpeed) {
            let checkBefore = 0;
            let count = 1;
            let numVeces = 0;

            let speeds = matchSpeed.speeds.map((value) => {
                if (value.speed > maxSpeed) {
                    setMaxSpeed(value.speed);
                }
                let year = new Date(value.second).getFullYear();
                let month = new Date(value.second).getMonth();
                let day = new Date(value.second).getDate();
                let hour = new Date(value.second).getHours();
                let min = new Date(value.second).getMinutes();
                let sec = new Date(value.second).getSeconds();
                let segundos = dayjs(
                    Date.UTC(year, month, day, hour, min, sec)
                ).valueOf();

                numVeces = 0;
                for (let i = 0; i < matchSpeed.speeds.length; i++) {
                    if (value.second == matchSpeed.speeds[i].second) {
                        numVeces++;
                    }
                }
                if (numVeces > 0 && count < numVeces) {
                    segundos += (999 / numVeces) * count;
                    count++;
                } else if (numVeces > 0 && count == numVeces) {
                    segundos += (999 / numVeces) * count;
                    count = 1;
                }
                return [segundos, value.speed];
            });
            setSpeeds(speeds);
        }
    }, [matchSpeed]);

    useEffect(() => {
        if (matchHr) {
            let hrs = matchHr.hrs.map((value) => {
                let year = new Date(value.second).getFullYear();
                let month = new Date(value.second).getMonth();
                let day = new Date(value.second).getDate();
                let hour = new Date(value.second).getHours();
                let min = new Date(value.second).getMinutes();
                let sec = new Date(value.second).getSeconds();
                let segundos = dayjs(
                    Date.UTC(year, month, day, hour, min, sec)
                ).valueOf();
                return [segundos, value.hr];
            });
            setHrs(hrs);
        }
    }, [matchHr]);

    // useEffect(() => {
    //   if (matchKcal) {
    //     let kcals = matchKcal.kcals.map((value) => {
    //       let year = new Date(value.second).getFullYear();
    //       let month = new Date(value.second).getMonth();
    //       let day = new Date(value.second).getDate();
    //       let hour = new Date(value.second).getHours();
    //       let min = new Date(value.second).getMinutes();
    //       let sec = new Date(value.second).getSeconds();
    //       let segundos = dayjs(
    //         Date.UTC(year, month, day, hour, min, sec)
    //       ).valueOf();
    //       return [segundos, value.kcal];
    //     });
    //     setKcalsChart(kcals);
    //   }
    // }, [matchKcal]);
    const points = useMemo(() => {
        const poinstData =
            positionData &&
            positionData.geomatch;
        if (!poinstData) {
            return [{lat: 0, lng: 0}];
        }
        return poinstData.map((d) => ({
            lat: convertStupidCoordinate(d.geo_lat),
            lng: convertStupidCoordinate(d.geo_lon),
        }));
    }, [positionData]);

    const kicks = useMemo(() => {
        let kicksData =
            kickpositions &&
            kickpositions.geomatch;
        if (!kicksData) {
            return [];
        }
        kicksData = [...new Map(kicksData.map(v => [v.event_ts, v])).values()];
        return kicksData.map((d) => ({
            lat: convertStupidCoordinate(d.geo_lat),
            lng: convertStupidCoordinate(d.geo_lon),
        }));
    }, [kickpositions]);

    const hits = useMemo(() => {
        let hitsData =
            hitpositions &&
            hitpositions.geomatch;
        if (!hitsData) {
            return [];
        }
        return hitsData.map((d) => ({
            lat: convertStupidCoordinate(d.geo_lat),
            lng: convertStupidCoordinate(d.geo_lon),
        }));
    }, [hitpositions]);

    const avgPos = useMemo(() => {
        const latitude =
            points && points.length
                ? points.reduce((acc, p) => acc + p.lat, 0) / points.length
                : 0;
        const longitude =
            points && points.length
                ? points.reduce((acc, p) => acc + p.lng, 0) / points.length
                : 0;
        return {latitude, longitude};
    }, [points]);

    useEffect(() => {
        if (location && location.pathname && location.pathname.includes("admin")) {
            setAdmin(true);
        }
    }, [location]);
    const {
        kcals = 0,
        avg_hr = 0,
        avgspeed = 0,
        distance = 0,
        impacts = 0,
        l_shootings = 0,
        max_hr = 0,
        maxspeed = 0,
        r_shootings = 0,
        running = 0,
        sprinting = 0,
        steps = 0,
        timeplayed = 0,
        walking = 0,
        maxkickstrenght = 0,
        temperature = 0,
        shinguards,
        zones,
    } = (dataMatch) || {};

    useEffect(() => {
        let totalPercentaje = walking + running + sprinting;
        if (totalPercentaje < 100) {
            setWalking(walking + (100 - totalPercentaje));
        } else {
            setWalking(walking);
        }
    }, [dataMatch]);

    // const calories = {
    //   chart: {
    //     type: "areaspline",
    //     zoomType: "x",
    //     backgroundColor: "transparent",
    //   },
    //   title: {
    //     text: null,
    //     enabled: false,
    //   },
    //   xAxis: {
    //     type: "datetime",
    //     title: {
    //       enabled: false,
    //     },
    //     allowDecimals: false,
    //   },
    //   tooltip: {
    //     formatter: function () {
    //       let time = Highcharts.dateFormat("%H:%M:%S", this.x);
    //       let s = "Tiempo: " + time + "<br/>" + "KC: " + this.y;
    //       return s;
    //     },
    //     shared: true,
    //   },
    //   yAxis: {
    //     title: {
    //       text: null,
    //     },
    //   },
    //   credits: {
    //     enabled: false,
    //   },
    //   plotLines: [
    //     {
    //       color: "#550000",
    //       width: 1,
    //       value: 16,
    //     },
    //     {
    //       color: "#6f6320",
    //       width: 1,
    //       value: 8,
    //     },
    //   ],
    //   plotOptions: {
    //     areaspline: {
    //       fillOpacity: 0.5,
    //       color: "#67b32e",
    //     },
    //     series: {
    //       states: {
    //         hover: {
    //           enabled: false,
    //         },
    //       },
    //     },
    //     area: {
    //       marker: {
    //         enabled: false,
    //         symbol: "circle",
    //         radius: 1,
    //         color: "#000000",
    //         states: {
    //           hover: {
    //             enabled: true,
    //           },
    //         },
    //       },
    //     },
    //   },
    //   series: [
    //     {
    //       name: "KC",
    //       lineColor: "#47930e",
    //       lineWidth: "1px",
    //       lineWidthPlus: "1px",
    //       fillColor: "rgba( 97, 191, 26, 0.2)",
    //       showInLegend: false,
    //       data: kcalsChart,
    //     },
    //   ],
    // };

    const heartRateChart = {
        chart: {
            zoomType: "xy",
            type: "spline",
            backgroundColor: "transparent",
        },
        title: {
            text: null,
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            type: "datetime",
            title: {
                enabled: false,
            },
            allowDecimals: true,
        },
        tooltip: {
            formatter: function () {
                let time = Highcharts.dateFormat("%H:%M:%S", this.x);
                let s = "Tiempo: " + time + "<br/>" + "HR: " + this.y + " PPM";
                return s;
            },
            shared: true,
        },
        credits: {
            enabled: false,
        },
        yAxis: {
            title: {
                text: null,
                enabled: false,
            },
        },

        plotOptions: {
            areaspline: {
                fillOpacity: 0.5,
                color: "#67b32e",
            },
            area: {
                marker: {
                    enabled: false,
                    symbol: "circle",
                    radius: 2,
                    color: "#ff0000",
                    states: {
                        hover: {
                            enabled: true,
                        },
                    },
                },
            },
        },
        series: [{name: "", data: hrs, color: "#ff0000", showInLegend: true}],
    };

    const matchSpeedGraph = {
        chart: {
            type: "area",
            zoomType: "xy",
            backgroundColor: "transparent",
        },
        title: {
            text: null,
        },
        legend: {
            enabled: false,
        },

        xAxis: {
            type: "datetime",
            allowDecimals: true,
        },
        credits: {
            enabled: false,
        },
        yAxis: {
            title: {
                text: null,
            },
            min: 0,

            labels: {
                formatter: function () {
                    return this.value + (hasImperialSystem ? " mi/h" : " km/h");
                },
            },
            plotLines: [
                {
                    color: "#550000",
                    width: 1,
                    value: 16,
                },
                {
                    color: "#6f6320",
                    width: 1,
                    value: 8,
                },
            ],
        },
        tooltip: {
            formatter: function () {
                let time = Highcharts.dateFormat("%H:%M:%S", this.x);

                let s = "Tiempo: " + time + "<br/>" + "Velocidad: " + this.y + "Km/h";
                return s;
            },
            shared: true,
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.8,
                color: "#67b32e",
            },
            series: {
                lineWidth: 2,
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
        },
        series: [
            {
                name: "",
                lineColor: "#47930e",
                lineWidth: "1px",
                lineWidthPlus: "1px",
                fillColor: "rgba( 97, 191, 26, 0.2)",
                showInLegend: true,
                data: speeds,
            },
        ],
    };

    const activityChart = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            backgroundColor: "transparent",
        },
        title: {
            text: null,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            pointFormat: "<b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                    style: {
                        color: "#fff",
                    },
                },
            },
        },
        series: [
            {
                name: "",
                colorByPoint: true,
                data: [
                    {
                        name: t("matchScreen.sprinting"),
                        color: "#ed5034",
                        y: sprinting,
                    },
                    {
                        name: t("matchScreen.running"),
                        color: "#c48129",
                        y: running,
                    },
                    {
                        name: t("matchScreen.walking"),
                        color: "#939c79",
                        y: totalWalking,
                    },
                ],
            },
        ],
    };
    const zonesChart = {
        chart: {
            type: 'column',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            backgroundColor: "transparent",
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: hasImperialSystem ? [
                t("matchScreen.zone") + ' 1 (0.1mi/h - 4.3mi/h)',
                t("matchScreen.zone") + '  2 (4.4mi/h - 8.0mi/h)',
                t("matchScreen.zone") + '  3 (8.1mi/h - 11.1mi/h)',
                t("matchScreen.zone") + '  4 (11.2mi/h - 13.0mi/h)',
                t("matchScreen.zone") + '  5 (13.1mi/h - 14.2mi/h)',
                t("matchScreen.zone") + '  6 (≥ 14.3mi/h)',
            ] : [
                t("matchScreen.zone") + '  1 (0.1km/h - 6.9km/h)',
                t("matchScreen.zone") + '  2 (7.0km/h - 12.9km/h)',
                t("matchScreen.zone") + '  3 (13.0km/h - 17.9km/h)',
                t("matchScreen.zone") + '  4 (18.0km/h - 20.9km/h)',
                t("matchScreen.zone") + '  5 (21.0km/h - 22.9km/h)',
                t("matchScreen.zone") + '  6 (≥ 23km/h)',
            ],
        },
        yAxis: {
            min: 0,
            title: {
                text: hasImperialSystem ? t("matchScreen.yards") + ' (yd)' : t("matchScreen.metres") + ' (m)'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<td style="padding:0"><b>{point.y:.1f} ' + (hasImperialSystem ? "yd" : "m") + '</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                showInLegend: false,
                color: "#3AAA35"
            },
        },
        series: [{
            data: zones ? JSON.parse(zones) : ''
        }]
    };

    return (
        <Container style={{marginTop: 25}}>
            <div style={{position: "relative", marginBottom: 10, height: 25}}>
                <div
                    className={classes.backButtonContainer}
                    onClick={() => history.goBack()}
                >
                    <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#3AAA35"/>
                </div>
                {" "}
                {location &&
                location.state &&
                location.state.userId &&
                shinguards &&
                isAdmin && (
                    <Typography variant="h5" style={{textAlign: "center"}}>
                        {t("headers.shinguards")} {shinguards}
                    </Typography>
                )}
            </div>

            {positionData && positionData.geomatch.length > 0 && (
                <div>
                    <div className={classes.sectionTitleContainer}>
                        <RoomIcon color="primary" fontSize="large"/>
                        <div className={classes.sectionTitle}>
                            {t("matchScreen.heatmap")}
                        </div>
                    </div>

                    <div style={{height: 500, position: "relative"}}>
                        {positionData && avgPos && (
                            <MapContainer
                                positions={points}
                                latitude={avgPos.latitude}
                                longitude={avgPos.longitude}
                                zoom={19}
                                live={false}
                                disableDefaultUI={true}
                                draggable={true}
                                kicksPositions={kicks}
                                hitsPositions={hits}
                            />
                        )}
                    </div>
                </div>
            )}
            <div className={classes.sectionTitleContainer}>
                <TrendingUpIcon color="primary" fontSize="large"/>
                <div className={classes.sectionTitle}>{t("matchScreen.activity")}</div>
            </div>
            <div className={classes.gaugeContainer}>
                <Grid container justify="center">
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={Math.round(kcals)}
                            max={2500}
                            units="Kcal"
                            label={t("matchScreen.calories")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={steps}
                            max={30000}
                            units={t("matchScreen.steps")}
                            label={t("matchScreen.numStpes")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={timeplayed}
                            max={500}
                            units="min"
                            label={t("matchScreen.timePlayed")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={temperature}
                            max={hasImperialSystem ? 113 : 45}
                            units={hasImperialSystem ? "ºF" : "ºC"}
                            label={t("matchScreen.temp")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={distance * 1000}
                            max={20000.001}
                            units={hasImperialSystem ? "yd" : "m"}
                            label={t("matchScreen.totalDistance")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={(distance * 1000) / timeplayed}
                            max={hasImperialSystem ? 500.72 : 500}
                            units={hasImperialSystem ? "yd/min" : "m/min"}
                            label={t("matchScreen.distancePerMin")}
                        />
                    </Grid>
                </Grid>
            </div>
            {/* {hrs && heartRateChart && (
        <HighchartsReact highcharts={Highcharts} options={calories} />
      )} */}
            <div className={classes.sectionTitleContainer}>
                <SpeedIcon color="primary" fontSize="large"/>
                <div className={classes.sectionTitle}>{t("matchScreen.speed")}</div>
            </div>
            <div className={classes.gaugeContainer}>
                <Grid container justify="center">
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={totalWalking}
                            max={100.001}
                            units="%"
                            label={t("matchScreen.timeWalking")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={running}
                            max={100.001}
                            units="%"
                            label={t("matchScreen.timeRunning")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={sprinting}
                            max={100.001}
                            units="%"
                            label={t("matchScreen.timeMaxSpeed")}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={avgspeed}
                            max={hasImperialSystem ? 25.428 : 30.001}
                            units={hasImperialSystem ? "mi/h" : "km/h"}
                            label={t("matchScreen.avgSpeed")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={maxspeed}
                            max={hasImperialSystem ? 40.855 : 40.001}
                            units={hasImperialSystem ? "mi/h" : "km/h"}
                            label={t("matchScreen.avgMax")}
                        />
                    </Grid>

                </Grid>
            </div>
            {speeds && matchSpeedGraph && (
                <HighchartsReact highcharts={Highcharts} options={matchSpeedGraph}/>
            )}

            {zones && zonesChart && (
                <HighchartsReact highcharts={Highcharts} options={zonesChart}/>
            )}
            <div className={classes.sectionTitleContainer}>
                <SportsSoccerIcon color="primary" fontSize="large"/>
                <div className={classes.sectionTitle}>
                    {t("matchScreen.relacionBall")}
                </div>
            </div>
            <div className={classes.gaugeContainer}>
                <Grid container justify="center">
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={l_shootings}
                            max={200}
                            units={t("matchScreen.kickLeft")}
                            label={t("matchScreen.numKicks")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={r_shootings}
                            max={200}
                            units={t("matchScreen.kickRight")}
                            label={t("matchScreen.numKicks")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={maxkickstrenght}
                            max={hasImperialSystem ? 984.25 : 300}
                            units={hasImperialSystem ? "ft/s2" : "m/s2"}
                            label={t("matchScreen.strengthKick")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={impacts}
                            max={4000}
                            units={t("matchScreen.hits")}
                            label={t("matchScreen.numHits")}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.sectionTitleContainer}>
                <FavoriteIcon color="primary" fontSize="large"/>
                <h3 className={classes.sectionTitle}>{t("matchScreen.heart")}</h3>
            </div>
            <div className={classes.gaugeContainer}>
                <Grid container justify="center">
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={max_hr}
                            max={220}
                            units={t("matchScreen.bpm")}
                            label={t("matchScreen.maxBeats")}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        style={{textAlign: "center"}}
                    >
                        <Gauge
                            value={Math.round(avg_hr)}
                            max={200}
                            units={t("matchScreen.bpm")}
                            label={t("matchScreen.avghr")}
                        />
                    </Grid>
                </Grid>
            </div>
            {hrs && heartRateChart && (
                <HighchartsReact highcharts={Highcharts} options={heartRateChart}/>
            )}
        </Container>
    );
};

export default MatchScreen;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitleContainer: {
            display: "flex",
            alignItems: "center",
        },
        sectionTitle: {
            fontWeight: "bold",
            textTransform: "uppercase",
        },
        sectionImage: {
            height: 35,
            width: 35,
            marginRight: 5,
        },
        gaugeContainer: {
            justifyContent: "space-between",
            display: "flex",
        },
        backButtonContainer: {
            marginBottom: 10,
            position: "absolute",
        },
    })
);
