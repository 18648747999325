import gql from "graphql-tag";

export const USER_QUERY = gql`
  query User($id: Float!, $uuid: String!) {
    getUser(id: $id, uuid: $uuid) {
      id
      uuid
      name
      fullname
      current_shinguards
      active
      measurement_format
    }
  }
`;

export const USER_PROFILE = gql`
  query GetProfile($id: Float!, $uuid: String!) {
    getProfile(id: $id, uuid: $uuid) {
      id
      email
      name
      fullname
      birthdate
      height
      weight
      teamname
      profile
      phone
      gender
      ground_temperature
      canbefollowed
      address1
      address2
      zipcode
      city
      country
      createts
      player_number
      player_position_id
      measurement_format
    }
  }
`;

export const USER_SIGNUP = gql`
  mutation signUp($signUpData: signUpData!) {
    signUp(signUpData: $signUpData) {
      id
      email
      fullname
      birthdate
      height
      weight
    }
  }
`;

export const CREATE_USER_CUSTOMERCARE = gql`
  mutation createUserCustomerCare($userCustomer: userCustomer!) {
    createusercustomercare(userCustomer: $userCustomer)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $updateProfileData: UpdateProfile!
    $userId: Float
    $uuid: String!
  ) {
    updateprofile(
      updateProfileData: $updateProfileData
      userId: $userId
      uuid: $uuid
    ) {
      id
      email
      name
      fullname
      birthdate
      height
      weight
      teamname
      profile
      phone
      gender
      ground_temperature
      measurement_format
      canbefollowed
      address1
      address2
      city
      country
      zipcode
      player_number
      player_position_id
    }
  }
`;
export const PLACE_ORDER = gql`
  mutation placeOrder($placeOrderData: PlaceOrderInput!, $userId: Float!) {
    placeorder(placeOrderData: $placeOrderData, userId: $userId) {
      id
    }
  }
`;

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!) {
    recoverpassword(email: $email)
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetpassword($userId: Float!, $pin: String!, $token: String!) {
    updatepassworduserwithtoken(userId: $userId, pin: $pin, token: $token)
  }
`;

export const GET_USER_FROM_TOKEN = gql`
  mutation getUserFromToken($token: String!) {
    getuserfromtoken(token: $token)
  }
`;

export const UPDATE_USER_SHINGUARDS = gql`
  mutation updateShinguardsUser($userId: Float!, $shinguards: Float!) {
    updateshinguardsuser(userId: $userId, shinguards: $shinguards)
  }
`;

export const REMOVE_USER_SHINGUARDS = gql`
  mutation removeUserShinguards($userId: Float!) {
    removeusershinguards(userId: $userId)
  }
`;

export const GET_USER_UUID = gql`
  mutation getUserUuid($userId: Float!) {
    getuseruuid(userId: $userId)
  }
`;
