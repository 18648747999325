import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import es from "./translations/es.json";
import en from "./translations/en.json";
import it from "./translations/it.json";

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  it: {
    translation: it,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  debug: false,
});

export default i18next;
