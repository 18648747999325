import {
  Container,
  Card,
  makeStyles,
  Button,
  TextField,
  Grid,
  Typography,
  createStyles,
  Theme,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import useAuth from "../../Services/Auth";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFlag, faLock } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import * as es from "dayjs/locale/es";
import * as en from "dayjs/locale/en";
import i18next from "i18next";

import logo from "../../lib/assets/logo2.png";
import esFlag from "../../lib/assets/es.png";
import enFlag from "../../lib/assets/en.png";
import itFlag from "../../lib/assets/it.png";

const LoginScreen: FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();
  const [languageSelected, setLanguageSelected] = useState("es");

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        logIn();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  const logIn = async () => {
    setLoggingIn(true);
    setError(false);
    try {
      await auth.logIn(email, password);
      history.push({
        pathname: "/matches",
        state: { fromLogin: true },
      });
    } catch (e) {
      console.log("Error", e);
      setError(true);
    }
    setLoggingIn(false);
  };

  useEffect(() => {
    let lang;
    if (languageSelected == "es") {
      localStorage.setItem("lang", "es");
      lang = "es";
      dayjs.locale(es);
    } else if (languageSelected == "it") {
      localStorage.setItem("lang", "it");
      lang = "it";
      dayjs.locale(es);
    } else {
      localStorage.setItem("lang", "en");
      lang = "en";
      dayjs.locale(en);
    }
    i18next.changeLanguage(lang);
  }, [languageSelected]);
  return (
    <Container className={classes.loginContainer}>
      <Card variant="outlined" className={classes.card}>
        <img src={logo} className={classes.logo} />
        <Grid
          item
          xs={12}
          style={{
            padding: "0 12px",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item className={classes.inputIconWidth}>
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </Grid>
            <Grid item className={classes.inputWidth}>
              <TextField
                id="email"
                name="email"
                label={t("common.form.email")}
                required
                style={{ width: "100%", margin: "10px 0px" }}
                value={email}
                error={error}
                helperText={
                  error ? "El email o la contraseña no son correctos" : ""
                }
                onChange={(
                  event: React.ChangeEvent<{ value: unknown }>,
                  value
                ) => {
                  setEmail(event.target.value as string);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: "0 12px",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item className={classes.inputIconWidth}>
              <FontAwesomeIcon icon={faLock} size="2x" />
            </Grid>
            <Grid item className={classes.inputWidth}>
              <TextField
                label={t("common.form.password")}
                type="password"
                helperText={
                  error ? "El email o la contraseña no son correctas" : ""
                }
                required
                style={{ width: "100%", margin: "10px 0px" }}
                value={password}
                error={error}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setPassword(event.target.value as string);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: "0 12px",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item className={classes.inputIconWidth}>
              <FontAwesomeIcon icon={faFlag} size="2x" />
            </Grid>
            <Grid item className={classes.inputWidth}>
              <img
                src={esFlag}
                className={
                  languageSelected != "es"
                    ? classes.languageNotSelected
                    : classes.flag
                }
                onClick={() => setLanguageSelected("es")}
              />
              <img
                src={enFlag}
                className={
                  languageSelected != "en"
                    ? classes.languageNotSelected
                    : classes.flag
                }
                onClick={() => setLanguageSelected("en")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: "10px 0" }}
          disabled={loggingIn}
          onClick={() => logIn()}
        >
          {t("loginScreen.login")}
        </Button>

        <Grid container style={{ marginTop: 10 }}>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/register")}
            >
              {t("loginScreen.register")}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant="h6"
              color="primary"
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={() => history.push("/recoverpassword")}
            >
              {t("loginScreen.forgotPassword")}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default LoginScreen;

const useStyles = makeStyles(({ palette, breakpoints }: Theme) =>
  createStyles({
    loginContainer: {
      margin: 0,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    card: {
      maxWidth: 350,
      margin: "auto",
      padding: 15,
      textAlign: "center",
      borderTop: "2px solid #3AAA35",
      [breakpoints.up("sm")]: {
        maxWidth: 450,
      },
    },
    button: {
      backgroundColor: "black",
      color: "white",
      borderColor: "#3AAA35",
      "&:hover": {
        backgroundColor: "#3AAA35",
      },
    },
    logo: {
      width: 200,
      height: "auto",
    },
    inputIconWidth: {
      width: "15%",
    },
    inputWidth: {
      width: "85%",
    },
    flag: {
      width: 40,
      height: 40,
      margin: "0 15px",
    },
    languageNotSelected: {
      filter: " brightness(50%)",
      width: 40,
      height: 40,
      margin: "0 15px",
    },
  })
);
