import React, { FC } from "react";

import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export interface ICircularProgressWithLabelProps {
  progress: number;
}

const CircularProgressWithLabel: FC<ICircularProgressWithLabelProps> = ({
  progress,
}) => {
  const classes = useStyles();
  return (
    <Box
      position="absolute"
      display="inline-flex"
      top="20px"
      height="40px"
      width="40px"
    >
      <CircularProgressbar
        value={progress}
        text={`${progress}%`}
        styles={buildStyles({
          rotation: 0.25,
          strokeLinecap: "butt",
          textSize: "30px",
          pathTransitionDuration: 0.5,
          pathColor: "#3AAA35",
          textColor: "#3AAA35",
          trailColor: "#d6d6d6",
          backgroundColor: "#3AAA35",
        })}
      />
    </Box>
  );
};

export default CircularProgressWithLabel;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressBar: {
      height: "50px !important",
      width: "50px !important",
      [theme.breakpoints.down("sm")]: {
        height: "45px !important",
        width: "45px !important",
      },
    },
  })
);
