import React, { createContext, FC, useContext, useState } from "react";
import io from "socket.io-client";

const clientOptions = {
  secure: false, // Use secure connetion
  reconnection: true, // Reconnect if needed (no randomizer values needed for TTR)
  rejectUnauthorized: false, // Allows self-signed and expired certificates
  transports: ["websocket", "polling"], // Revert Tranports
  xdomain: false, // Needed in some data exchange under some gateways (probably deprecated in newer versions)
  agent: true, // Don't use the http agent
  upgrade: true, // Don't allow to upgrade transport from websocket to polling
};

const socket = io("https://live.humanox.com:10357", clientOptions);
export const SocketContext = createContext(null);

export const SocketProvider: FC = ({ children }) => {
  const [fcPercentage, setFCPercentage] = useState();
  const [lfcPercentage, setLFCPercentage] = useState();
  const [left, setLeft] = useState();
  const statusOn = () => {
    return new Promise((resolve) => {
      let timeout = setTimeout(() => {
        return resolve(null);
      }, 10000);
      socket.on("status", (data) => {
        clearTimeout(timeout);
        return resolve(data);
      });
    });
  };

  socket.on("fc", (data) => {
    let parts = data.split(",");
    if (left != undefined) {
      if (!left) {
        let percentaje = parseInt((parts[1] * 100) / parts[2]);
        setFCPercentage(percentaje);
      } else {
        let percentage = parseInt((parts[1] * 50) / parts[2]);
        setLFCPercentage(percentage);
      }
    }
  });
  socket.on("lfc", (data) => {
    let parts = data.split(",");
    let percentaje = parseInt(parts[1] / 2 + 51);
    setLFCPercentage(percentaje);
  });

  const socketContext = {
    status: async (imei: string) => {
      socket.emit("status", imei);
      const resStatus = await statusOn();
      return resStatus;
    },
    reset: (imei: string) => {
      socket.emit("reset", imei);
    },
    powerOff: (imei: string) => {
      socket.emit("poweroff", imei);
    },
    gps1: (imei: string) => {
      socket.emit("gps1", imei);
    },
    gps2: (imei: string) => {
      socket.emit("gps2", imei);
    },
    gps5: (imei: string) => {
      socket.emit("gps5", imei);
    },
    gps10: (imei: string) => {
      socket.emit("gps10", imei);
    },
    fotar: (imei: string) => {
      setLeft(false);
      socket.emit("fotar", imei);
    },
    fotal: (imei: string) => {
      setLeft(true);
      socket.emit("fotal", imei);
    },
    fcPercentage,
    lfcPercentage,
  };
  return (
    <SocketContext.Provider value={socketContext}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
export default useSocket;
