import gql from "graphql-tag";

export const FOLLOWING_USERS = gql`
  query GetFollowingUsers($userId: Float!, $uuid: String!) {
    allFollowingUsers(userId: $userId, uuid: $uuid) {
      followers {
        id
        uuid
        name
        fullname
        profile
        live
        current_shinguards
      }
    }
  }
`;

export const FOLLOW_USER = gql`
  mutation FollowUser($shinguard: String!, $userId: Float!) {
    followuser(shinguard: $shinguard, userId: $userId) {
      id
    }
  }
`;

export const REMOVE_FOLLOW = gql`
  mutation RemoveFollow($followingId: Float!, $userId: Float!) {
    removefollow(followingId: $followingId, userId: $userId)
  }
`;
