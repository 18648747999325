import React, { FC, Component, useState } from "react";
import { Map, GoogleApiWrapper, HeatMap, Marker } from "google-maps-react";
import ball from "../lib/assets/ballmap.png";
import shinguard from "../lib/assets/shinguard.png";
import heatmapImage from "../lib/assets/heatmap.png";
import hitImage from "../lib/assets/calibrate-hit.png";
import kickImage from "../lib/assets/calibrate-ball.png";
import logoIcon from "../lib/assets/logo.jpg";
import PropTypes from "prop-types";
import "./Map.css";
import { withStyles } from "@material-ui/core";
const mapStyles = {
  width: "100%",
  height: "100%",
};

const styles = {
  testeointeresante: {
    "& img['../lib/assets/logo.jpg']": {
      borderRadius: "50%",
    },
  },
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heatmap: true,
      kick: false,
      hit: false,
    };
  }

  render() {
    const {
      positions,
      latitude,
      longitude,
      zoom,
      disableDefaultUI,
      draggable,
      kicksPositions,
      live,
      markerLive,
      hitsPositions,
      classes,
    } = this.props;
    const { heatmap, kick, hit } = this.state;

    return (
      <Map
        google={this.props.google}
        style={mapStyles}
        className={classes.testeointeresante}
        initialCenter={{
          lat: latitude,
          lng: longitude,
        }}
        zoom={zoom}
        disableDefaultUI={disableDefaultUI}
        draggable={draggable}
      >
        {positions && (
          <HeatMap
            opacity={heatmap ? 1 : 0}
            positions={positions}
            radius={10}
          />
        )}
        {kick && kicksPositions &&
          kicksPositions.map((kicks, index) => (
            <Marker
              key={index}
              position={{ lat: kicks.lat, lng: kicks.lng }}
              icon={ball}
              width={30}
              height={30}
            />
          ))}
        {markerLive && (
          <Marker position={{ lat: markerLive.lat, lng: markerLive.lng }} />
        )}
        {hit && hitsPositions &&
          hitsPositions.map((hits, index) => (
            <Marker
              key={index}
              position={{ lat: hits.lat, lng: hits.lng }}
              icon={shinguard}
              width={20}
              height={50}
            />
          ))}

        {!live && (
          <div
            style={{
              position: "absolute",
              width: 64,
              height: 64,
              zIndex: 10,
              top: 16,
              right: 16,
              background: "#000",
              borderRadius: "5px",
              lineHeight: "64px",
              padding: "4px",
              textAlign: "center",
              opacity: heatmap ? 1 : 0.5,
            }}
            onClick={() => {
              this.setState({ heatmap: !heatmap });
            }}
          >
            <img
              src={heatmapImage}
              style={{
                width: 48,
                paddingTop: 4,
              }}
            />
          </div>
        )}
        {!live && (
          <div
            style={{
              position: "absolute",
              width: 64,
              height: 64,
              zIndex: 10,
              top: 92,
              right: 16,
              background: "#000",
              borderRadius: "5px",
              lineHeight: "64px",
              padding: "4px",
              textAlign: "center",
              opacity: kick ? 1 : 0.5,
            }}
            onClick={() => {
              this.setState({ kick: !kick });
            }}
          >
            <img
              src={kickImage}
              style={{
                width: 42,
                paddingTop: 8,
              }}
            />
          </div>
        )}
        {!live && (
          <div
            style={{
              position: "absolute",
              width: 64,
              height: 64,
              zIndex: 10,
              top: 166,
              right: 16,
              background: "#000",
              borderRadius: "5px",
              lineHeight: "64px",
              padding: "4px",
              textAlign: "center",
              opacity: hit ? 1 : 0.5,
            }}
            onClick={() => {
              this.setState({ hit: !hit });
            }}
          >
            <img
              src={hitImage}
              style={{
                width: 34,
                paddingTop: 4,
              }}
            />
          </div>
        )}
      </Map>
    );
  }
}
export default GoogleApiWrapper((props) => ({
  // For PRODUCTION
  apiKey: "AIzaSyC1y5EKJWcZAUj_hBO2K7RzolEX9-GeiQc",
  // For testing
  // apiKey: "AIzaSyALI8sRiD4iv2mL0oT4JS-8sAIueXYgQrg",
  libraries: ["visualization"],
}))(withStyles(styles)(MapContainer));

MapContainer.defaultProps = {
  positions: [{ lat: 0, lng: 0 }],
  latitude: 0,
  longitude: 0,
  zoom: 17,
  disableDefaultUI: true,
  draggable: false,
};
MapContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};
