import React, { FC, useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Container,
  Card,
  TextField,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import logo from "../../lib/assets/logo2.png";
import { useMutation } from "@apollo/client";
import {
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  GET_USER_FROM_TOKEN,
} from "../../lib/queries/user.queries";
import { useHistory, useLocation } from "react-router-dom";
import DialogModal from "../../Components/DialogModal";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faEnvelope,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const RecoverPassword: FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [pin, setPin] = useState();
  const [pin2, setPin2] = useState();
  const classes = useStyles();
  const [error, setError] = useState();
  const [recover] = useMutation(RECOVER_PASSWORD);
  const [change] = useMutation(RESET_PASSWORD);
  const [getUserFromToken] = useMutation(GET_USER_FROM_TOKEN);
  const [userId, setUserId] = useState();
  const [mailSend, setMailSend] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const query = useQueryParams();
  let token = query.get("token");
  const { t } = useTranslation();
  const [errorPassword, setErrorPassword] = useState();

  const recoverPassword = async () => {
    try {
      const { data: recoverPasswordData } = await recover({
        variables: { email },
      });
      setMailSend(true);
    } catch (e) {
      setMailSend(true);
    }
  };

  const changePassword = async () => {
    setErrorPassword(false);
    if (pin != pin2) {
      setErrorPassword(t("common.errorsForm.passworddontMatch"));
      window.scroll({
        top: 10,
        left: 100,
        behavior: "smooth",
      });
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!&])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!&]{1,16}$/g.test(
        pin
      )
    ) {
      setErrorPassword(t("common.errorsForm.passwordContain"));
      window.scroll({
        top: 10,
        left: 100,
        behavior: "smooth",
      });
    } else {
      console.log("Valor de token", token);
      const { data: changePasswordData } = await change({
        variables: { userId, pin, token },
      });
      if (
        changePasswordData &&
        changePasswordData.updatepassworduserwithtoken == "ok"
      ) {
        setPasswordChanged(true);
      }
    }
  };
  const getUser = async () => {
    const { data: userData } = await getUserFromToken({
      variables: { token },
    });
    setUserId(userData.getuserfromtoken);
  };
  useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token]);

  return (
    <Container>
      <div
        className={classes.backButtonContainer}
        onClick={() => history.goBack()}
      >
        <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#3AAA35" />
      </div>
      <div className={classes.recoverContainer}>
        <Card variant="outlined" className={classes.card}>
          <img src={logo} className={classes.logo} />
          {!token && (
            <div>
              <Typography variant="h5">
                {t("recoverPasswordScreen.recover")}
              </Typography>
              <Grid
                item
                xs={12}
                style={{
                  padding: "0 12px",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <FontAwesomeIcon icon={faEnvelope} size="2x" />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="email"
                      name="email"
                      label={t("common.form.email")}
                      required
                      style={{ width: "100%", margin: "10px 0px" }}
                      value={email}
                      error={error}
                      helperText={
                        error ? "El email o la contraseña no son correctas" : ""
                      }
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setEmail(event.target.value as string);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Button
                color="primary"
                variant="contained"
                onClick={() => recoverPassword()}
              >
                {t("recoverPasswordScreen.buttonrecover")}
              </Button>
            </div>
          )}
          {token && (
            <div>
              <Grid
                item
                xs={12}
                style={{
                  padding: "0 12px",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <FontAwesomeIcon icon={faLock} size="2x" />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t("common.form.password")}
                      type="password"
                      helperText={errorPassword}
                      required
                      style={{ width: "100%", margin: "10px 0px" }}
                      value={pin}
                      error={errorPassword}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setPin(event.target.value as string);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: "0 12px",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <FontAwesomeIcon icon={faLock} size="2x" />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t("common.form.repassword")}
                      type="password"
                      required
                      style={{ width: "100%", margin: "10px 0px" }}
                      value={pin2}
                      error={errorPassword}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setPin2(event.target.value as string);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Button
                color="primary"
                variant="contained"
                onClick={() => changePassword()}
              >
                {t("recoverPasswordScreen.change")}
              </Button>
            </div>
          )}

          <Grid container>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Card>
      </div>
      <DialogModal
        open={mailSend}
        onOk={() => setMailSend(false)}
        onCancel={() => setMailSend(false)}
        buttonAgree={t("common.accept")}
        title={t("recoverPasswordScreen.recoverTitle")}
        text={t("recoverPasswordScreen.recoverText")}
      ></DialogModal>
      <DialogModal
        open={passwordChanged}
        onOk={() => {
          setPasswordChanged(false);
          history.push("/login");
        }}
        onCancel={() => setPasswordChanged(false)}
        buttonAgree={t("common.accept")}
        title={t("recoverPasswordScreen.changePassword")}
        text={t("recoverPasswordScreen.changePasswordText")}
      ></DialogModal>
    </Container>
  );
};

export default RecoverPassword;

const useStyles = makeStyles(({ palette, breakpoints }: Theme) =>
  createStyles({
    recoverContainer: {
      margin: 0,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    card: {
      maxWidth: 300,
      margin: "auto",
      padding: 15,
      textAlign: "center",
      borderTop: "2px solid #3AAA35",
    },
    logo: {
      width: 200,
      height: "auto",
    },
    backButtonContainer: {
      margin: 10,
    },
  })
);
