import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  NoSsr,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Chip,
  Typography,
  Container,
} from "@material-ui/core";
import MuiPhoneNumber from "mui-phone-input-ssr";

import CreditCardIcon from "@material-ui/icons/CreditCard";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  PLACE_ORDER,
  USER_PROFILE,
  UPDATE_PROFILE,
  CREATE_USER_CUSTOMERCARE,
} from "../../lib/queries/user.queries";
import { useHistory, useLocation } from "react-router-dom";
import humanoxLogo from "../../lib/assets/logo2.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  faShieldAlt,
  faUser,
  faSignature,
  faEnvelope,
  faPhone,
  faBirthdayCake,
  faVenusMars,
  faRulerVertical,
  faWeight,
  faTemperatureLow,
  faSearch,
  faBuilding,
  faCity,
  faFlag,
  faMailBulk,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import useAuth from "../../Services/Auth";
const token =
  "W2GryG9ChOWxVwN0X91Vr8q98JJT8XRgDc2hHdeVLvkoWUCF4IS8RqXVMAQkzLUsE6I0f6vtgYa4gvEE3qApmCbIwB4XhMZuHxY4hgN48qYDHXVgj4hwwqFt9e8RvjbF8W281kxDHPr64adFTWfUh1DKdtfLlfDElszwOdQf8nUML0XlGczeDiRog1rxwwGeB2jMZyR0OzPnkHYpASL2JKApLdAyvxVeysXPHorCkZ6VGGUIZeiu2Vh7YJFzgCJ5";

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const StoreScreen: FC = (props) => {
  const query = useQueryParams();
  const { t } = useTranslation();
  const location = useLocation();
  const auth = useAuth();
  let id = props && props.userData && props.userData.id;
  let uuid = auth && auth.userData && auth.userData.uuid;
  if (location && location.state && location.state.id) {
    id = location.state.id;
    uuid = localStorage.getItem("uuid");
  }
  let response = query.get("status");
  const classes = useStyles();
  const buttonRef = useRef();
  const buttonRedsysRef = useRef();
  const history = useHistory();

  const { data: profileData } = useQuery(USER_PROFILE, {
    variables: { id: parseFloat(id), uuid },
  });
  const [placeOrder] = useMutation(PLACE_ORDER);
  const [href, setHref] = useState("");
  const [signatureVersion, setSignatureVersion] = useState();
  const [merchantParameters, setMerchantParameters] = useState();
  const [signature, setSignature] = useState();
  const [redsysAction, setRedsysAction] = useState(
    "https://sis-t.redsys.es:25443/sis/realizarPago"
  );
  const [product, setProduct] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [suscription_type, setSuscription] = useState("P");
  const [totalShinguardPrice, setTotalShinguardPrice] = useState(288);
  const [totalPlatformAnualPrice, setTotalPlatformAnualPrice] = useState(43);
  const [totalPlatformMonthlyPrice, setTotalPlatformMonthlyPrice] = useState(
    4.5
  );
  const shinguardPirce = 288;
  const platformAnualPrice = 43;
  const platformMonthlyPrice = 4.5;
  const [addressOrderDialog, setAddressOrderDialog] = useState(false);
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [zipcode, setZipcode] = useState();
  const [phone, setPhone] = useState();
  const [edit, setEdit] = useState();
  const [updateProfileMutation] = useMutation(UPDATE_PROFILE);
  const [createUserCustomerCare] = useMutation(CREATE_USER_CUSTOMERCARE);

  const options = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    if (profileData && profileData.getProfile) {
      setAddress1(profileData.getProfile.address1);
      setAddress2(profileData.getProfile.address2);
      setCity(profileData.getProfile.city);
      setCountry(profileData.getProfile.country);
      setZipcode(profileData.getProfile.zipcode);
      setPhone(
        profileData.getProfile.phone &&
          profileData.getProfile.phone.replace(/ /g, "")
      );
      if (address1 != null) {
        setEdit(false);
      }
    }
  }, [profileData]);

  const updateUserAddress = async () => {
    let updateProfileData = {
      profile: profileData.getProfile.profile,
      teamname: profileData.getProfile.teamname,
      name: profileData.getProfile.name,
      fullname: profileData.getProfile.fullname,
      email: profileData.getProfile.email,
      phone: profileData.getProfile.phone,
      birthdate: profileData.getProfile.birthdate,
      gender: profileData.getProfile.gender,
      height: profileData.getProfile.height,
      weight: profileData.getProfile.weight,
      ground_temperature: profileData.getProfile.ground_temperature,
      canbefollowed: profileData.getProfile.canbefollowed,
      address1,
      address2,
      city,
      country,
      zipcode,
    };
    try {
      const { data: updatedProfile } = await updateProfileMutation({
        variables: { updateProfileData, userId: parseFloat(id), uuid },
      });
      let userCustomer = {
        customer_id: parseFloat(id),
        username: profileData.getProfile.name,
        first_name: profileData.getProfile.fullname
          ? profileData.getProfile.fullname
          : "first_name",
        last_name: profileData.getProfile.fullname
          ? profileData.getProfile.fullname
          : "last_name",
        height: profileData.getProfile.height
          ? parseFloat(profileData.getProfile.height)
          : 0,
        weight: profileData.getProfile.weight
          ? parseFloat(profileData.getProfile.weight)
          : 0,
        phone: phone.replace(/ /g, ""),
        address1: address1 ? address1 : "Empty",
        address2: address2 ? address2 : "Empty",
        postalcode: zipcode ? zipcode : "11000",
        city: city ? city : "Empty",
        country_code: country,
        email: profileData.getProfile.email,
        birthdate: profileData.getProfile.birthdate,
        invoice: 0,
      };
      let resUserCustomer = await createUserCustomerCare({
        variables: { userCustomer },
      });
      setAddressOrderDialog(false);
      getOrder();
    } catch (e) {
      //getOrder();
    }
  };

  const getOrder = async () => {
    let placeOrderData = {
      is_gift: "0",
      activation_code: "",
      shinguard_size: "S",
      quantity,
    };
    const { data: orderData } = await placeOrder({
      variables: { placeOrderData, userId: parseFloat(id) },
    });
    if (
      orderData &&
      orderData.placeorder &&
      orderData.placeorder.id &&
      profileData &&
      profileData.getProfile
    ) {
      const {
        fullname,
        phone,
        email,
        birthdate,
        createts,
      } = profileData.getProfile;

      if (suscription_type != "C") {
        let paymentData = {};
        let orderLines = [];
        if (suscription_type == "P") {
          for (let i = 0; i < quantity; i++) {
            orderLines.push({
              order_position: i + 1,
              amount: 288,
              order_status: 1,
              register_date: new Date().toISOString().slice(0, 10),
              size: "S",
            });
          }
        } else if (suscription_type == "Y") {
          for (let i = 0; i < quantity; i++) {
            orderLines.push({
              order_position: i + 1,
              amount: 43,
              order_status: 1,
              register_date: new Date().toISOString().slice(0, 10),
              size: "S",
            });
          }
        } else {
          for (let i = 0; i < quantity; i++) {
            orderLines.push({
              order_position: i + 1,
              amount: 4.5,
              order_status: 1,
              register_date: new Date().toISOString().slice(0, 10),
              size: "S",
            });
          }
        }

        paymentData = {
          order_number: orderData.placeorder.id.toString(),
          customer_id: id,
          suscription_type: suscription_type,
          invoice: "0",
          shipping_address: {
            address: address1 + " " + address2,
            postalcode: zipcode,
            city: city,
            countrycode: "es",
          },
          order_lines: orderLines,
        };

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(paymentData),
        };
        fetch(
          "https://customercare.humanox.com/api/v1/order",
          requestOptions
        ).then(async (response) => {
          const res = await response.json();
          if (res && res.code == 200) {
            setSignature(res.data.Ds_Signature);
            setMerchantParameters(res.data.Ds_MerchantParameters);
            setSignatureVersion(res.data.Ds_SignatureVersion);
            setRedsysAction(res.data.url);
            buttonRedsysRef.current.click();
          }
        });
      } else {
        let credit = {
          c: {
            orderId: orderData.placeorder.id.toString(),
            custom: "",
            amount: totalShinguardPrice,
            currency: "EUR",
            country: country == 34 ? "ES" : "",
            successURL: "http://live.humanox.com/response/ok",
            failURL: "http://live.humanox.com/response/ko",
            notificationURL: "https://live.humanox.com:5357/payment/ic/status",
            endUser: {
              endUserId: id,
              ip: "",
              firstName: fullname,
              lastName: fullname,
              personalId: "",
              email: email,
              birthDate: birthdate,
              mobile: phone,
              alternativePhoneNumber: "",
              address: {
                street: address1 && address2 ? address1 + address2 : "",
                postalCode: zipcode ? zipcode : "",
                province: zipcode ? zipcode.slice(0, 2) : "", // dos primeros digitos del código postal
                city: city ? city : "",
                country: country == 34 ? "ES" : "",
              },
              registrationDate: createts,
              historicOrders: [],
            },
          },
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title: "React POST Request Example" }),
        };
        fetch("https://live.humanox.com:5357/payment/ic/token", requestOptions)
          .then(async (response) => {
            const res = await response.json();
            credit.token = res.accessToken;
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(credit),
            };
            fetch(
              "https://live.humanox.com:5357/payment/ic/credit",
              requestOptions
            )
              .then(async (response) => {
                const res = await response.json();

                setHref(res.url);
                buttonRef.current.click();
              })
              .catch((error) => console.log("Error", error));
          })
          .catch((error) => console.log("Error", error));
      }
    } else {
      history.push("/store?status=KO");
    }
  };

  return (
    <Container style={{ paddingBottom: 5 }}>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.paymentResponse}>
          {response && <img src={humanoxLogo} className={classes.logo} />}
          {response == "OK" && (
            <div>
              <CheckCircleIcon color="primary" className={classes.checkIcon} />
              <Typography variant="h5">
                {t("storeScreen.confirmedOrder")}
              </Typography>
              <Typography variant="subtitle1">
                {t("storeScreen.confirmEmail")}
              </Typography>
            </div>
          )}
          {response == "KO" && (
            <div>
              <CancelIcon color="error" className={classes.checkIcon} />
              <Typography variant="h5">
                {t("storeScreen.errorOrder")}
              </Typography>
              <Typography variant="subtitle1">
                {t("storeScreen.errorOrderText")}
              </Typography>
            </div>
          )}
        </Grid>
        {!response && (
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.inputContainer}>
              <TextField
                select
                label={t("storeScreen.selectProduct")}
                id="demo-simple-select"
                style={{ width: "100%", margin: "10px 0px" }}
                defaultValue={1}
                value={product}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setProduct(event.target.value as Number);
                  if ((event.target.value as Number) == 1) {
                    setSuscription("P");
                  } else {
                    setSuscription("Y");
                  }
                }}
              >
                <MenuItem value={1}>{t("storeScreen.platformAccess")}</MenuItem>
                <MenuItem value={2}>{t("storeScreen.shinguards")}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputContainer}>
              <TextField
                label={t("storeScreen.quantity")}
                type="number"
                style={{ width: "100%", margin: "10px 0px" }}
                value={quantity}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  if (!isNaN(parseInt(event.target.value as Number))) {
                    if (parseInt(event.target.value as Number) > 0) {
                      setQuantity(parseInt(event.target.value as Number));
                      setTotalShinguardPrice(
                        shinguardPirce * parseInt(event.target.value as Number)
                      );
                      setTotalPlatformAnualPrice(
                        platformAnualPrice *
                          parseInt(event.target.value as Number)
                      );
                      setTotalPlatformMonthlyPrice(
                        platformMonthlyPrice *
                          parseInt(event.target.value as Number)
                      );
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.iconCardContainer}>
                <CreditCardIcon color="primary" className={classes.iconCard} />
                <Typography variant="h6">
                  {" "}
                  {t("storeScreen.paymentMode")}
                </Typography>
              </div>
              <Grid container className={classes.paymentModeContainer}>
                <Grid item xs={12} sm={6}>
                  <RadioGroup
                    aria-label="payment_mode"
                    name="payment_mode"
                    column
                    value={suscription_type}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSuscription((event.target as HTMLInputElement).value);
                    }}
                  >
                    {product == 1 && (
                      <FormControlLabel
                        value="Y"
                        control={<Radio color="primary" />}
                        label={t("storeScreen.annual", {
                          totalPlatformAnualPrice,
                        })}
                      />
                    )}
                    {product == 1 && (
                      <FormControlLabel
                        value="M"
                        control={<Radio color="primary" />}
                        label={t("storeScreen.monthly", {
                          totalPlatformMonthlyPrice,
                        })}
                      />
                    )}
                    {product == 2 && (
                      <FormControlLabel
                        value="P"
                        control={<Radio color="primary" />}
                        label={t("storeScreen.sigle", {
                          totalShinguardPrice,
                        })}
                      />
                    )}
              
                  
                  </RadioGroup>
                </Grid>

              </Grid>
              <Typography variant="h7">
                  {" "}
                  {t("storeScreen.dollarsDisclaimer")}
                </Typography>
            </Grid>
            <form method="POST" id="redsys" action={redsysAction}>
              <input
                type="hidden"
                name="Ds_SignatureVersion"
                value={signatureVersion}
              />
              <input
                type="hidden"
                name="Ds_MerchantParameters"
                value={merchantParameters}
              />
              <input type="hidden" name="Ds_Signature" value={signature} />

              <button
                type="submit"
                style={{ display: "none" }}
                ref={buttonRedsysRef}
              ></button>
            </form>
            <Grid item xs={12} className={classes.buttonSubmitContainer}>
              {product && suscription_type != "C" && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setAddressOrderDialog(true)}
                >
                  {t("storeScreen.payWithCard")}
                </Button>
              )}
              {product == 1 && suscription_type == "C" && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setAddressOrderDialog(true)}
                >
                  {t("storeScreen.payWithInsta")}
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        <Dialog
          open={addressOrderDialog}
          onClose={() => {
            setAddressOrderDialog(false);
            setEdit(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("storeScreen.shippingInformation")}
          </DialogTitle>
          <DialogContent>
            {profileData && profileData.getProfile.address1 == null && (
              <DialogContentText>
                {t("storeScreen.noAddress")}
              </DialogContentText>
            )}
            {profileData && profileData.getProfile.address1 != null && (
              <DialogContentText>{t("storeScreen.address")}</DialogContentText>
            )}
          </DialogContent>
          <fieldset className={classes.fieldset}>
            <legend className={classes.legend}> {t("storeScreen.send")}</legend>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ width: "15%" }}>
                <FontAwesomeIcon icon={faHouseUser} size="2x" />
              </Grid>
              <Grid item style={{ width: "85%" }}>
                <TextField
                  label={t("common.form.address1")}
                  disabled={!edit}
                  required
                  defaultValue={address1}
                  style={{ width: "100%", margin: "10px 0px" }}
                  value={address1}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setAddress1(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ width: "15%" }}>
                <FontAwesomeIcon icon={faBuilding} size="2x" />
              </Grid>
              <Grid item style={{ width: "85%" }}>
                <TextField
                  label={t("common.form.address2")}
                  disabled={!edit}
                  defaultValue={address2}
                  style={{ width: "100%", margin: "10px 0px" }}
                  value={address2}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setAddress2(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ width: "15%" }}>
                <FontAwesomeIcon icon={faCity} size="2x" />
              </Grid>
              <Grid item style={{ width: "85%" }}>
                <TextField
                  label={t("common.form.city")}
                  disabled={!edit}
                  required
                  defaultValue={city}
                  style={{ width: "100%", margin: "10px 0px" }}
                  value={city}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setCity(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ width: "15%" }}>
                <FontAwesomeIcon icon={faMailBulk} size="2x" />
              </Grid>
              <Grid item style={{ width: "85%" }}>
                <TextField
                  label={t("common.form.zipcode")}
                  disabled={!edit}
                  required
                  defaultValue={zipcode}
                  style={{ width: "100%", margin: "10px 0px" }}
                  value={zipcode}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setZipcode(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ width: "15%" }}>
                <FontAwesomeIcon icon={faFlag} size="2x" />
              </Grid>
              <Grid item style={{ width: "85%" }}>
                <TextField
                  select
                  label={t("common.form.country")}
                  id="demo-simple-select"
                  required
                  disabled={!edit}
                  style={{ width: "100%", margin: "10px 0px" }}
                  value={country}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setCountry(event.target.value);
                  }}
                >
                  {options.map((country) => (
                    <MenuItem value={country.value}>{country.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ width: "15%" }}>
                <FontAwesomeIcon icon={faPhone} size="2x" />
              </Grid>
              <Grid item style={{ width: "85%" }}>
                <MuiPhoneNumber
                  id="ss_phone"
                  name="ss_phone"
                  required
                  disabled={!edit}
                  style={{ width: "100%", margin: "10px 0px" }}
                  label={t("common.form.phone")}
                  data-cy="user-phone"
                  regions={["america", "europe", "asia", "oceania", "africa"]}
                  defaultCountry={"es"}
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                  }}
                />
              </Grid>
            </Grid>
          </fieldset>
          <DialogActions style={{ padding: 20 }}>
            <Button
              onClick={() => {
                setAddressOrderDialog(false);
                setEdit(false);
              }}
              color="primary"
              variant="contained"
            >
              {t("common.cancel")}
            </Button>
            {!edit && (
              <Button
                onClick={() => setEdit(true)}
                variant="contained"
                color="primary"
              >
                {t("common.form.edit")}
              </Button>
            )}
            {!edit && (
              <Button
                onClick={() => updateUserAddress()}
                disabled={
                  !address1 ||
                  !city ||
                  !zipcode ||
                  !country ||
                  !phone ||
                  (phone && phone.length <= 11)
                }
                variant="contained"
                color="primary"
              >
                {t("common.order")}
              </Button>
            )}
            {edit && (
              <Button
                onClick={() => updateUserAddress()}
                color="primary"
                variant="contained"
                disabled={
                  !address1 ||
                  !city ||
                  !zipcode ||
                  !country ||
                  !phone ||
                  (phone && phone.length <= 11)
                }
              >
                {t("common.order")}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Grid>
    </Container>
  );
};

export default StoreScreen;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
    inputContainer: {
      padding: 5,
    },
    iconCard: {
      height: 200,
      width: 250,
    },
    iconCardContainer: {
      textAlign: "center",
    },
    paymentModeContainer: {
      marginTop: 50,
    },
    buttonSubmitContainer: {
      textAlign: "center",
      marginTop: 50,
    },
    logo: {
      maxWidth: 300,
      height: "auto",
    },
    paymentResponse: {
      marginTop: 30,
      textAlign: "center",
    },
    checkIcon: {
      width: "14rem",
      height: "auto",
    },
    fieldset: {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 8,
      padding: 20,
      margin: "10px 20px",
    },
    legend: {
      textTransform: "uppercase",
      fontSize: 14,
    },
  })
);
