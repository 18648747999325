import React, {FC, useContext, useEffect, useMemo, useState} from "react";

import {useHistory, useLocation} from "react-router-dom";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
  MATCHES_QUERY,
  CREATE_MATCH,
  END_MATCH,
  CHECK_LIVE_MATCH,
  CHECK_MATCH_ENDED,
  DELETE_MATCH,
  LIVE_MATCH_INFO,
  SAVE_REQUEST_DATA,
} from "../../lib/queries/match.queries";
import {USER_QUERY} from "../../lib/queries/user.queries";
import MatchItem from "../../Components/MatchItem";
import Spinner from "../../Components/Spinner";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Collapse,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SpeedIcon from "@material-ui/icons/Speed";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ball from "../../lib/assets/ball-white.png";
import shinguardsImage from "../../lib/assets/signalShinguards.png";
import {convertStupidCoordinate} from "../../Services/Utils";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import MapContainer from "../../Components/Map";
import Gauge from "../../Components/Gauge";
import dayjs from "dayjs";
import StartMacthDialog from "../../Components/StartMatchDialog";
import Timer from "../../Components/Timer";
import SignalShinguardsModal from "../../Components/SignalShinguardsDialog";
import {useTranslation} from "react-i18next";
import {
  faVideo,
  faChevronLeft,
  faShoePrints,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const MatchesScreen: FC = (props) => {
  const history = useHistory();
  const {t} = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [startMatch, setStartMatch] = useState(false);
  const [showStatusError, setShowStatusError] = useState(false);
  const [showSignalModal, setShowSignalModal] = useState(false);
  const [tryingToRecord, setTryingToRecord] = useState(false);
  const [matchStartTime, setMatchStartTime] = useState(0);
  const [recording, setRecording] = useState(false);
  const [matchId, setMatchId] = useState();
  const [lowSignalModal, setLowSignalModal] = useState(false);
  const [numKicks, setNumKicks] = useState(0);
  const [numHits, setNumHits] = useState(0);
  const [firstTime, setFirsTime] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [matchInfo, setMatchInfo] = useState();
  const [modalSubscription, setModalSubscription] = useState(false);
  const [modalShinguards, setModalShinguards] = useState(false);
  const [modalShinguardsCheck, setModalShinguardsCheck] = useState(false);
  const [openRealTimeData, setOpenRealTimeData] = useState(false);

  let id = props && props.userData && props.userData.id;
  let uuid = props && props.userData && props.userData.uuid;
  if (location && location.state && location.state.id) {
    id = parseFloat(location.state.id);
    uuid = localStorage.getItem("uuid");
  }
  if (
    props &&
    props.match &&
    props.match.params &&
    props.match.params.id &&
    location &&
    location.state &&
    location.state.uuid
  ) {
    id = parseFloat(props.match.params.id);
    uuid = location && location.state && location.state.uuid;
  }

  const [createMatch] = useMutation(CREATE_MATCH);
  const [endMatch] = useMutation(END_MATCH);
  const [deleteMatch] = useMutation(DELETE_MATCH);
  const {data: userData} = useQuery(USER_QUERY, {variables: {id, uuid}});
  const {data: liveMatchData, refetch: refetchLiveMatchData} = useQuery(
    CHECK_LIVE_MATCH,
    {
      variables: {userId: id, uuid: uuid},
    }
  );

  useEffect(() => {
    if (location && location.pathname && location.pathname.includes("admin")) {
      setAdmin(true);
    }
  }, [location]);
  //console.log("Valor de userData", userData);
  const player = userData && userData.getUser;
  const shinguards = player && parseFloat(player.current_shinguards);
  const liveMatch = liveMatchData && liveMatchData.checklivematch;
  const hasImperialSystem = player ? userData.getUser.measurement_format == 'imperial' : '';
  const [
    checkMatchEnded,
    {data: matchended, loading, stopPolling: stopCheckMacth},
  ] = useLazyQuery(CHECK_MATCH_ENDED, {
    variables: {matchid: -1, userid: -1},
    pollInterval: 10000,
  });
  const [
    liveMatchInfo,
    {data: liveMatchInfoData, stopPolling: stop},
  ] = useLazyQuery(LIVE_MATCH_INFO, {
    pollInterval: 2000,
  });
  const [
    saveRequest,
    {data: saveRequestData},] = useLazyQuery(SAVE_REQUEST_DATA);

  const {data: matchesData, refetch: refetchMatches} = useQuery(
    MATCHES_QUERY,
    {
      variables: {id, uuid},
    }
  );
  const matches =
    (matchesData &&
      matchesData.getlistallmatches &&
      matchesData.getlistallmatches.matches) ||
    [];

  useEffect(() => {
    if (location && location.state && location.state.fromLogin) {
      if (!firstTime) {
        setFirsTime(true);
      }
    }
  }, [location]);

  useEffect(() => {
    if (recording && shinguards) {
      liveMatchInfo({
        variables: {matchId: matchId, shinguard: shinguards},
      });
    }
  }, [recording, shinguards]);
  let matchesArray = matches
    .slice()
    .sort((a, b) => (b.startTime > a.startTime ? 1 : -1));
  useEffect(() => {
    setRecording(false);
    refetchLiveMatchData();
    refetchMatches();
  }, [id]);
  useEffect(() => {
    if (liveMatch && liveMatch.id && liveMatch.startTime) {
      setMatchId(liveMatch.id);
      setMatchStartTime(liveMatch.startTime);
      setRecording(true);
      checkMatchEnded({variables: {matchid: liveMatch.id, userid: id}});
    }
  }, [liveMatch]);

  useEffect(() => {
    if (matchended && matchended.checkmatchended == "OK" && !loading) {
      stopCheckMacth;
      stop;
      setRecording(false);
    }
  });

  const startRecording = async (inputstart: any) => {
    setTryingToRecord(true);
    try {
      const {data} = await createMatch({
        variables: {input: {...inputstart}, userId: id},
      });
      setMatchId(data.startmatch.id);
      setMatchStartTime(Date.now());
      setRecording(true);
      setTryingToRecord(false);
    } catch (e) {
      console.log("Error", e);
      setTryingToRecord(false);
      setShowStatusError(true);
      setTimeout(() => setShowStatusError(false), 2000);
    }
  };

  const stopRecording = async () => {
    try {
      saveRequest({
        variables: {matchId: matchId, userId: id},
      });
      let resEndMatch = await endMatch({
        variables: {
          id,
          matchId,
          timefinish: dayjs(new Date()).format(hasImperialSystem ? "YYYY-MM-DD HH:mm:ss" : "DD-MM-YYYY HH:mm:ss"),
        },
      });
      setRecording(false);
      refetchMatches();
      refetchLiveMatchData();
      if (isAdmin) {
        history.push({
          pathname: `/admin/match/${id}/${resEndMatch.data.stopmatch.id}`,
          state: {id: id},
        });
      } else {
        history.push(`/matches`);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const removeMatch = async (matchId: Float32Array) => {
    await deleteMatch({variables: {matchId, userId: id}});
    refetchMatches();
  };
  const points = useMemo(() => {
    const poinstData = liveMatchInfoData && liveMatchInfoData.livematchinfo;
    if (!poinstData) {
      return [{lat: 0, lng: 0}];
    } else {
      setRecording(true);
    }

    setNumKicks(liveMatchInfoData && liveMatchInfoData.livematchinfo.event_bk);
    setNumHits(liveMatchInfoData && liveMatchInfoData.livematchinfo.event_fk);

    return {
      lat: convertStupidCoordinate(poinstData.geo_lat),
      lng: convertStupidCoordinate(poinstData.geo_lon),
    };
  }, [liveMatchInfoData]);

  return (
    <Container>
      <Spinner
        open={tryingToRecord}
        message={t("matchesScreen.creatingMatch")}
      />
      <Spinner
        open={showStatusError}
        message={t("matchesScreen.errorCreatingMatch")}
        hideSpinner
      />
      {props &&
      props.match &&
      props.match.params &&
      props.match.params.id &&
      player && (
        <div style={{position: "relative"}}>
          <div
            style={{position: "absolute"}}
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#3AAA35"/>
          </div>
          <Typography
            variant="h4"
            style={{textAlign: "center", marginBottom: 10}}
          >
            {t("matchesScreen.matchesOf")} {player && player.name}
          </Typography>
        </div>
      )}
      <div style={{position: "relative", zIndex: 1}}>
        {recording && (
          <Accordion
            expanded={openRealTimeData}
            onChange={() => {
              setOpenRealTimeData(!openRealTimeData);
            }}
            style={{marginBottom: 10}}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" fontSize="large"/>}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <FontAwesomeIcon
                icon={faVideo}
                size="2x"
                style={{color: "#3AAA35", marginRight: 10}}
              />
              <Typography variant="h5">
                {t("matchesScreen.realTime")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{flexDirection: "column"}}>
              <Collapse in={recording} timeout={1500}>
                <div>
                  {liveMatchInfoData &&
                  liveMatchInfoData.livematchinfo &&
                  points &&
                  points.lat != 0 && (
                    <div style={{height: 500, position: "relative"}}>
                      <MapContainer
                        markerLive={{
                          lat: points && points.lat != 0 ? points.lat : null,
                          lng: points && points.lng != 0 ? points.lng : null,
                        }}
                        positions={null}
                        latitude={
                          points && points.lat != 0 ? points.lat : points.lat
                        }
                        longitude={
                          points && points.lng != 0 ? points.lng : points.lng
                        }
                        zoom={19}
                        live={true}
                        disableDefaultUI={true}
                        draggable={true}
                      />
                    </div>
                  )}
                  <Grid
                    container
                    spacing={3}
                    justify="space-around"
                    className={classes.gaugeContainer}
                  >
                    <Grid item xs={12} sm={4} className={classes.gaugeInfo}>
                      <div className={classes.sectionTitleContainer}>
                        <SpeedIcon color="primary" fontSize="large"/>
                        <Typography variant="h5">
                          {t("matchScreen.speed")}
                        </Typography>
                      </div>
                      <Gauge
                        value={
                          liveMatchInfoData &&
                          liveMatchInfoData.livematchinfo &&
                          !isNaN(liveMatchInfoData.livematchinfo.speed) &&
                          liveMatchInfoData.livematchinfo.speed
                            ? liveMatchInfoData.livematchinfo.speed
                            : 0
                        }
                        max={hasImperialSystem ? 40.075 : 50.001}
                        units={hasImperialSystem ? "mi/h" : "km/h"}
                        label={t("matchScreen.speed")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gaugeInfo}>
                      <div className={classes.sectionTitleContainer}>
                        <FavoriteIcon color="primary" fontSize="large"/>
                        <Typography variant="h5">
                          {t("matchScreen.heart")}
                        </Typography>
                      </div>
                      <Gauge
                        value={
                          liveMatchInfoData &&
                          liveMatchInfoData.livematchinfo &&
                          !isNaN(liveMatchInfoData.livematchinfo.hr) &&
                          liveMatchInfoData.livematchinfo.hr
                            ? liveMatchInfoData.livematchinfo.hr
                            : 0
                        }
                        max={230.01}
                        units={t("matchScreen.bpm")}
                        label={t("matchScreen.hr")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gaugeInfo}>
                      <div className={classes.sectionTitleContainer}>
                        <WhatshotIcon color="primary" fontSize="large"/>
                        <Typography variant="h5">
                          {t("matchScreen.temp")}
                        </Typography>
                      </div>
                      <Gauge
                        value={
                          liveMatchInfoData &&
                          liveMatchInfoData.livematchinfo &&
                          !isNaN(liveMatchInfoData.livematchinfo.temperature) &&
                          liveMatchInfoData.livematchinfo.temperature
                            ? liveMatchInfoData.livematchinfo.temperature
                            : 0
                        }
                        max={hasImperialSystem ? 122 : 50}
                        units={hasImperialSystem ? "ºF" : "ºC"}
                        label={t("matchScreen.temp")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gaugeInfo}>
                      <div className={classes.sectionTitleContainer}>
                        <FontAwesomeIcon
                          icon={faUserShield}
                          size="2x"
                          color="#3AAA35"
                          style={{marginRight: 5}}
                        />
                        <Typography variant="h5">
                          {t("matchScreen.hitsTitle")}
                        </Typography>
                      </div>
                      <Gauge
                        value={numHits}
                        max={1000}
                        units={t("matchScreen.hits")}
                        label={t("matchScreen.numHits")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gaugeInfo}>
                      <div className={classes.sectionTitleContainer}>
                        <SportsSoccerIcon color="primary" fontSize="large"/>
                        <Typography variant="h5">
                          {t("matchScreen.kicksTitle")}
                        </Typography>
                      </div>
                      <Gauge
                        value={numKicks}
                        max={100}
                        units={t("matchScreen.kicks")}
                        label={t("matchScreen.numKicks")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gaugeInfo}>
                      <div className={classes.sectionTitleContainer}>
                        <FontAwesomeIcon
                          icon={faShoePrints}
                          size="2x"
                          color="#3AAA35"
                          style={{marginRight: 5}}
                        />
                        <Typography variant="h5">
                          {t("matchScreen.stepsTitle")}
                        </Typography>
                      </div>
                      <Gauge
                        value={
                          liveMatchInfoData &&
                          liveMatchInfoData.livematchinfo &&
                          !isNaN(liveMatchInfoData.livematchinfo.steps) &&
                          liveMatchInfoData.livematchinfo.steps
                            ? liveMatchInfoData.livematchinfo.steps
                            : 0
                        }
                        max={30000}
                        units={t("matchScreen.steps")}
                        label={t("matchScreen.numStpes")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {recording && (
                        <Timer
                          recording={recording}
                          startTime={matchStartTime}
                        />
                      )}
                      {/* <h2 className={classes.timer}>
                {formatNumber(hours)}:{formatNumber(minutes)}:
                {formatNumber(seconds)}
              </h2> */}
                    </Grid>
                  </Grid>
                </div>
              </Collapse>
            </AccordionDetails>
          </Accordion>
        )}

        <Grid container spacing={3} justify="center" alignItems="center">
          {matchesArray &&
          matchesArray.map((match) => (
            <MatchItem
              key={match.id}
              matchId={match.id}
              userId={id}
              name={match.name}
              date={dayjs(new Date(match.startTime)).format(hasImperialSystem ? "YYYY/MM/DD HH:mm:ss" : "DD/MM/YYYY HH:mm:ss")}
              team1Score={match.team1_score}
              team2Score={match.team2_score}
              onClick={() => {
                if (location && location.state && location.state.id) {
                  history.push({
                    pathname: `/admin/match/${id}/${match.id}`,
                    state: {id: match.id, userId: id, uuid: uuid},
                  });
                } else {
                  history.push({
                    pathname: `/match/${match.id}`,
                    state: {userId: id, uuid: uuid},
                  });
                }
              }}
              onDelete={() => {
                removeMatch(match.id);
              }}
            />
          ))}
          {matchesArray.length <= 0 && player && !player.current_shinguards && (
            <Grid item xs={12} style={{position: "relative"}}>
              <div className={classes.noMatchContainer}>
                <Typography variant="h4" style={{margin: "10px 0"}}>
                  {t("matchesScreen.noMatch")}
                </Typography>
                <Typography variant="h5">
                  {t("matchesScreen.noShinguards")}
                </Typography>
                <Button
                  style={{margin: "10px 0"}}
                  onClick={() => history.push("/shinguards")}
                  color="primary"
                  variant="contained"
                >
                  {t("matchesScreen.addShinguards")}
                </Button>
              </div>
            </Grid>
          )}
          {matchesArray.length <= 0 && player && player.current_shinguards && (
            <Grid item xs={12} style={{position: "relative"}}>
              <div className={classes.noMatchContainer}>
                <Typography variant="h4" style={{margin: "10px 0"}}>
                  {t("matchesScreen.noMatch")}
                </Typography>
                <Typography variant="h5">
                  {t("matchesScreen.noMatches")}
                </Typography>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      {!recording &&
      (!props.match.params.id || (props.match.params.id && isAdmin)) && (
        <FloatingMenu
          slideSpeed={500}
          spacing={8}
          isOpen={open}
          direction="up"
          className={classes.fab}
        >
          <MainButton
            iconResting={
              <AddIcon style={{fontSize: 20}} fontSize="large"/>
            }
            iconActive={
              <CloseIcon style={{fontSize: 20}} fontSize="large"/>
            }
            background="#3AAA35"
            onClick={() => {
              setOpen(!open);
            }}
            size={56}
          />

          <ChildButton
            icon={<img src={ball} className={classes.imageIcon}/>}
            background="#3AAA35"
            label={t("floatingButtons.startMatch")}
            size={40}
            left={-100}
            onClick={() => {
              setOpen(false);
              if (player && player.active != 1) {
                setModalSubscription(true);
              } else if (!shinguards || shinguards == 0) {
                setModalShinguards(true);
              } else {
                setStartMatch(true);
              }
            }}
          />
          <ChildButton
            icon={<img src={shinguardsImage} className={classes.imageIcon}/>}
            background="#3AAA35"
            label={t("floatingButtons.checkSignal")}
            size={40}
            left={-103}
            onClick={() => {
              if (!shinguards || shinguards == 0) {
                setOpen(false);
                setModalShinguardsCheck(true);
              } else {
                setOpen(false);
                setShowSignalModal(true);
              }
            }}
          />
        </FloatingMenu>
      )}
      {recording &&
      (!props.match.params.id || (props.match.params.id && isAdmin)) && (
        <FloatingMenu
          slideSpeed={500}
          spacing={8}
          isOpen={open}
          direction="up"
          className={classes.fab}
        >
          <MainButton
            iconResting={"Stop"}
            iconActive={"Stop"}
            background="#3AAA35"
            onClick={() => {
              stopRecording();
            }}
            size={56}
          />
        </FloatingMenu>
      )}

      <StartMacthDialog
        open={startMatch}
        shinguards={player && player.current_shinguards}
        onOk={(data: any) => {
          setStartMatch(false);
          setMatchInfo(data);
          startRecording(data);
        }}
        onCancel={() => {
          setStartMatch(false);
        }}
      />
      <SignalShinguardsModal
        open={showSignalModal}
        shinguards={player && player.current_shinguards}
        onCancel={() => setShowSignalModal(false)}
        onOk={() => setShowSignalModal(false)}
      />
      <Dialog
        open={lowSignalModal}
        onClose={() => setLowSignalModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("matchesScreen.gpsCoverageTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("matchesScreen.gpsCoverageText1")}
          </DialogContentText>
          <DialogContentText>
            {t("matchesScreen.gpsCoverageText2")}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setLowSignalModal(false);
            }}
            color="primary"
            variant="contained"
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={() => {
              setLowSignalModal(false);
              startRecording(matchInfo);
            }}
            color="primary"
            variant="contained"
          >
            {t("common.record")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalSubscription}
        onClose={() => setModalSubscription(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("matchesScreen.modalSuscriptionTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("matchesScreen.modalSuscriptionText1")}
          </DialogContentText>
          <DialogContentText>
            {t("matchesScreen.modalSuscriptionText3")}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setModalSubscription(false);
            }}
            color="primary"
            variant="contained"
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={() => {
              setModalSubscription(false);
              history.push("/store");
            }}
            color="primary"
            variant="contained"
          >
            {t("menu.store")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalShinguards}
        onClose={() => setModalShinguards(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("matchesScreen.modalShinguardsTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("matchesScreen.modalShinguardsText1")}
          </DialogContentText>
          <DialogContentText>
            {t("matchesScreen.modalShinguardsText2")}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setModalShinguards(false);
            }}
            color="primary"
            variant="contained"
          >
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalShinguardsCheck}
        onClose={() => setModalShinguardsCheck(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("matchesScreen.modalShinguardsTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("matchesScreen.modalShinguardsText1")}
          </DialogContentText>
          <DialogContentText>
            {t("matchesScreen.modalShinguardsText2")}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setModalShinguardsCheck(false);
            }}
            color="primary"
            variant="contained"
          >
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MatchesScreen;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 2,
    },
    imageIcon: {
      width: 30,
      height: 30,
    },
    sectionTitleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    sectionTitle: {
      fontSize: 24,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    gaugeInfo: {
      textAlign: "center",
    },
    gaugeContainer: {
      marginTop: 10,
    },
    timer: {
      textAlign: "center",
      fontSize: 40,
      margin: "10px auto",
    },
    noMatchContainer: {
      textAlign: "center",
    },
    titleContainer: {
      textAlign: "center",
      marginBottom: 20,
    },
    titleText: {
      color: theme.palette.primary.main,
    },
    playerWrapper: {
      width: "auto",
      height: "auto",
    },
    reactPlayer: {
      paddingTop: "56.25%",
      position: "relative",
      "& div": {
        position: "absolute",
        top: 0,
      },
    },
  })
);
