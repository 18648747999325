import React, {useEffect, useMemo, useState} from "react";
import ReactDom from "react-dom";
import "./Services/i18n";
import i18next from "i18next";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    useLocation,
} from "react-router-dom";
import {createMuiTheme, responsiveFontSizes, ThemeProvider,} from "@material-ui/core/styles";

import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    createHttpLink,
} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

import {AuthProvider} from "./Services/Auth";
import "core-js/stable";
import "regenerator-runtime/runtime";

import Header from "./Components/Header";

import ComunityScreen from "./Screens/ComunityScreen/ComunityScreen";
import MatchesScreen from "./Screens/MatchesScreen/MatchesScreen";
import MatchScreen from "./Screens/MatchesScreen/MatchScreen/MatchScreen";
import ShinguardsScreen from "./Screens/Shinguards/ShinguardsScreen";
import LoginScreen from "./Screens/LoginScreen/LoginScreen";
import CssBaseline from "@material-ui/core/CssBaseline";
import ProfileScreen from "./Screens/ProfileScreen/ProfileScreen";

import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import * as es from "dayjs/locale/es";
import * as en from "dayjs/locale/en";
import RegisterScreen from "./Screens/RegisterScreen/RegisterScreen";
import CalibrationScreen from "./Screens/CalibrationScreen/CalibrationScreen";
import StoreScreen from "./Screens/StoreScreen/StoreScreen";
import RecoverPassword from "./Screens/RecoverPassword/RecoverPassword";
import {AdminProvider} from "./Services/Admin";
import StoreOK from "./Screens/StoreScreen/StoreOk";
import StoreKO from "./Screens/StoreScreen/StoreKO";

import AuthRoute from "./Route/AuthRoute";

import {SocketProvider} from "./Services/Sockets";

dayjs.locale(es);

let theme = createMuiTheme({
    palette: {
        primary: {
            main: "#3AAA35",
            dark: "#80BA27",
        },
        background: {
            default: "#303030",
        },
        type: "dark",
    },
    //typography: {
    //    fontFamily: "Roboto",
    //},
    // overrides: {
    //   MuiCssBaseline: {
    //     "@global": {
    //       "@font-face": [raleway],
    //     },
    //   },
    // },
});
theme = responsiveFontSizes(theme);

const httpLink = createHttpLink({
    //uri: "https://app.humanox.com/graphql",
    uri: "https://live.humanox.com:3001/graphql",
    //uri: "https://devlive.humanox.com:3000/graphql",
    //uri: "http://192.168.1.52:3000/graphql",
});

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const userData = JSON.parse(localStorage.getItem("userData"));
    //console.log("[authLink]===>userData", userData);
    // return the headers to the context so httpLink can read them
    const token = localStorage.getItem("token");
    if (token) {
        return {
            headers: {
                ...headers,
                "x-access-token": token || "",
            },
        };
    } else if (userData) {
        return {
            headers: {
                ...headers,
                "x-access-token": (userData && userData.jwt) || "",
            },
        };
    } else {
        return {
            headers: {
                ...headers,
                "x-access-token": "",
            },
        };
    }
});

function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

export default function App(props) {
    const [userData, setUserData] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    const query = useQueryParams();
    const [userId, setUserId] = useState();
    const [adminToken, setAdminToken] = useState();
    const history = useHistory();
    const [language, setLanguage] = useState("");

    useEffect(() => {
        let lang = localStorage.getItem("lang");
        if (!lang) {
            let locale = window.navigator.language.slice(0, 2);
            if (locale == "es") {
                localStorage.setItem("lang", "es");
                lang = "es";
                setLanguage("es");
                i18next.changeLanguage(lang);
            } else {
                localStorage.setItem("lang", "en");
                lang = "en";
                setLanguage("en");
                i18next.changeLanguage(lang);
            }
            if (lang == "es") {
                dayjs.locale(es);
            } else {
                dayjs.locale(en);
            }
        } else {
            if (lang == "es") {
                dayjs.locale(es);
            } else {
                dayjs.locale(en);
            }
            setLanguage(lang);
            i18next.changeLanguage(lang);
        }
    }, []);
    useEffect(() => {
        const tokenValue = query.get("token");
        const idValue = query.get("id");
        if (tokenValue && idValue) {
            localStorage.setItem("token", tokenValue);
            localStorage.setItem("userId", idValue);
            setAdminToken(tokenValue);
            setUserId(idValue);
        }
    }, []);
    useEffect(() => {
        const userId = localStorage.getItem("userId");
        setUserId(userId);
    }, []);
    useEffect(() => {
        if (userId) {
            history.push({
                pathname: `/admin/matches/${userId}`,
                state: {id: userId},
            });
        }
    }, [userId]);

    useEffect(() => {
        const userData = localStorage.getItem("userData");
        //console.log("[App] === userData", JSON.parse(userData));
        setUserData(JSON.parse(userData));
        setLoading(false);
    }, []);

    const client = useMemo(
        () =>
            new ApolloClient({
                link: authLink.concat(httpLink),
                cache: new InMemoryCache(),
            }),
        []
    );
    return (
        <ApolloProvider client={client}>
            {/* <SocketContext.Provider value={socket}> */}
            <SocketProvider>
                {!userId && (
                    <AuthProvider
                        userData={userData}
                        loading={loading}
                        onUserDataChange={setUserData}
                    >
                        {!userId && language && userData && <Header userData={userData}/>}
                        <Switch>
                            <AuthRoute
                                path="/matches"
                                exact
                                component={MatchesScreen}
                            ></AuthRoute>
                            <AuthRoute
                                path="/matches/:id"
                                exact
                                component={MatchesScreen}
                            ></AuthRoute>
                            <AuthRoute
                                path="/community"
                                exact
                                component={ComunityScreen}
                            ></AuthRoute>
                            <AuthRoute path="/match/:id" component={MatchScreen}/>

                            <AuthRoute path="/profile" component={ProfileScreen}/>
                            <AuthRoute path="/calibrate" component={CalibrationScreen}/>
                            <AuthRoute path="/store" component={StoreScreen}/>

                            <AuthRoute path="/shinguards" component={ShinguardsScreen}/>
                            <Route path="/login">
                                <LoginScreen/>
                            </Route>
                            <Route path="/register">
                                <RegisterScreen/>
                            </Route>
                            <Route path="/recoverpassword">
                                <RecoverPassword/>
                            </Route>

                            <Route path="/response/ok">
                                <StoreOK/>
                            </Route>
                            <Route path="/response/ko">
                                <StoreKO/>
                            </Route>
                            <AuthRoute patch="/" exact component={MatchesScreen}/>
                        </Switch>
                    </AuthProvider>
                )}

                <AdminProvider userId={userId} onAdminChange={setUserId}>
                    {userId && <Header userData={null}/>}
                    <Route path="/admin/profile/:id" component={ProfileScreen}/>
                    <Route path="/admin/matches/:id" component={MatchesScreen}></Route>
                    <Route path="/admin/match/:id/:id" component={MatchScreen}/>
                    <Route path="/admin/store/:id" component={StoreScreen}/>
                    <Route path="/admin/shinguards/:id" component={ShinguardsScreen}/>
                    <Route path="/admin/community/:id" component={ComunityScreen}/>
                </AdminProvider>
            </SocketProvider>
            {/* </SocketContext.Provider> */}
        </ApolloProvider>
    );
}

ReactDom.render(
    <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
            <CssBaseline/>
            <Router>
                <App/>
            </Router>
        </MuiPickersUtilsProvider>
    </ThemeProvider>,

    document.getElementById("root") as HTMLElement
);
