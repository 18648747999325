import React, { FC, useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_USER_SHINGUARDS,
  USER_QUERY,
  REMOVE_USER_SHINGUARDS,
} from "../../lib/queries/user.queries";
import { LAST_SHINGUARDS_VERSION } from "../../lib/queries/shinguards.queries";
import useAuth from "../../Services/Auth";

import {
  Button,
  Chip,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Theme,
} from "@material-ui/core";
import Shinguards, { ShinguardState } from "../../Components/Shinguards";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faRetweet,
  faSync,
  faMinusCircle,
  faLevelUpAlt,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import DialogModal from "../../Components/DialogModal";

import Paper from "@material-ui/core/Paper";
import Spinner from "../../Components/Spinner";
import useSocket from "../../Services/Sockets";

const ShinguardsScreen: FC = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const classes = useStyles();
  //const socket = useContext(SocketContext);
  const socket = useSocket();
  const fcPercentage = socket.fcPercentage;
  const lfcPercentage = socket.lfcPercentage;
  const location = useLocation();
  let id = auth && auth.userData && auth.userData.id;
  let uuid = auth && auth.userData && auth.userData.uuid;
  if (location && location.state && location.state.id) {
    id = parseFloat(location.state.id);
    uuid = localStorage.getItem("uuid");
  }
  const { data: userData, refetch: refetchUser } = useQuery(USER_QUERY, {
    variables: { id, uuid },
  });
  const player = userData && userData.getUser;
  let shinguards =
    player &&
    player.current_shinguards &&
    parseFloat(player.current_shinguards);

  const [updateUserShinguards] = useMutation(UPDATE_USER_SHINGUARDS);
  const [removeUserShinguards] = useMutation(REMOVE_USER_SHINGUARDS);
  const [showAddShinguardsModal, setShowAddShinguardsModal] = useState(false);
  const [showRemoveShinguards, setShowRemoveShinguards] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [rssi, setRssi] = useState("N/A");
  const [nos, setNos] = useState("N/A");
  const [bl, setBl] = useState("N/A");
  const [br, setBr] = useState("N/A");
  const [temperature, setTemperature] = useState("N/A");
  const [fwversion, setFwversion] = useState("N/A");
  const [sq, setsq] = useState("N/A");
  const [mic, setMic] = useState();
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const [rightPercentaje, setRightPercentaje] = useState(-1);
  const [leftPercentaje, setLeftPercentaje] = useState(-1);
  const [upddatedRightSuccess, setUpdatedRightSuccess] = useState(false);
  const [updatedRightFailed, setUpdatedRightFailed] = useState(false);
  const [upddatedLeftSuccess, setUpdatedLeftSuccess] = useState(false);
  const [updatedLeftFailed, setUpdatedLeftFailed] = useState(false);
  const [changeHzSuccess, setChangeHzSuccess] = useState(false);
  const [hz, setHz] = useState(0);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [powerOff, setPowerOff] = useState(false);
  const [changingTo1, setChanginTo1] = useState(false);
  const [changingTo2, setChanginTo2] = useState(false);
  const [changingTo5, setChanginTo5] = useState(false);
  const [changingTo10, setChanginTo10] = useState(false);
  const [assigning, setAssigning] = useState(false);

  const [noHX, setNoHX] = useState(false);

  const { data: lastVersion } = useQuery(LAST_SHINGUARDS_VERSION);
  const version = lastVersion && lastVersion.lastshinguardsversion;

  const getStatus = async () => {
    try {
      setConnecting(true);
      let result = await socket.status(shinguards.toString());
      if (result != null) {
        let cadena = result.split(",");
        setBr(cadena[0]);
        setBl(cadena[1]);
        setRssi(cadena[4]);
        setsq(cadena[5]);
        setNos(cadena[6]);
        setTemperature(cadena[9]);
        setFwversion(cadena[8]);
        setConnecting(false);
        setConnected(true);
      } else if (result == null) {
        setConnecting(false);
        setConnected(false);
        setBr("N/A");
        setBl("N/A");
        setRssi("N/A");
        setsq("N/A");
        setNos("N/A");
        setTemperature("N/A");
        setFwversion("N/A");
      }
    } catch (e) {
      console.log(e);
      setConnecting(false);
      setConnected(false);
    }
  };

  const setUserShinguards = async () => {
    if (mic) {
      try {
        setAssigning(true);
        setError(false);
        setNoHX(false);
        const { data: userUpdated } = await updateUserShinguards({
          variables: { userId: id, shinguards: mic },
        });

        if (userUpdated.updateshinguardsuser == "ok") {
          setShowAddShinguardsModal(false);
          setTimeout(() => setAssigning(false), 2000);
          refetchUser();
        } else if (userUpdated.updateshinguardsuser == "konc") {
          setNoHX(true);
          setAssigning(false);
        }
      } catch (e) {
        setError(true);
        setAssigning(false);
      }
    } else {
      setError(true);
      setAssigning(false);
    }
  };
  const removeShinguards = async () => {
    try {
      const { data: userUpdated } = await removeUserShinguards({
        variables: { userId: id },
      });
      if (userUpdated.removeusershinguards == "ok") {
        setShowRemoveShinguards(false);
        refetchUser();
      }
    } catch (e) {}
  };

  const resetShinguards = async () => {
    try {
      socket.reset(shinguards.toString());
      setResetSuccess(true);
      setTimeout(() => {
        setResetSuccess(false);
        getStatus();
      }, 20000);
    } catch (e) {}
  };
  const powerOffShinguards = async () => {
    try {
      socket.powerOff(shinguards.toString());
      setPowerOff(true);
      setTimeout(() => {
        setPowerOff(false);
        setBr("N/A");
        setBl("N/A");
        setRssi("N/A");
        setsq("N/A");
        setNos("N/A");
        setTemperature("N/A");
        setFwversion("N/A");
        setConnected(false);
      }, 2500);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const updateRightShinguards = async () => {
    try {
      socket.fotar(shinguards.toString());
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    setRightPercentaje(fcPercentage);
  }, [fcPercentage]);

  useEffect(() => {
    if (rightPercentaje == 100) {
      setRightPercentaje(-1);
    }
  }, [rightPercentaje]);

  const updateLeftShinguards = async () => {
    try {
      socket.fotal(shinguards.toString());
    } catch (e) {
      console.log("Error", e);
    }
  };
  useEffect(() => {
    setLeftPercentaje(lfcPercentage);
  }, [lfcPercentage]);

  useEffect(() => {
    if (leftPercentaje >= 100) {
      setLeftPercentaje(-1);
    }
  }, [leftPercentaje]);

  const changeTo1Hz = async () => {
    try {
      socket.gps1(shinguards.toString());
      setChanginTo1(true);
      setTimeout(() => {
        setChanginTo1(false);
        setHz(1);
        setChangeHzSuccess(true);
      }, 2000);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const changeTo2Hz = async () => {
    try {
      socket.gps2(shinguards.toString());
      setChanginTo2(true);
      setTimeout(() => {
        setChanginTo2(false);
        setHz(2);
        setChangeHzSuccess(true);
      }, 2000);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const changeTo5Hz = async () => {
    try {
      socket.gps5(shinguards.toString());
      setChanginTo5(true);
      setTimeout(() => {
        setChanginTo5(false);
        setHz(5);
        setChangeHzSuccess(true);
      }, 2000);
    } catch (e) {
      console.log("Error", e);
    }
  };
  const changeTo10Hz = async () => {
    try {
      socket.gps10(shinguards.toString());
      setChanginTo10(true);
      setTimeout(() => {
        setChanginTo10(false);
        setHz(10);
        setChangeHzSuccess(true);
      }, 2000);
    } catch (e) {
      console.log("Error", e);
    }
  };

  useEffect(() => {
    if (shinguards) {
      getStatus();
    }
  }, [shinguards]);

  const state =
    shinguards && connected ? ShinguardState.Sync : ShinguardState.notSync;

  let statusText;
  if (connecting) {
    statusText = t("shinguardsScreen.connecting");
  } else if (connected) {
    statusText = t("shinguardsScreen.syncShinguards");
  } else {
    statusText = t("shinguardsScreen.off");
  }

  const firmwareVersion = () => {
    if (fwversion == "N/A") {
      return <span>{fwversion}</span>;
    } else if (fwversion != "N/A") {
      return (
        <span
          className={classes.infoText}
          style={{
            color: version && fwversion >= version ? "#3AAA35" : "#ab2a3e",
          }}
        >
          {fwversion}
        </span>
      );
    }
  };

  const gsmConection = () => {
    if (rssi == "N/A") {
      return <span>{rssi}</span>;
    } else if (rssi != "N/A" && rssi < -95) {
      return (
        <span
          style={{
            color: "#ab2a3e",
          }}
        >
          {rssi} dB
        </span>
      );
    } else if (rssi != "N/A" && rssi >= -95 && rssi < -80) {
      return (
        <span
          style={{
            color: "#FFFF00",
          }}
        >
          {rssi} dB
        </span>
      );
    } else if (rssi != "N/A" && rssi >= -80) {
      return (
        <span
          style={{
            color: "#3AAA35",
          }}
        >
          {rssi} dB
        </span>
      );
    }
  };
  const gpsSqConection = () => {
    let nosText = (
      <span
        style={{
          width: "60%",
        }}
      >
        {nos}
      </span>
    );
    let sqText = (
      <span
        style={{
          width: "40%",
        }}
      >
        {rssi}
      </span>
    );
    if (nos == "N/A") {
      nosText = (
        <span
          style={{
            width: "60%",
          }}
        >
          {nos}
        </span>
      );
    } else if (nos != "N/A" && nos < 3) {
      nosText = (
        <span
          style={{
            color: "#ab2a3e",
            width: "60%",
          }}
        >
          {nos} {t("shinguardsScreen.satellites")}
        </span>
      );
    } else if (nos != "N/A" && nos >= 3 && nos < 6) {
      nosText = (
        <span
          style={{
            color: "#FF6600",
            width: "60%",
          }}
        >
          {nos} {t("shinguardsScreen.satellites")}
        </span>
      );
    } else if (nos != "N/A" && nos >= 6 && nos < 10) {
      nosText = (
        <span
          style={{
            color: "#FFFF00",
            width: "60%",
          }}
        >
          {nos} {t("shinguardsScreen.satellites")}
        </span>
      );
    } else if (nos != "N/A" && nos >= 10) {
      nosText = (
        <span
          style={{
            color: "#3AAA35",
            width: "60%",
          }}
        >
          {nos} {t("shinguardsScreen.satellites")}
        </span>
      );
    }

    if (sq == "N/A") {
      sqText = (
        <span
          style={{
            width: "40%",
          }}
        >
          {sq}
        </span>
      );
    } else if (sq != "N/A" && nos != 0 && sq > 60 && sq <= 100) {
      sqText = (
        <span
          style={{
            color: "#ab2a3e",
            width: "40%",
          }}
        >
          {sq} SQ
        </span>
      );
    } else if (sq != "N/A" && nos != 0 && sq > 35 && sq <= 60) {
      sqText = (
        <span
          style={{
            color: "#FF6600",
            width: "40%",
          }}
        >
          {sq} SQ
        </span>
      );
    } else if (sq != "N/A" && nos != 0 && sq > 15 && sq <= 35) {
      sqText = (
        <span
          style={{
            color: "#FFFF00",
            width: "40%",
          }}
        >
          {sq} SQ
        </span>
      );
    } else if (sq != "N/A" && nos != 0 && sq <= 15) {
      sqText = (
        <span
          style={{
            color: "#3AAA35",
            width: "40%",
          }}
        >
          {sq} SQ
        </span>
      );
    } else if (sq != "N/A" && nos == 0) {
      sqText = (
        <span
          style={{
            color: "#ab2a3e",
            width: "40%",
          }}
        >
          {sq} SQ
        </span>
      );
    }
    return (
      <div style={{ display: "flex" }}>
        {nosText} {sqText}
      </div>
    );
  };

  const rows = [
    {
      icon: (
        <DeviceHubIcon
          fontSize="small"
          style={{ verticalAlign: "middle", marginRight: 5 }}
        />
      ),
      name: t("shinguardsScreen.id"),
      value: shinguards,
    },
    {
      icon: (
        <DeveloperBoardIcon
          fontSize="small"
          style={{ verticalAlign: "middle", marginRight: 5 }}
        />
      ),
      name: t("shinguardsScreen.firmware"),
      value: firmwareVersion(),
    },
    {
      icon: (
        <LocationOnIcon
          fontSize="small"
          style={{ verticalAlign: "middle", marginRight: 5 }}
        />
      ),
      name: t("shinguardsScreen.gsmSignal"),
      value: gsmConection(),
    },
    {
      icon: (
        <GpsFixedIcon
          fontSize="small"
          style={{ verticalAlign: "middle", marginRight: 5 }}
        />
      ),
      name: t("shinguardsScreen.gpsSignal"),
      value: gpsSqConection(),
    },
  ];
  return (
    <Container style={{ paddingBottom: 5 }}>
      <Grid style={{ position: "relative" }}>
        <Grid item xs={12}>
          <Shinguards
            state={state}
            progressLeft={leftPercentaje}
            progressRight={rightPercentaje}
            batteryRight={br}
            batteryLeft={bl}
          ></Shinguards>
        </Grid>
        {shinguards != "0" && shinguards && (
          <Grid
            container
            spacing={3}
            justify="center"
            style={{ textAlign: "center" }}
          >
            <Grid item xs={12}>
              {!!shinguards && <p>{statusText}</p>}
            </Grid>
            {shinguards != "0" && shinguards && (
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.icon}
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {location && location.state && location.state.id && connected && (
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={6} sm={3} style={{ padding: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTo1Hz();
                      }}
                    >
                      <div className={classes.infoTextContainer}>
                        <FontAwesomeIcon icon={faWaveSquare} size="lg" />
                        <span className={classes.infoText}>1Hz</span>
                      </div>
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ padding: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTo2Hz();
                      }}
                    >
                      <div className={classes.infoTextContainer}>
                        <FontAwesomeIcon icon={faWaveSquare} size="lg" />
                        <span className={classes.infoText}>2Hz</span>
                      </div>
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ padding: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTo5Hz();
                      }}
                    >
                      <div className={classes.infoTextContainer}>
                        <FontAwesomeIcon icon={faWaveSquare} size="lg" />
                        <span className={classes.infoText}>5Hz</span>
                      </div>
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3} style={{ padding: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTo10Hz();
                      }}
                    >
                      <div className={classes.infoTextContainer}>
                        <FontAwesomeIcon icon={faWaveSquare} size="lg" />
                        <span className={classes.infoText}>10Hz</span>
                      </div>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {!shinguards && (
          <div style={{ textAlign: "center" }}>
            <Button
              color="primary"
              disabled={shinguards ? true : false}
              variant="contained"
              onClick={() => {
                setShowAddShinguardsModal(true);
              }}
            >
              {t("shinguardsScreen.addShinguards")}
            </Button>
            <Modal
              open={showAddShinguardsModal}
              onClose={() => setShowAddShinguardsModal(false)}
            >
              <div className={classes.addShinguardsModal}>
                <h2>{t("shinguardsScreen.addShinguards")}</h2>
                <p>{t("shinguardsScreen.enterImei")}</p>
                <TextField
                  label={t("shinguardsScreen.numImei")}
                  value={mic}
                  helperText={
                    error ? t("shinguardsScreen.addShinguardError") : ""
                  }
                  error={error}
                  type="number"
                  style={{ width: "100%", margin: "10px 0px" }}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    if (isNaN(event.target.value as Number)) {
                      setError(true);
                    } else {
                      setMic(parseFloat(event.target.value as Number));
                    }
                  }}
                />
                {noHX && (
                  <div style={{ color: "#ab2a3e" }}>
                    {t("shinguardsScreen.noHX")}
                  </div>
                )}
                <div className={classes.buttonModalContainer}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setError(false);
                      setNoHX(false);
                      setShowAddShinguardsModal(false);
                    }}
                  >
                    {t("common.cancel")}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setUserShinguards()}
                  >
                    {t("common.add")}
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        )}
        <DialogModal
          open={changeHzSuccess}
          onOk={() => {
            setChangeHzSuccess(false);
          }}
          onCancel={() => {
            setChangeHzSuccess(false);
          }}
          buttonAgree={t("common.accept")}
          title={t("shinguardsScreen.changehz.changeto1Title")}
          text={t("shinguardsScreen.changehz.changeHzText", { hz })}
        ></DialogModal>
        <Dialog
          open={showRemoveShinguards}
          onClose={() => setShowRemoveShinguards(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("shinguardsScreen.removeShinguardModalTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("shinguardsScreen.removeShinguardModalText")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowRemoveShinguards(false)}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => removeShinguards()}
            >
              {t("common.accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={upddatedRightSuccess}
          onClose={() => setUpdatedRightSuccess(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("shinguardsScreen.updateModal.updatedTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("shinguardsScreen.updateModal.updatedRightText")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedRightSuccess(false)}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedRightSuccess(false)}
            >
              {t("common.accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={upddatedLeftSuccess}
          onClose={() => setUpdatedLeftSuccess(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("shinguardsScreen.updateModal.updatedTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("shinguardsScreen.updateModal.updatedLeftText")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedLeftSuccess(false)}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedLeftSuccess(false)}
            >
              {t("common.accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={updatedRightFailed}
          onClose={() => setUpdatedRightFailed(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("shinguardsScreen.updateModal.errorUpdatingTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("shinguardsScreen.updateModal.errorUpdatingRightText")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedRightFailed(false)}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedRightFailed(false)}
            >
              {t("common.accept")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={updatedLeftFailed}
          onClose={() => setUpdatedLeftFailed(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("shinguardsScreen.updateModal.errorUpdatingTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("shinguardsScreen.updateModal.errorUpdatingLeftText")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedLeftFailed(false)}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUpdatedLeftFailed(false)}
            >
              {t("common.accept")}
            </Button>
          </DialogActions>
        </Dialog>
        {connected && fwversion < version && (
          <FloatingMenu
            slideSpeed={500}
            spacing={8}
            isOpen={open}
            direction="up"
            className={classes.fab}
          >
            <MainButton
              iconResting={
                <AddIcon style={{ fontSize: 20 }} fontSize="large" />
              }
              iconActive={
                <CloseIcon style={{ fontSize: 20 }} fontSize="large" />
              }
              background="#3AAA35"
              onClick={() => {
                setOpen(!open);
              }}
              size={56}
            />

            <ChildButton
              icon={<FontAwesomeIcon icon={faPowerOff} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.turnOff")}
              onClick={() => {
                setOpen(!open);
                powerOffShinguards();
              }}
            />

            <ChildButton
              icon={<FontAwesomeIcon icon={faSync} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.sync")}
              onClick={() => {
                setOpen(!open);
                getStatus();
              }}
            />
            <ChildButton
              icon={<FontAwesomeIcon icon={faMinusCircle} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.remove")}
              onClick={() => {
                setOpen(!open);
                setShowRemoveShinguards(true);
              }}
            />
            <ChildButton
              icon={<FontAwesomeIcon icon={faRetweet} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.restart")}
              onClick={() => {
                setOpen(!open);
                resetShinguards();
              }}
            />
            <ChildButton
              icon={<FontAwesomeIcon icon={faLevelUpAlt} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.updateRight")}
              left={-112}
              onClick={() => {
                setOpen(!open);
                updateRightShinguards();
              }}
            />
            <ChildButton
              icon={
                <FontAwesomeIcon
                  icon={faLevelUpAlt}
                  size="lg"
                  transform="flip-h"
                />
              }
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.updateLeft")}
              left={-117}
              onClick={() => {
                setOpen(!open);
                updateLeftShinguards();
              }}
            />
          </FloatingMenu>
        )}
        {connected && fwversion >= version && (
          <FloatingMenu
            slideSpeed={500}
            spacing={8}
            isOpen={open}
            direction="up"
            className={classes.fab}
          >
            <MainButton
              iconResting={
                <AddIcon style={{ fontSize: 20 }} fontSize="large" />
              }
              iconActive={
                <CloseIcon style={{ fontSize: 20 }} fontSize="large" />
              }
              background="#3AAA35"
              onClick={() => {
                setOpen(!open);
              }}
              size={56}
            />

            <ChildButton
              icon={<FontAwesomeIcon icon={faPowerOff} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.turnOff")}
              onClick={() => {
                setOpen(!open);
                powerOffShinguards();
              }}
            />

            <ChildButton
              icon={<FontAwesomeIcon icon={faSync} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.sync")}
              onClick={() => {
                setOpen(!open);
                getStatus();
              }}
            />
            <ChildButton
              icon={<FontAwesomeIcon icon={faMinusCircle} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.remove")}
              onClick={() => {
                setOpen(!open);
                setShowRemoveShinguards(true);
              }}
            />
            <ChildButton
              icon={<FontAwesomeIcon icon={faRetweet} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.restart")}
              onClick={() => {
                setOpen(!open);
                resetShinguards();
              }}
            />
          </FloatingMenu>
        )}
        {!connected && shinguards != "0" && shinguards && (
          <FloatingMenu
            slideSpeed={500}
            spacing={8}
            isOpen={open}
            direction="up"
            className={classes.fab}
          >
            <MainButton
              iconResting={
                <AddIcon style={{ fontSize: 20 }} fontSize="large" />
              }
              iconActive={
                <CloseIcon style={{ fontSize: 20 }} fontSize="large" />
              }
              background="#3AAA35"
              onClick={() => {
                setOpen(!open);
              }}
              size={56}
            />

            <ChildButton
              icon={<FontAwesomeIcon icon={faSync} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.sync")}
              onClick={() => {
                setOpen(!open);
                getStatus();
              }}
            />
            <ChildButton
              icon={<FontAwesomeIcon icon={faMinusCircle} size="lg" />}
              background="#3AAA35"
              size={40}
              label={t("floatingButtons.remove")}
              onClick={() => {
                setOpen(!open);
                setShowRemoveShinguards(true);
              }}
            />
          </FloatingMenu>
        )}
      </Grid>
      <Modal open={resetSuccess}>
        <div className={classes.tryingRecord}>
          <Spinner
            open={resetSuccess}
            hideSpinner={false}
            message={t("shinguardsScreen.resetSuccess")}
          />
        </div>
      </Modal>
      <Modal open={powerOff}>
        <div className={classes.tryingRecord}>
          <Spinner
            open={powerOff}
            hideSpinner={false}
            message={t("shinguardsScreen.powerOff")}
          />
        </div>
      </Modal>
      <Modal open={changingTo1}>
        <div className={classes.tryingRecord}>
          <Spinner
            open={changingTo1}
            hideSpinner={false}
            message={t("shinguardsScreen.changingTo1")}
          />
        </div>
      </Modal>
      <Modal open={changingTo2}>
        <div className={classes.tryingRecord}>
          <Spinner
            open={changingTo2}
            hideSpinner={false}
            message={t("shinguardsScreen.changingTo2")}
          />
        </div>
      </Modal>
      <Modal open={changingTo5}>
        <div className={classes.tryingRecord}>
          <Spinner
            open={changingTo5}
            hideSpinner={false}
            message={t("shinguardsScreen.changingTo5")}
          />
        </div>
      </Modal>
      <Modal open={changingTo10}>
        <div className={classes.tryingRecord}>
          <Spinner
            open={changingTo10}
            hideSpinner={false}
            message={t("shinguardsScreen.changingTo10")}
          />
        </div>
      </Modal>
      <Modal open={assigning}>
        <div className={classes.tryingRecord}>
          <Spinner
            open={assigning}
            hideSpinner={false}
            message={t("shinguardsScreen.assingingShinguards")}
          />
        </div>
      </Modal>
    </Container>
  );
};

export default ShinguardsScreen;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addShinguardsModal: {
      position: "absolute",
      height: 350,
      width: 330,
      top: "50%",
      left: "50%",
      background: "#1E1E1E",
      transform: "translateX(-50%) translateY(-50%);",
      bottom: 0,
      color: "white",
      textAlign: "center",
      borderRadius: 25,
      boxShadow:
        "0px 6px 6px -3px rgba(0,0,0,0.1), 0px 6px 10px 1px rgba(0,0,0,0.1), 0px 4px 12px 3px rgba(0,0,0,0.1)",
      padding: 25,
      "&:focus": {
        outline: "none",
      },
    },
    buttonModalContainer: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: 5,
    },
    inputMic: {
      width: "100%",
    },
    infoTextContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
    infoText: {
      marginLeft: 5,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 2,
    },
    imageIcon: {
      width: 30,
      height: 30,
    },
    titleContainer: {
      textAlign: "center",
      marginBottom: 20,
    },
    titleText: {
      color: theme.palette.primary.main,
    },
    tryingRecord: {
      position: "absolute",
      height: 300,
      width: 300,
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%);",
      bottom: 0,
      color: "white",
      textAlign: "center",
      borderRadius: 25,
      padding: 25,
      "&:focus": {
        outline: "none",
      },
    },
  })
);
