import React, {FC, useEffect, useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import MuiPhoneNumber from "mui-phone-input-ssr";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {
  USER_SIGNUP,
  CREATE_USER_CUSTOMERCARE,
} from "../../lib/queries/user.queries";
import dayjs from "dayjs";
import DialogModal from "../../Components/DialogModal";
import useAuth from "../../Services/Auth";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faBirthdayCake,
  faChevronLeft,
  faChild,
  faEnvelope,
  faLock,
  faPhone,
  faRulerHorizontal,
  faRulerVertical,
  faSignature,
  faUser,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";

import gamerIcon from "../../lib/assets/ico_gamer_black.png";
import enviromentIcon from "../../lib/assets/ico_environment_black.png";

export enum PLayerType {
  None,
  Gamer,
  ProGamer,
  Professional,
  Trainer,
  PhysicalTrainer,
  Enviroment,
  Follower,
}

const RegisterScreen: FC = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const {t} = useTranslation();

  const [birthdate, setBirthdate] = useState();
  const [email, setEmail] = useState();
  const [pin, setPin] = useState();
  const [pin2, setPin2] = useState();
  const [username, setUsername] = useState();
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [height, setHeight] = useState();
  const [heightFeet, setHeightFeet] = useState();
  const [heightInches, setHeightInches] = useState();
  const [heightCm, setHeightCm] = useState();
  const [weight, setWeight] = useState();
  const [measurement_format, setMeasurementFormat] = useState();
  const [gender, setGender] = useState("M");
  const [profile, setprofile] = useState();
  const [phone, setPhone] = useState();
  const [cardSelected, setCardSelected] = useState();
  const [error, setError] = useState();
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorUserName, setErrorUserName] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorBirthday, setErrorBirthday] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [signSuccess, setSignSuccess] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [check5, setCheck5] = useState(false);
  const [menor, setMenor] = useState(false);
  const [fullNameTutor, setFullNameTutor] = useState();
  const [emailTutor, setEmailTutor] = useState();
  const [birthdateTutor, setBirthdateTutor] = useState();
  const [phoneTutor, setPhoneTutor] = useState();
  let signUpData = {};

  const [signup] = useMutation(USER_SIGNUP);
  const [createUserCustomerCare] = useMutation(CREATE_USER_CUSTOMERCARE);

  const onFinishSignup = async () => {
    setErrorPhone(false);
    setErrorUserName(false);
    setError(false);
    setErrorPassword(false);
    setErrorBirthday(false);
    if (pin != pin2) {
      setErrorPassword(t("common.errorsForm.passworddontMatch"));
      window.scroll({
        top: 10,
        left: 100,
        behavior: "smooth",
      });
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!&])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!&]{1,16}$/g.test(
        pin
      )
    ) {
      setErrorPassword(t("common.errorsForm.passwordContain"));
      window.scroll({
        top: 10,
        left: 100,
        behavior: "smooth",
      });
    } else if (birthdate == undefined) {
      setErrorBirthday(true);
      window.scroll({
        top: 10,
        left: 100,
        behavior: "smooth",
      });
    } else if (/\s/g.test(username)) {
      setErrorUserName(true);
      window.scroll({
        top: 10,
        left: 100,
        behavior: "smooth",
      });
    } else {
      signUpData = {
        email,
        pin,
        name: username,
        birthdate,
        fullname: name && lastname ? name + " " + lastname : null,
        height: height ? parseFloat(height) : 0,
        weight: weight ? parseFloat(weight) : 0,
        measurement_format: measurement_format,
        gender,
        profile,
        phone: phone.replace(/ /g, ""),
      };

      window.sendConsentimientos();

      try {
        setSigningUp(true);
        let resUser = await signup({variables: {signUpData}});

        let userCustomer = {
          customer_id: resUser.data.signUp.id,
          username,
          first_name: name ? name : "first_name",
          last_name: lastname ? lastname : "last_name",
          height: height ? parseFloat(height) : 0,
          weight: weight ? parseFloat(weight) : 0,
          measurement_format: measurement_format,
          phone: phone.replace(/ /g, ""),
          address1: "Empty",
          address2: "",
          postalcode: "11000",
          city: "Empty",
          country_code: "ES",
          email,
          birthdate,
          invoice: 0,
        };
        const {data: resUserCustomer} = await createUserCustomerCare({
          variables: {userCustomer},
        });
        if (resUserCustomer && resUserCustomer.createusercustomercare == "ok") {
          setSigningUp(false);
          setSignSuccess(true);
          window.scroll({
            top: 10,
            left: 100,
            behavior: "smooth",
          });
        } else {
          if (typeof global.window !== "undefined") {
            window.scroll({
              top: 10,
              left: 100,
              behavior: "smooth",
            });
          }
        }
      } catch (e) {
        console.log("Valor del error", e);
        setError(true);
        setSigningUp(false);
        if (typeof global.window !== "undefined") {
          window.scroll({
            top: 10,
            left: 100,
            behavior: "smooth",
          });
        }
      }
    }
  };
  const onSignupEnd = async () => {
    try {
      await auth.logIn(email, pin);
      history.push("/matches");
    } catch (e) {
      console.log("Error", e);
      history.push("/login");
    }

    setSignSuccess(false);
  };

  useEffect(() => {
    setprofile(cardSelected);
    if (cardSelected != PLayerType.None) {
      window.scroll({
        top: 10,
        left: 100,
        behavior: "smooth",
      });
    }
  }, [cardSelected]);
  console.log("Valor de cardSelected", cardSelected);
  console.log("Valor de profile", profile);
  useEffect(() => {
    if (check1 && check2 && check3 && check4) {
      setCheck5(true);
    }
  }, [check1, check2, check3, check4]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender((event.target as HTMLInputElement).value);
  };
  const handleDateChange = (date: Date | null) => {
    console.log(date.value);
    setBirthdate(date);
  };
  return (
    <Container maxWidth="sm" className={classes.container}>
      <div
        className={classes.backButtonContainer}
        onClick={() => history.goBack()}
      >
        <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#3AAA35"/>
      </div>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{
            display:
              cardSelected == PLayerType.Gamer ||
              cardSelected == null ||
              cardSelected == PLayerType.None
                ? "block"
                : "none",
            position: "relative",
          }}
        >
          <Card
            className={classes.root}
            style={{
              backgroundColor: cardSelected == PLayerType.Gamer ? "white" : "",
            }}
            onClick={() => setCardSelected(PLayerType.Gamer)}
          >
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <p
                  style={{
                    color: cardSelected == PLayerType.Gamer ? "black" : "",
                  }}
                >
                  {t("common.playerType.gamertext")}
                </p>
                <h2 className={classes.playerTypeTitle}>
                  {t("common.playerType.gamer")}
                </h2>
              </CardContent>
            </div>
            <div className={classes.cover}>
              <CardMedia
                image={gamerIcon}
                className={classes.cardImage}
              ></CardMedia>
            </div>
          </Card>
          <FontAwesomeIcon
            onClick={() => setCardSelected(null)}
            icon={faAngleLeft}
            size="3x"
            className={classes.arrowIcon}
            style={{
              display: cardSelected != null ? "block" : "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              cardSelected == PLayerType.Enviroment ||
              cardSelected == null ||
              cardSelected == PLayerType.None
                ? "block"
                : "none",
            position: "relative",
          }}
        >
          <Card
            className={classes.root}
            style={{
              backgroundColor:
                cardSelected == PLayerType.Enviroment ? "white" : "",
            }}
            onClick={() => setCardSelected(PLayerType.Enviroment)}
          >
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <p
                  style={{
                    color: cardSelected == PLayerType.Enviroment ? "black" : "",
                  }}
                >
                  {t("common.playerType.enviromenttext")}
                </p>
                <h2 className={classes.playerTypeTitle}>
                  {t("common.playerType.enviroment")}
                </h2>
              </CardContent>
            </div>
            <div className={classes.cover}>
              <CardMedia
                image={enviromentIcon}
                className={classes.cardImage}
              ></CardMedia>
            </div>
          </Card>
          <FontAwesomeIcon
            onClick={() => setCardSelected(null)}
            icon={faAngleLeft}
            size="3x"
            className={classes.arrowIcon}
            style={{
              display: cardSelected != null ? "block" : "none",
            }}
          />
        </Grid>

        <form>
          <fieldset
            className={classes.fieldset}
            style={{
              display: cardSelected != null ? "block" : "none",
            }}
          >
            <legend className={classes.legend}>
              {t("common.form.personalData")}
            </legend>

            <Grid
              item
              xs={12}
              style={{
                display: cardSelected != null ? "block" : "none",
                padding: "0 12px",
              }}
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
                className={classes.inputContainer}
              >
                <Grid item className={classes.inputIconWidth}>
                  <FontAwesomeIcon icon={faBirthdayCake} size="2x"/>
                </Grid>
                <Grid item item className={classes.inputWidth}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="ss_birthday"
                    name="ss_birthday"
                    label={t("common.form.birthday")}
                    format="DD/MM/YYYY"
                    disableFuture
                    helperText={
                      errorBirthday ? t("common.errorsForm.errorBirthdate") : ""
                    }
                    error={errorBirthday}
                    minDate={new Date("1900-1-1")}
                    style={{width: "100%", margin: "10px 0px"}}
                    value={birthdate}
                    onChange={(date: Date, value?: string | null) => {
                      let checkYear = new Date() - date;

                      if (checkYear / 31556952000 < 14) {
                        setMenor(true);
                      } else {
                        setMenor(false);
                      }
                      setBirthdate(dayjs(date).format("YYYY-M-D").toString());
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: cardSelected != null ? "block" : "none",
                padding: "0 12px",
              }}
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
                className={classes.inputContainer}
              >
                {menor && (
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {t("common.form.childText")}
                    </Typography>
                  </Grid>
                )}

                <Grid item className={classes.inputIconWidth}>
                  {!menor && <FontAwesomeIcon icon={faEnvelope} size="2x"/>}
                  {menor && <FontAwesomeIcon icon={faChild} size="2x"/>}
                </Grid>
                <Grid item item className={classes.inputWidth}>
                  <TextField
                    id="ss_email"
                    name="ss_email"
                    label={
                      !menor
                        ? t("common.form.email")
                        : t("common.form.username")
                    }
                    required
                    style={{width: "100%", margin: "10px 0px"}}
                    value={email}
                    error={error}
                    helperText={
                      error ? t("common.errorsForm.emailDuplicated") : ""
                    }
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setEmail(event.target.value as string);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: cardSelected != null ? "block" : "none",
                padding: "0 12px",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6} className={classes.inputsContainer1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item style={{width: "15%"}}>
                      <FontAwesomeIcon icon={faLock} size="2x"/>
                    </Grid>
                    <Grid item item style={{width: "85%"}}>
                      <TextField
                        label={t("common.form.password")}
                        type="password"
                        helperText={errorPassword}
                        required
                        style={{width: "100%", margin: "10px 0px"}}
                        value={pin}
                        error={errorPassword}
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setPin(event.target.value as string);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputsContainer2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item style={{width: "15%"}}>
                      <FontAwesomeIcon icon={faLock} size="2x"/>
                    </Grid>
                    <Grid item item style={{width: "85%"}}>
                      <TextField
                        label={t("common.form.repassword")}
                        type="password"
                        required
                        helperText={errorPassword ? "" : ""}
                        error={errorPassword}
                        style={{width: "100%", margin: "10px 0px"}}
                        value={pin2}
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setPin2(event.target.value as string);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: cardSelected != null ? "block" : "none",
                padding: "0 12px",
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item className={classes.inputIconWidth}>
                  <FontAwesomeIcon icon={faUser} size="2x"/>
                </Grid>
                <Grid item item className={classes.inputWidth}>
                  <TextField
                    label={t("common.form.nick")}
                    id="ss_nickname"
                    name="ss_nickname"
                    style={{width: "100%", margin: "10px 0px"}}
                    value={username}
                    required
                    helperText={
                      errorUserName ? t("common.errorsForm.errorUserName") : ""
                    }
                    error={errorUserName}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setUsername(event.target.value as string);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: cardSelected != null ? "block" : "none",
                padding: "0 12px",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={6} className={classes.inputsContainer1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item style={{width: "15%"}}>
                      <FontAwesomeIcon icon={faSignature} size="2x"/>
                    </Grid>
                    <Grid item item style={{width: "85%"}}>
                      <TextField
                        id="ss_realname_name"
                        name="ss_realname_name"
                        label={t("common.form.name")}
                        style={{width: "100%", margin: "10px 0px"}}
                        value={name}
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setName(event.target.value as String);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputsContainer2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item style={{width: "15%"}}>
                      <FontAwesomeIcon icon={faSignature} size="2x"/>
                    </Grid>
                    <Grid item item style={{width: "85%"}}>
                      <TextField
                        id="ss_realname_lastname"
                        name="ss_realname_lastname"
                        label={t("common.form.surname")}
                        style={{width: "100%", margin: "10px 0px"}}
                        value={lastname}
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setLastName(event.target.value as String);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: cardSelected != null ? "block" : "none",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item className={classes.inputIconWidth}>
                    <FontAwesomeIcon icon={faPhone} size="2x"/>
                  </Grid>
                  <Grid item className={classes.inputWidth}>
                    <MuiPhoneNumber
                      id="ss_phone"
                      name="ss_phone"
                      required
                      error={errorPhone}
                      style={{width: "100%", margin: "10px 0px"}}
                      label={t("common.form.phone")}
                      data-cy="user-phone"
                      defaultCountry={"es"}
                      value={phone}
                      onChange={(value) => {
                        setPhone(value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={6} className={classes.inputsContainer1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item style={{width: "15%"}}>
                      <FontAwesomeIcon icon={faRulerVertical} size="2x"/>
                    </Grid>
                    <Grid item item style={measurement_format == 'imperial' ? {width: "40%"} : {width: "85%"}}>
                      <TextField
                        id="ss_height"
                        name="ss_height"
                        label={t("common.form.height") + (measurement_format == 'imperial' ? " (ft)" : "")}
                        style={{width: "100%", margin: "10px 0px"}}
                        value={measurement_format == 'imperial' ? heightFeet : heightCm}
                        type="number"
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          if (measurement_format == 'imperial') {
                            setHeightFeet(event.target.value as Number);
                            let heightAll = (parseFloat(event.target.value ? event.target.value : 0) * 12) + parseFloat(heightInches ? heightInches : 0);
                            setHeightCm(heightAll * 2.54);
                            setHeight(heightAll);
                          } else {
                            let heightAll = Math.round(parseFloat(event.target.value) * 100) / 100;
                            setHeightCm(heightAll);
                            setHeight(heightAll);
                          }
                        }}
                      />
                    </Grid>
                    {measurement_format == 'imperial' && (<Grid item item style={{width: "45%"}}>
                      <TextField
                        id="ss_height"
                        name="ss_height"
                        label={t("common.form.height") + " (in)"}
                        style={{width: "100%", margin: "10px 0px"}}
                        value={heightInches}
                        type="number"
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setHeightInches(event.target.value as Number);
                          let heightAll = (parseFloat(heightFeet ? heightFeet : 0) * 12) + parseFloat(event.target.value ? event.target.value : 0);
                          setHeightCm(heightAll * 2.54);
                          setHeight(heightAll);
                        }}
                      />
                    </Grid>)}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputsContainer2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item style={{width: "15%"}}>
                      <FontAwesomeIcon icon={faWeight} size="2x"/>
                    </Grid>
                    <Grid item item style={{width: "85%"}}>
                      <TextField
                        id="ss_weight"
                        name="ss_weight"
                        label={t("common.form.weight")}
                        style={{width: "100%", margin: "10px 0px"}}
                        value={weight}
                        type="number"
                        onChange={(
                          event: React.ChangeEvent<{ value: unknown }>
                        ) => {
                          setWeight(event.target.value as Number);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item className={classes.inputIconWidth}>
                    <FontAwesomeIcon icon={faRulerHorizontal} size="2x"/>
                  </Grid>
                  <Grid item className={classes.inputWidth}>
                    <TextField
                      label={t("common.form.measurementformat")}
                      select
                      defaultValue={0}
                      style={{width: "100%", margin: "10px 0px"}}
                      value={measurement_format == 'imperial' ? 1 : 0}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        setMeasurementFormat(event.target.value ? 'imperial' : 'metric');
                        if (event.target.value) {
                          let hFeet = Math.round(parseInt((heightCm / 2.54) / 12) * 100) / 100;
                          let hInches = Math.round(((heightCm / 2.54) - (parseInt((heightCm / 2.54) / 12) * 12)) * 100) / 100;
                          setHeightFeet(hFeet);
                          setHeightInches(hInches);
                          let heightAll = parseFloat(hFeet) * 12 + parseFloat(hInches);
                          setHeight(heightAll);
                          setWeight(Math.round(weight * 2.205 * 100) / 100);
                        } else {
                          setHeightCm(Math.round(heightCm * 100) / 100);
                          setHeight(Math.round(heightCm * 100) / 100);
                          setWeight(Math.round(weight / 2.205 * 100) / 100);
                        }
                      }}
                    >
                      <MenuItem value={1}>{t("common.form.imperial")}</MenuItem>
                      <MenuItem value={0}>{t("common.form.metric")}</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>

              <RadioGroup
                aria-label="gender"
                name="gender"
                row
                style={{justifyContent: "center"}}
                value={gender}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="M"
                  name="M"
                  control={<Radio color="primary"/>}
                  label={t("common.form.gendermale")}
                />
                <FormControlLabel
                  value="F"
                  name="F"
                  control={<Radio color="primary"/>}
                  label={t("common.form.genderfemale")}
                />
                <FormControlLabel
                  value="N"
                  name="N"
                  control={<Radio color="primary"/>}
                  label={t("common.form.genderno")}
                />
              </RadioGroup>
            </Grid>
          </fieldset>
          {menor && (
            <fieldset
              className={classes.fieldset}
              style={{
                display: cardSelected != null ? "block" : "none",
                marginTop: 20,
              }}
            >
              <legend className={classes.legend}>
                {t("common.form.tutorData")}
              </legend>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: cardSelected != null ? "block" : "none",
                  padding: "0 12px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className={classes.inputContainer}
                >
                  <Grid item className={classes.inputIconWidth}>
                    <FontAwesomeIcon icon={faSignature} size="2x"/>
                  </Grid>
                  <Grid item className={classes.inputWidth}>
                    <TextField
                      label={t("common.form.fullname")}
                      style={{width: "100%", margin: "10px 0px"}}
                      value={fullNameTutor}
                      error={error}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setFullNameTutor(event.target.value as String);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: cardSelected != null ? "block" : "none",
                  padding: "0 12px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className={classes.inputContainer}
                >
                  <Grid item className={classes.inputIconWidth}>
                    <FontAwesomeIcon icon={faEnvelope} size="2x"/>
                  </Grid>
                  <Grid item className={classes.inputWidth}>
                    <TextField
                      id="standard-basic"
                      label={t("common.form.emailParent")}
                      required
                      style={{width: "100%", margin: "10px 0px"}}
                      value={emailTutor}
                      error={error}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>
                      ) => {
                        setEmailTutor(event.target.value as string);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: cardSelected != null ? "block" : "none",
                  padding: "0 12px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className={classes.inputContainer}
                >
                  <Grid item className={classes.inputIconWidth}>
                    <FontAwesomeIcon icon={faBirthdayCake} size="2x"/>
                  </Grid>
                  <Grid item className={classes.inputWidth}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label={t("common.form.birthday")}
                      format="DD/MM/YYYY"
                      style={{width: "100%", margin: "10px 0px"}}
                      value={birthdateTutor}
                      error={error}
                      onChange={(date: Date, value?: string | null) => {
                        setBirthdateTutor(dayjs(date).format("YYYY-M-D"));
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: cardSelected != null ? "block" : "none",
                  padding: "0 12px",
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item className={classes.inputIconWidth}>
                    <FontAwesomeIcon icon={faPhone} size="2x"/>
                  </Grid>
                  <Grid item className={classes.inputWidth}>
                    <MuiPhoneNumber
                      name="phone"
                      required
                      style={{width: "100%", margin: "10px 0px"}}
                      label={t("common.form.phone")}
                      data-cy="user-phone"
                      regions={[
                        "america",
                        "europe",
                        "asia",
                        "oceania",
                        "africa",
                      ]}
                      defaultCountry={"es"}
                      value={phoneTutor}
                      onChange={(value) => {
                        setPhoneTutor(value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </fieldset>
          )}
          <fieldset
            className={classes.fieldset}
            style={{
              display: cardSelected != null ? "block" : "none",
              marginTop: 20,
            }}
          >
            <legend className={classes.legend}>
              {t("common.form.auth&consent")}
            </legend>
            <Grid item xs={12} style={{margin: "10px 0"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check5}
                    onChange={() => {
                      setCheck5(!check5);
                      if (!check5) {
                        console.log("Valor verdadero", !check5);
                        setCheck1(true);
                        setCheck2(true);
                        setCheck3(true);
                        setCheck4(true);
                      } else {
                        console.log("Valor false", check5);
                        setCheck1(false);
                        setCheck2(false);
                        setCheck3(false);
                        setCheck4(false);
                      }
                    }}
                    name="checkbox1"
                    id="checkbox1"
                    color="primary"
                  />
                }
                label={t("common.form.checkbox5")}
              />
            </Grid>
            <div style={{paddingLeft: 20}}>
              <Grid item xs={12} style={{margin: "10px 0"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check1}
                      onChange={() => {
                        setCheck1(!check1);
                        if (check1) {
                          setCheck5(false);
                        }
                      }}
                      name="checkbox1"
                      id="checkbox1"
                      color="primary"
                    />
                  }
                  label={t("common.form.checkbox1")}
                />
              </Grid>
              <Grid item xs={12} style={{margin: "10px 0"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check2}
                      onChange={() => {
                        setCheck2(!check2);
                        if (check2) {
                          setCheck5(false);
                        }
                      }}
                      name="checkbox2"
                      id="checkbox2"
                      color="primary"
                    />
                  }
                  label={t("common.form.checkbox2")}
                />
              </Grid>
              <Grid item xs={12} style={{margin: "10px 0"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check3}
                      onChange={() => {
                        setCheck3(!check3);
                        if (check3) {
                          setCheck5(false);
                        }
                      }}
                      name="checkbox3"
                      id="checkbox3"
                      color="primary"
                    />
                  }
                  label={t("common.form.checkbox3")}
                />
              </Grid>
              <Grid item xs={12} style={{margin: "10px 0"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check4}
                      onChange={() => {
                        setCheck4(!check4);
                        if (check4) {
                          setCheck5(false);
                        }
                      }}
                      name="checkbox4"
                      id="checkbox4"
                      color="primary"
                    />
                  }
                  label={t("common.form.checkbox4")}
                />
              </Grid>
            </div>
            <Grid item xs={12} style={{margin: "10px 0"}}>
              <Typography variant="body2">
                {t("common.form.policy1")}
                <a
                  href="https://humanox.com/condiciones-de-uso/"
                  className={classes.links}
                >
                  {t("common.form.terms")}
                </a>
                {t("common.form.policy2")}
                <a
                  href="https://humanox.com/privacidad/"
                  className={classes.links}
                >
                  {t("common.form.privacy")}
                </a>
                {t("common.form.policy3")}
                <a
                  href="https://humanox.com/politica-de-cookies/"
                  className={classes.links}
                >
                  {t("common.form.cookies")}
                </a>
                .
              </Typography>
            </Grid>
          </fieldset>
        </form>
        <form id="userdata" name="userdata" style={{display: "none"}}>
          <input
            name="ss_birthday"
            type="date"
            id="ss_birthday"
            value={birthdate}
          />
          <input type="email" name="ss_email" id="ss_email" value={email}/>
          <input
            type="text"
            name="ss_nickname"
            id="ss_nickname"
            value={username}
          />
          <input
            type="text"
            id="ss_realname_name"
            name="ss_realname_name"
            value={name}
          />
          <input
            type="text"
            id="ss_realname_lastname"
            name="ss_realname_lastname"
            value={lastname}
          />
          <input name="ss_height" type="number" id="ss_height" value={height}/>
          <input name="ss_weight" type="number" id="ss_weight" value={weight}/>
          <input
            name="gender"
            type="radio"
            value="M"
            checked={gender == "M" ? true : false}
          />
          <input
            name="gender"
            type="radio"
            value="F"
            checked={gender == "F" ? true : false}
          />
          <input name="ss_phone" type="text" id="ss_phone" value={phone}/>
          <input type="text" id="addr1" name="addr1" value=""/>
          <input type="text" id="addr2" name="addr2" value=""/>
          <input type="text" id="zipcode" name="zipcode" value=""/>
          <input type="text" id="city" name="city" value=""/>
          <input type="text" id="province" name="province" value=""/>
          <input name="invoice" id="invoice" type="checkbox" checked={false}/>

          <input
            type="checkbox"
            id="checkbox1"
            name="Autorizo perfil basado en hábitos"
            checked={check1}
          />
          <input
            type="checkbox"
            id="checkbox2"
            name="Autorizo tratamiento datos salud"
            checked={check2}
          />
          <input
            type="checkbox"
            id="checkbox3"
            name="Autorizo cesion datos a otros usuarios"
            checked={check3}
          />
          <input
            type="checkbox"
            id="checkbox4"
            name="Acepto condiciones y privacidad"
            checked={check4}
          />
          {/* <input type="text" id="pname" name="pname"  />
          <input type="text" id="pemail" name="pemail" />
          <input type="date" id="pbirth" name="pbirth" />
          <input type="text" id="pphone" name="pphone" /> */}
        </form>
        <Grid item xs={12}>
          <div style={{textAlign: "center", margin: "10px 0"}}>
            <Button
              variant="contained"
              onClick={() => onFinishSignup()}
              disabled={
                !email ||
                !pin ||
                !phone ||
                (phone && phone.length < 11) ||
                !check4
              }
              color="primary"
            >
              {t("common.form.create")}
            </Button>
          </div>
        </Grid>
      </Grid>
      <DialogModal
        open={signSuccess}
        onOk={() => onSignupEnd()}
        onCancel={() => onSignupEnd()}
        buttonAgree={"Aceptar"}
        title={t("common.form.accountCreated")}
        text={t("common.form.accountCreatedText")}
      ></DialogModal>
    </Container>
  );
};

export default RegisterScreen;

const useStyles = makeStyles(({palette, breakpoints}: Theme) =>
  createStyles({
    container: {
      marginTop: 10,
    },
    root: {
      display: "flex",
      zIndex: 1,
    },
    backButtonContainer: {
      marginBottom: 10,
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    content: {
      flex: "1 0 auto",
      textAlign: "center",
      position: "relative",
    },
    cover: {
      width: 100,
    },
    cardImage: {
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
    },
    playerTypeTitle: {
      color: palette.primary.main,
    },
    buttonType: {
      position: "absolute",
    },
    inputsContainer1: {
      [breakpoints.up("sm")]: {
        paddingRight: 5,
      },
    },
    inputsContainer2: {
      [breakpoints.up("sm")]: {
        paddingLeft: 5,
      },
    },
    inputContainer: {
      padding: 0,
    },
    inputIconWidth: {
      width: "15%",
      [breakpoints.up("sm")]: {
        width: "7%",
      },
    },
    inputWidth: {
      width: "85%",
      [breakpoints.up("sm")]: {
        width: "93%",
      },
    },
    arrowContainer: {
      position: "absolute",
      zIndex: 9999,
      bottom: 24,
      left: 24,
    },
    arrowIcon: {
      color: palette.primary.main,
      cursor: "pointer",
      position: "absolute",
      bottom: 24,
      left: 24,
    },
    fieldset: {
      border: "2px solid",
      borderColor: palette.primary.main,
      borderRadius: 8,
      width: "calc(100% - 24px)",
      margin: "0 auto",
    },
    legend: {
      textTransform: "uppercase",
      fontSize: 14,
    },
    links: {
      color: palette.primary.main,
    },
  })
);
