import React, { FC, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import useAuth from "../Services/Auth";
import jwt_decode from "jwt-decode";

const AuthRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth();
  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    if (userData && userData.jwt) {
      const { exp } = jwt_decode(userData.jwt);
      const expirationTime = exp * 1000;
      if (Date.now() >= expirationTime) {
        auth.logOut();
      }
    }
  }, [userData]);
  return (
    !auth.isLoading && (
      <Route
        {...rest}
        render={(props) => {
          const userData = JSON.parse(localStorage.getItem("userData"));
          if (userData && userData.jwt) {
            return <Component {...props} userData={userData} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            );
          }
        }}
      />
    )
  );
};

export default AuthRoute;
