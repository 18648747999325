import React, {FC, useEffect, useMemo, useState} from "react";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
  UPDATE_PROFILE,
  USER_PROFILE,
  CREATE_USER_CUSTOMERCARE,
} from "../../lib/queries/user.queries";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  NoSsr,
  Chip,
  TextField,
  Theme,
  Typography,
  Container,
} from "@material-ui/core";
import dayjs from "dayjs";
import MuiPhoneNumber from "mui-phone-input-ssr";
import logoIcon from "../../lib/assets/logo.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import countryList from "react-select-country-list";
import {
  faBirthdayCake,
  faBuilding,
  faCity,
  faEnvelope,
  faFlag,
  faHouseUser,
  faMailBulk,
  faPhone,
  faRulerHorizontal,
  faRulerVertical,
  faSearch,
  faShieldAlt,
  faSignature,
  faTemperatureLow,
  faTshirt,
  faUser,
  faVenusMars,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import gamerIcon from "../../lib/assets/ico_gamer.png";
import gamerProIcon from "../../lib/assets/ico_gamer_pro.png";
import pro from "../../lib/assets/ico_professional.png";
import trainer from "../../lib/assets/ico_trainer.png";
import physical from "../../lib/assets/ico_phystrainer.png";
import enviromentIcon from "../../lib/assets/ico_environment.png";
import follower from "../../lib/assets/ico_follower.png";
import fieldIcon from "../../lib/assets/field.png";
import useAdmin from "../../Services/Admin";
import {useLocation} from "react-router-dom";
import DialogModal from "../../Components/DialogModal";

const playerType = [
  {
    id: 1,
    type: "GAMER",
    icon: gamerIcon,
  },
  {
    id: 2,
    type: "ProGamer",
    icon: gamerProIcon,
  },
  {
    id: 3,
    type: "Professional",
    icon: pro,
  },
  {
    id: 4,
    type: "Trainer",
    icon: trainer,
  },
  {
    id: 5,
    type: "PhysicalTrainer",
    icon: physical,
  },
  {
    id: 6,
    type: "Enviroment",
    icon: enviromentIcon,
  },
  {
    id: 7,
    type: "Follower",
    icon: follower,
  },
];

const ProfileScreen: FC = (props) => {
  const classes = useStyles();
  const test = useAdmin();
  const location = useLocation();
  let id = props && props.userData && props.userData.id;
  let uuid = props && props.userData && props.userData.uuid;
  if (location && location.state && location.state.id) {
    id = location.state.id;
    uuid = localStorage.getItem("uuid");
  }

  const [updateProfileMutation] = useMutation(UPDATE_PROFILE);
  const [edit, setEdit] = useState(false);
  const [changeMetricModal, setChangeMetricModal] = useState(false);
  const [profile, setProfile] = useState();
  const [position, setPosition] = useState();
  const [playerNum, setPlayerNum] = useState();
  const [teamname, setTeamname] = useState();
  const [name, setName] = useState("");
  const [fullname, setFullname] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [birth, setBirth] = useState(new Date());
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState();
  const [height, setHeight] = useState();
  const [heightCm, setHeightCm] = useState();
  const [heightFeet, setHeightFeet] = useState();
  const [heightInches, setHeightInches] = useState();
  const [weight, setWeight] = useState();
  const [ground_temperature, setGroundTemp] = useState();
  const [measurement_format, setMeasurementFormat] = useState();
  const [canbefollowed, setCanBeFollow] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState("ES");
  const [visible, setVisible] = useState(false);
  const [zipcode, setZipcode] = useState();
  const [error, setError] = useState();
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState(false);
  const {t} = useTranslation();
  const [userAccordion, setUserAccordion] = useState(true);
  const [addressAccordion, setAddressAccordion] = useState(false);
  let updateProfileData = {};
  const {data: profileData, refetch: refetchUserProfile} = useQuery(
    USER_PROFILE,
    {
      variables: {id: parseFloat(id), uuid},
    }
  );
  const options = useMemo(() => countryList().getData(), []);
  const [createUserCustomerCare] = useMutation(CREATE_USER_CUSTOMERCARE);

  useEffect(() => {
    if (id) {
      checkImgUrl(`https://live.humanox.com/usermedia/userpics/${id}.jpg`);
    }
  }, [id]);
  useEffect(() => {
    if (profileData && profileData.getProfile) {
      //console.log("Valor del perfil", profileData);
      setProfile(profileData.getProfile.profile);
      setPosition(profileData.getProfile.player_position_id);
      setPlayerNum(profileData.getProfile.player_number);
      setTeamname(profileData.getProfile.teamname);
      setName(profileData.getProfile.name);
      setFullname(profileData.getProfile.fullname);
      setPhone(profileData.getProfile.phone);
      setEmail(profileData.getProfile.email);
      setGender(profileData.getProfile.gender);
      setHeight(profileData.getProfile.height);
      setWeight(profileData.getProfile.weight);
      setGroundTemp(profileData.getProfile.ground_temperature);
      setMeasurementFormat(profileData.getProfile.measurement_format);
      setCanBeFollow(profileData.getProfile.canbefollowed);
      setBirthdate(dayjs(profileData.getProfile.birthdate).format("YYYY-M-D"));
      setAddress1(profileData.getProfile.address1);
      setAddress2(profileData.getProfile.address2);
      setCity(profileData.getProfile.city);
      setCountry(profileData.getProfile.country);
      setZipcode(profileData.getProfile.zipcode);
      if (profileData.getProfile.measurement_format == 'imperial') {
        setHeightFeet(Math.round(parseInt(profileData.getProfile.height / 12) * 100) / 100);
        setHeightInches(Math.round((profileData.getProfile.height - (parseInt(profileData.getProfile.height / 12) * 12)) * 100) / 100);
        setHeightCm(profileData.getProfile.height * 2.54);
      } else {
        setHeightFeet(Math.round(parseInt((profileData.getProfile.height / 2.54) / 12) * 100) / 100);
        setHeightInches(Math.round(((profileData.getProfile.height / 2.54) - (parseInt((profileData.getProfile.height / 2.54) / 12) * 12)) * 100) / 100);
        setHeightCm(profileData.getProfile.height);
      }
    }
  }, [profileData]);
  const updateProfile = async () => {
    updateProfileData = {
      profile,
      teamname,
      name,
      fullname,
      email,
      phone,
      birthdate,
      gender,
      player_number: playerNum,
      player_position_id: position,
      height,
      weight,
      ground_temperature,
      measurement_format,
      canbefollowed: canbefollowed.toString(),
      address1,
      address2,
      city,
      country,
      zipcode,
    };

    try {
      const {data: updatedProfile} = await updateProfileMutation({
        variables: {updateProfileData, userId: parseFloat(id), uuid},
      });
      let userCustomer = {
        customer_id: parseFloat(id),
        username: name,
        first_name: fullname ? fullname : "first_name",
        last_name: fullname ? fullname : "last_name",
        height: height ? parseFloat(height) : 0,
        weight: weight ? parseFloat(weight) : 0,
        phone: phone.replace(/ /g, ""),
        address1: address1 ? address1 : "Empty",
        address2,
        postalcode: zipcode ? zipcode : "11000",
        city: city ? city : "Empty",
        country_code: country,
        email,
        birthdate,
        invoice: 0,
      };
      let resUserCustomer = await createUserCustomerCare({
        variables: {userCustomer},
      });
    } catch (e) {
      refetchUserProfile();
    }
  };

  const checkImgUrl = (url: String) => {
    fetch(url)
      .then((res) => {
        if (res.status == 404) {
          setImage("");
        } else {
          setImage(`https://live.humanox.com/usermedia/userpics/${id}.jpg`);
        }
      })
      .catch((e) => {
        setImage("");
      });
  };
  const handleImage = (event: Event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    console.log(file.name);
    reader.onload = function (e) {
      setFile(e.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    if (
      file.type == "image/png" ||
      file.type == "image/jpg" ||
      file.type == "image/jpeg"
    ) {
      return true;
    }
    if (file.size / 1000000 > 10) {
      window.alert("Please upload a file smaller than 1 MB");
      return false;
    }
  };
  const loadForm = (data: any) => {
    return (
      <div className={classes.formContainer}>
        <Accordion
          expanded={userAccordion}
          onChange={() => {
            setUserAccordion(!userAccordion);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary"/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography color="primary">{t("common.form.userData")}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{flexDirection: "column"}}>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <img
                  src={
                    profile ? playerType[profile - 1].icon : playerType[0].icon
                  }
                  className={
                    profile == 1 || profile == 2 || profile == 4
                      ? classes.profileType
                      : classes.profileTypeSmall
                  }
                />
              </Grid>

              <Grid item item style={{width: "85%"}}>
                <TextField
                  select
                  label={t("common.form.profile")}
                  id="demo-simple-select"
                  disabled={!edit}
                  style={{width: "100%", margin: "10px 0px"}}
                  defaultValue={data.profile}
                  value={profile}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setProfile(event.target.value);
                  }}
                >
                  <MenuItem value={1}>{t("common.playerType.gamer")}</MenuItem>

                  <MenuItem value={6}>
                    {t("common.playerType.enviroment")}
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            {profile == 1 && (
              <Grid container spacing={2} alignItems="center">
                <Grid item style={{width: "15%"}}>
                  <img src={fieldIcon} className={classes.profileTypeSmall}/>
                </Grid>

                <Grid item item style={{width: "85%"}}>
                  <TextField
                    select
                    label={t("common.form.position")}
                    id="demo-simple-select"
                    disabled={!edit}
                    style={{width: "100%", margin: "10px 0px"}}
                    defaultValue={data.player_position_id}
                    value={position}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setPosition(event.target.value);
                    }}
                  >
                    <MenuItem value={1}>
                      {t("common.playerPosition.goalkeeper")}
                    </MenuItem>
                    <MenuItem value={2}>
                      {t("common.playerPosition.fullBack")}
                    </MenuItem>
                    <MenuItem value={3}>
                      {t("common.playerPosition.centerBack")}
                    </MenuItem>
                    <MenuItem value={4}>
                      {t("common.playerPosition.centralmidfield")}
                    </MenuItem>
                    <MenuItem value={5}>
                      {t("common.playerPosition.wingers")}
                    </MenuItem>

                    <MenuItem value={6}>
                      {t("common.playerPosition.striker")}
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            )}
            {profile == 1 && (
              <Grid container spacing={2} alignItems="center">
                <Grid item style={{width: "15%"}}>
                  <FontAwesomeIcon icon={faTshirt} size="2x"/>
                </Grid>

                <Grid item style={{width: "85%"}}>
                  <TextField
                    label={t("common.form.playerNum")}
                    type="number"
                    disabled={!edit}
                    InputProps={{inputProps: {min: 1, max: 99}}}
                    defaultValue={data.player_number}
                    style={{width: "100%", margin: "10px 0px"}}
                    value={playerNum}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setPlayerNum(parseInt(event.target.value as Number));
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faShieldAlt} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.teamname")}
                  disabled={!edit}
                  defaultValue={data.teamname}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={teamname}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setTeamname(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faUser} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.nick")}
                  disabled={!edit}
                  defaultValue={data.name}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={name}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setName(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faSignature} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.fullname")}
                  disabled={!edit}
                  defaultValue={data.fullname}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={fullname}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFullname(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faEnvelope} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.email")}
                  disabled={!edit}
                  defaultValue={data.email}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={email}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setEmail(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faPhone} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <MuiPhoneNumber
                  name="phone"
                  disabled={!edit}
                  label={t("common.form.phone")}
                  data-cy="user-phone"
                  regions={["america", "europe", "asia", "oceania", "africa"]}
                  defaultCountry={"es"}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={phone}
                  defaultValue={data.phone}
                  onChange={(value) => {
                    setPhone(value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faBirthdayCake} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <KeyboardDatePicker
                  style={{width: "100%", margin: "10px 0px"}}
                  id="date-picker-dialog"
                  label={t("common.form.birthday")}
                  format={measurement_format == 'imperial' ? "YYYY/MM/DD" : "DD/MM/YYYY"}
                  value={birthdate}
                  defaultValue={dayjs(data.birthdate).format("YYYY-M-D")}
                  disabled={!edit}
                  onChange={(date: Date, value?: string | null) => {
                    setBirth(date);
                    setBirthdate(dayjs(date).format("YYYY-M-D"));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faVenusMars} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <TextField
                  select
                  label={t("common.form.gender")}
                  id="demo-simple-select"
                  disabled={!edit}
                  style={{width: "100%", margin: "10px 0px"}}
                  defaultValue={data.gender}
                  value={gender}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setGender(event.target.value);
                  }}
                >
                  <MenuItem value={"M"}>{t("common.form.gendermale")}</MenuItem>
                  <MenuItem value={"F"}>
                    {t("common.form.genderfemale")}
                  </MenuItem>
                  <MenuItem value={"N"}>{t("common.form.genderno")}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faRulerVertical} size="2x"/>
              </Grid>
              <Grid item item style={measurement_format == 'imperial' ? {width: "40%"} : {width: "85%"}}>
                <TextField
                  label={t("common.form.height") + (measurement_format == 'imperial' ? " (ft)" : " (cm)")}
                  type="number"
                  disabled={!edit}
                  defaultValue={measurement_format == 'imperial' ? heightFeet : heightCm}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={measurement_format == 'imperial' ? heightFeet : heightCm}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    if (measurement_format == 'imperial') {
                      setHeightFeet(event.target.value as Number);
                      let heightAll = (parseFloat(event.target.value ? event.target.value : 0) * 12) + parseFloat(heightInches ? heightInches : 0);
                      setHeightCm(heightAll * 2.54);
                      setHeight(heightAll);
                    } else {
                      let heightAll = Math.round(parseFloat(event.target.value) * 100) / 100;
                      setHeightCm(heightAll);
                      setHeight(heightAll);
                    }
                  }}
                />
              </Grid>
              {measurement_format == 'imperial' && (<Grid item item style={{width: "45%"}}>
                <TextField
                  label={t("common.form.height") + " (in)"}
                  type="number"
                  disabled={!edit}
                  defaultValue={heightInches}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={heightInches}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setHeightInches(event.target.value as Number);
                    let heightAll = (parseFloat(heightFeet ? heightFeet : 0) * 12) + parseFloat(event.target.value ? event.target.value : 0);
                    setHeightCm(heightAll * 2.54);
                    setHeight(heightAll);
                  }}
                />
              </Grid>)}
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faWeight} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.weight") + (measurement_format == 'imperial' ? " (lbs)" : " (kg)")}
                  type="number"
                  disabled={!edit}
                  defaultValue={data.weight}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={weight}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setWeight(parseFloat(event.target.value as Number));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faTemperatureLow} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.groundTemp") + (measurement_format == 'imperial' ? " (ºF)" : " (ºC)")}
                  type="number"
                  disabled={!edit}
                  defaultValue={data.ground_temperature}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={ground_temperature}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setGroundTemp(parseFloat(event.target.value as Number));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faRulerHorizontal} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <DialogModal
                  open={changeMetricModal}
                  onOk={() => {
                    setChangeMetricModal(false);
                    setEdit(false);
                    updateProfile();
                  }}
                  onCancel={() => {
                    setChangeMetricModal(false);
                  }}
                  buttonAgree={t("common.accept")}
                  buttonDesagree={t("common.cancel")}
                  text={t("common.form.changeMetricWarning")}
                ></DialogModal>
                <TextField
                  label={t("common.form.measurementformat")}
                  select
                  disabled={!edit}
                  defaultValue={data.measurement_format == 'imperial' ? 1 : 0}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={measurement_format == 'imperial' ? 1 : 0}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setMeasurementFormat(event.target.value ? 'imperial' : 'metric');
                    if (event.target.value) {
                      let hFeet = Math.round(parseInt((heightCm / 2.54) / 12) * 100) / 100;
                      let hInches = Math.round(((heightCm / 2.54) - (parseInt((heightCm / 2.54) / 12) * 12)) * 100) / 100;
                      setHeightFeet(hFeet);
                      setHeightInches(hInches);
                      let heightAll = parseFloat(hFeet) * 12 + parseFloat(hInches);
                      setHeight(heightAll);
                      setWeight(Math.round(weight * 2.205 * 100) / 100);
                      setGroundTemp(Math.round(((ground_temperature * 9 / 5) + 32) * 100) / 100);
                    } else {
                      setHeightCm(Math.round(heightCm * 100) / 100);
                      setHeight(Math.round(heightCm * 100) / 100);
                      setWeight(Math.round(weight / 2.205 * 100) / 100);
                      setGroundTemp(Math.round(((ground_temperature - 32) * 5 / 9) * 100) / 100);
                    }
                  }}
                >
                  <MenuItem value={1}>{t("common.form.imperial")}</MenuItem>
                  <MenuItem value={0}>{t("common.form.metric")}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faSearch} size="2x"/>
              </Grid>
              <Grid item item style={{width: "85%"}}>
                <TextField
                  select
                  label={t("common.form.canbefollowed")}
                  id="demo-simple-select"
                  disabled={!edit}
                  style={{width: "100%", margin: "10px 0px"}}
                  defaultValue={data.canbefollowed}
                  value={canbefollowed}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setCanBeFollow(event.target.value as String);
                  }}
                >
                  <MenuItem value={1}>{t("common.form.canfollowed")}</MenuItem>
                  <MenuItem value={0}>{t("common.form.cantfollowed")}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={addressAccordion}
          onChange={() => {
            setAddressAccordion(!addressAccordion);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary"/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography color="primary">{t("common.form.address")}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{flexDirection: "column"}}>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faHouseUser} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.address1")}
                  disabled={!edit}
                  defaultValue={data.address1}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={address1}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setAddress1(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faBuilding} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.address2")}
                  disabled={!edit}
                  defaultValue={data.address2}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={address2}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setAddress2(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faCity} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.city")}
                  disabled={!edit}
                  defaultValue={data.city}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={city}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setCity(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faMailBulk} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <TextField
                  label={t("common.form.zipcode")}
                  disabled={!edit}
                  defaultValue={data.zipcode}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={zipcode}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setZipcode(event.target.value as string);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{width: "15%"}}>
                <FontAwesomeIcon icon={faFlag} size="2x"/>
              </Grid>
              <Grid item style={{width: "85%"}}>
                <TextField
                  select
                  label={t("common.form.country")}
                  id="demo-simple-select"
                  required
                  disabled={!edit}
                  style={{width: "100%", margin: "10px 0px"}}
                  value={country}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setCountry(event.target.value);
                  }}
                >
                  {options.map((country) => (
                    <MenuItem value={country.value}>{country.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };
  return (
    <Container>
      <input
        accept="image/*"
        className={classes.input}
        id="file"
        multiple
        type="file"
        onChange={(e: Event) => handleImage(e)}
      />
      <div className={classes.imageProfileContainer}>
        {/* <label htmlFor="file"> */}
        <div
          style={{width: 100, height: 100, margin: "10px auto"}}
          // onMouseEnter={() => setVisible(true)}
          // onMouseLeave={() => setVisible(false)}
        >
          {image && <img src={logoIcon} className={classes.imageProfile}/>}
          {!image && <img src={logoIcon} className={classes.imageProfile}/>}

          {/* {visible && (
              <div className={classes.hoverImage}>
                <FontAwesomeIcon icon={faCamera} size="lg" />
              </div>
            )} */}
        </div>
        {/* </label> */}
        {/* <div>Prueba de texto</div> */}
      </div>
      {profileData && loadForm(profileData.getProfile)}
      <div className={classes.editButtonContainer}>
        {!edit && (
          <Button
            onClick={() => setEdit(true)}
            variant="contained"
            color="primary"
          >
            {t("common.form.edit")}
          </Button>
        )}
        {edit && (
          <Grid container>
            <Grid item xs={6}>
              <Button
                onClick={() => setEdit(false)}
                variant="contained"
                color="primary"
              >
                {t("common.cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  if (profileData.getProfile.measurement_format !== measurement_format) {
                    setChangeMetricModal(true);
                  } else {
                    setEdit(false);
                    updateProfile();
                  }
                }}
                disabled={!name || !phone || (phone && phone.length < 11)}
                variant="contained"
                color="primary"
              >
                {t("common.form.update")}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
};

export default ProfileScreen;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
    },
    imageProfile: {
      borderRadius: "50%",
      border: "3px solid #3AAA35",
      height: 100,
      width: 100,
    },
    imageProfileContainer: {
      textAlign: "center",
      position: "relative",
      paddingBottom: 10,
    },
    editButtonContainer: {
      textAlign: "center",
    },
    formContainer: {
      maxWidth: 500,
      margin: "10px auto",
      [theme.breakpoints.down("xs")]: {
        maxWidth: 350,
      },
    },
    profileType: {
      width: 25,
      height: 35,
    },
    profileTypeSmall: {
      width: 35,
      height: 40,
    },
    input: {
      display: "none",
    },
    hoverImage: {
      height: 100,
      width: 100,
      backgroundColor: "rgba( 0, 0, 0, 0.5)",
      borderRadius: "50%",
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
