import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";

import { useMutation } from "@apollo/client";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { GET_USER_UUID } from "../lib/queries/user.queries";

export const AdminContext = createContext(null);

export interface IAdminProviderProps {
  userId: any;
  onAdminChange: (userId: any) => void;
}

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const AdminProvider: FC<IAdminProviderProps> = ({
  userId,
  onAdminChange,
  children,
}) => {
  const query = useQueryParams();
  const history = useHistory();
  const [id, setId] = useState();
  const [adminToken, setAdminToken] = useState();
  const [token] = useMutation(GET_USER_UUID);

  useEffect(() => {
    let tokenValue = query.get("token");
    let idValue = query.get("id");
    if (!tokenValue || !idValue) {
      tokenValue = localStorage.getItem("token");
      idValue = localStorage.getItem("userId");
    }
    //console.log("Valor de tokenValue", tokenValue);
    if (tokenValue && idValue) {
      localStorage.setItem("token", tokenValue);
      setAdminToken(tokenValue);
      setId(idValue);
    }
  }, []);

  const getUuid = async () => {
    if (id) {
      const { data: uuid } = await token({
        variables: { userId: parseFloat(id) },
      });
      localStorage.setItem("uuid", uuid.getuseruuid);
    }
  };
  useEffect(() => {
    getUuid();
  }, [id]);

  const adminContext = {
    logOut: async () => {
      onAdminChange(null);
      localStorage.clear();
    },
    userId,
  };
  return (
    <AdminContext.Provider value={adminContext}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
export default useAdmin;
