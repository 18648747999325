import React, { FC } from "react";
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 0,
      display: "inline-block",
      margin: 10,
    },
    svgContainer: {
      height: 100,
      width: 100,
      position: "relative",
      margin: "auto",
    },
    valueContainer: {
      position: "absolute",
      transform: "translateX(-50%) translateY(-50%);",
      left: "50%",
      top: "50%",
      width: "auto",
      padding: 0,
    },
    valueText: {
      fontWeight: 600,
      textAlign: "center",
    },
    valueUnits: {
      textAlign: "center",
    },
    valueLabel: {
      textAlign: "center",
      fontWeight: 600,
    },
  })
);

interface IGaugeProps {
  value: number;
  max: number;
  units: string;
  label: string;
}

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
};

const Gauge: FC<IGaugeProps> = ({ value, max, units, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.svgContainer}>
        <svg height="100%" width="100%" viewBox="0 0 1 1">
          <circle
            cx={0.5}
            cy={0.5}
            r="0.45"
            stroke="#3A3A3B"
            strokeWidth="0.1"
            fill="none"
          ></circle>
          <path
            d={describeArc(0.5, 0.5, 0.45, 270 - (360 * value) / max, 270)}
            stroke="#3AAA35"
            strokeWidth="0.1"
            fill="none"
          ></path>
        </svg>
        <div className={classes.valueContainer}>
          <div className={classes.valueText}>
            {Math.round((value + Number.EPSILON) * 10) / 10}
          </div>
          <div className={classes.valueUnits}>{units}</div>
        </div>
      </div>

      <p className={classes.valueLabel}>{label}</p>
    </div>
  );
};

export default Gauge;
