import gql from "graphql-tag";

export const CHECK_DATA_PROCESSED = gql`
query checkdataprocessed($matchId: Float!, $userId: Float!){
  checkdataprocessed(matchId: $matchId, userId: $userId)
}`;

export const MATCHES_QUERY = gql`
  query Matches($id: Float!, $uuid: String!) {
    getlistallmatches(userid: $id, uuid: $uuid) {
      matches {
        id
        name
        startTime
        team1_score
        team2_score
      }
    }
  }
`;

export const GET_POSITIONS_QUERY = gql`
  query GetPositions($matchId: Float!, $userId: Float!, $uuid: String!) {
    getgeopositionmatch(matchid: $matchId, userid: $userId, uuid: $uuid) {
      geomatch {
        geo_lon
        geo_lat
      }
    }
  }
`;

export const GET_KICK_POSITIONS = gql`
  query GetKickPositions($matchId: Float!, $userId: Float!, $uuid: String!) {
    getkickpositions(matchid: $matchId, userid: $userId, uuid: $uuid) {
      geomatch {
        geo_lon
        geo_lat
      }
    }
  }
`;

export const GET_HIT_POSITIONS = gql`
  query GetHitPositions($matchId: Float!, $userId: Float!, $uuid: String!) {
    gethitspositions(matchid: $matchId, userid: $userId, uuid: $uuid) {
      geomatch {
        geo_lon
        geo_lat
      }
    }
  }
`;

export const GET_DATA_MATCH_QUERY = gql`
  query getMatchDatas($matchId: Float!, $userId: Float!, $uuid: String!) {
    getmatchdata(matchId: $matchId, userId: $userId, uuid: $uuid) {
      distance
      steps
      timeplayed
      kcals
      walking
      running
      sprinting
      maxspeed
      avgspeed
      max_hr
      avg_hr
      impacts
      l_shootings
      r_shootings
      maxkickstrenght
      temperature
      shinguards
      zones
    }
  }
`;

export const MATCH_SPEED = gql`
  query GetMatchSpeed($matchId: Float!, $userId: Float!, $uuid: String!) {
    getmatchspeed(matchId: $matchId, userId: $userId, uuid: $uuid) {
      speeds {
        second
        speed
      }
    }
  }
`;

export const MATCH_HR = gql`
  query GetMatchHr($matchId: Float!, $userId: Float!, $uuid: String!) {
    getmatchhr(matchId: $matchId, userId: $userId, uuid: $uuid) {
      hrs {
        second
        hr
      }
    }
  }
`;

export const MATCH_KCALS = gql`
  query GetMatchKcals($matchId: Float!, $userId: Float!) {
    getmatchkcals(matchId: $matchId, userId: $userId) {
      kcals {
        second
        kcal
      }
    }
  }
`;

export const CREATE_MATCH = gql`
  mutation StartMatch($input: InputStart, $userId: Float!) {
    startmatch(inputstart: $input, userId: $userId) {
      id
      name
      stadium_name
      location
      team1
      team2
    }
  }
`;

export const END_MATCH = gql`
  mutation StopMatch($id: Float!, $matchId: Float!, $timefinish: String!) {
    stopmatch(id: $id, matchId: $matchId, timefinish: $timefinish) {
      id
    }
  }
`;

export const CHECK_LIVE_MATCH = gql`
  query CheckLiveMatch($userId: Float!, $uuid: String) {
    checklivematch(userId: $userId, uuid: $uuid) {
      id
      startTime
    }
  }
`;

export const CHECK_MATCH_ENDED = gql`
  query CheckMatchEnded($matchid: Float!, $userid: Float!) {
    checkmatchended(matchid: $matchid, userid: $userid)
  }
`;

export const LIVE_MATCH_INFO = gql`
  query liveMatchInfo($matchId: Float!, $shinguard: Float!) {
    livematchinfo(matchId: $matchId, shinguard: $shinguard) {
      geo_lon
      geo_lat
      temperature
      speed
      hr
      steps
      event_type
      event_fk
      event_bk
    }
  }
`;

export const DELETE_MATCH = gql`
  mutation DeleteMatch($matchId: Float!, $userId: Float!) {
    deletematch(matchId: $matchId, userId: $userId)
  }
`;

export const SAVE_REQUEST_DATA = gql`
  query saveRequestData($matchId: Float!,$userId: Float!) {
    saverequestdata(matchId: $matchId, userId: $userId)
  }
`;

export const GET_REQUEST_DATA = gql`
  query getRequestData($matchId: Float!,$userId: Float!,$uuid: String!) {
    getrequestdata(matchId: $matchId, userId: $userId, uuid: $uuid){
      getmatchdata {
          distance
          steps
          timeplayed
          kcals
          walking
          running
          sprinting
          maxspeed
          avgspeed
          max_hr
          avg_hr
          impacts
          l_shootings
          r_shootings
          maxkickstrenght
          temperature
          shinguards
          zones
        }
        getgeopositionmatch {
          geomatch {
            event_ts
            geo_lon
            geo_lat
            period
          }
        }
        getkickpositions {
          geomatch {
            event_ts
            geo_lon
            geo_lat
            period
          }
        }
        gethitspositions {
          geomatch {
            event_ts
            geo_lon
            geo_lat
            period
          }
        }
        getmatchspeed {
          speeds {
            second
            speed
          }
        }
        getmatchhr {
          hrs {
            hr
            second
          }
        }
        getmatchkcals{
          kcals{
            kcal
            second
          }
        }
    }
  }
`;
