import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Chip,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FollowUser from "../../Components/FollowUser";
import {
  FOLLOWING_USERS,
  FOLLOW_USER,
  REMOVE_FOLLOW,
} from "../../lib/queries/comunity.queries";
import { useTranslation } from "react-i18next";
import useAuth from "../../Services/Auth";

const ComunityScreen: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const location = useLocation();
  let id = auth && auth.userData && auth.userData.id;
  let uuid = auth && auth.userData && auth.userData.uuid;
  if (location && location.state && location.state.id) {
    id = parseFloat(location.state.id);
    uuid = localStorage.getItem("uuid");
  }
  const classes = useStyles();
  const [mic, setMic] = useState();
  const [cantbeFollowed, setCantBeFollowed] = useState(false);
  const [removeFollowUser] = useMutation(REMOVE_FOLLOW);
  const { data: followingData, refetch: refetchFollowing } = useQuery(
    FOLLOWING_USERS,
    {
      variables: { userId: parseFloat(id), uuid },
    }
  );

  const following = followingData && followingData.allFollowingUsers.followers;
  console.log("Valor de following", following);
  const [newFollow] = useMutation(FOLLOW_USER);
  const followUser = async () => {
    try {
      const { data: folloUserData } = await newFollow({
        variables: { shinguard: mic, userId: id },
      });

      if (
        folloUserData &&
        folloUserData.followuser &&
        folloUserData.followuser.id == -1
      ) {
        setCantBeFollowed(true);
      }
      refetchFollowing();
    } catch (e) {
      console.log("Error", e);
    }
  };

  const removeFollow = async (player) => {
    const { data: deleted } = await removeFollowUser({
      variables: { followingId: player.id, userId: id },
    });
    refetchFollowing();
  };

  return (
    <Container style={{ paddingBottom: 5 }}>
      {following && (
        <div>
          <h2> {t("comunityScreen.addplayer")}</h2>
          <TextField
            id="standard-basic"
            label={t("comunityScreen.playersMICnumber")}
            style={{ width: "100%", margin: "10px 0px" }}
            value={mic}
            type="number"
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setMic(event.target.value as Number);
            }}
          />
          <Button
            onClick={() => followUser()}
            color="primary"
            variant="contained"
          >
            {t("comunityScreen.follow")}
          </Button>
        </div>
      )}
      {following && (
        <div>
          <Grid container>
            {following.map((follow) => (
              <FollowUser
                key={follow.id}
                id={follow.id}
                name={follow.name ? follow.name : follow.fullname}
                profile={follow.profile}
                live={follow.live == 1 ? true : false}
                onClick={() => {
                  if (location && location.state && location.state.id) {
                    history.push({
                      pathname: `/admin/matches/${follow.id}`,
                      state: { id: follow.id, uuid: follow.uuid },
                    });
                  } else {
                    history.push({
                      pathname: `/matches/${follow.id}`,
                      state: { uuid: follow.uuid },
                    });
                  }
                }}
                onDelete={() => removeFollow(follow)}
              />
            ))}
          </Grid>
        </div>
      )}
      <Dialog
        open={cantbeFollowed}
        onClose={() => setCantBeFollowed(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("comunityScreen.cantbefollow")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("comunityScreen.cantbefollowed")}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCantBeFollowed(false);
            }}
          >
            {t("common.accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ComunityScreen;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
      position: "relative",
      cursor: "pointer",
      display: "flex",
    },
    container: {
      padding: "0 20px 20px 20px",
    },
    followingDetails: {
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flex: "1 0 auto",
    },
    cover: {
      width: 151,
    },
    playetTypeContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    playerTypeImage: {
      width: 50,
      height: 50,
    },
    cardImage: {
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
    },
    titleContainer: {
      textAlign: "center",
      marginBottom: 20,
    },
    titleText: {
      color: theme.palette.primary.main,
    },
  })
);
