import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  createStyles,
  Theme,
  IconButton,
  Typography,
  ListItemIcon,
  Drawer,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import useAuth from "../Services/Auth";
import { useTranslation } from "react-i18next";
import { AccountCircle } from "@material-ui/icons";
import logo from "../lib/assets/logo2.png";
import comunnityIcon from "../lib/assets/community-icon-white.png";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import Shinguards from "../lib/assets/shinguards.png";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import useAdmin from "../Services/Admin";
import i18next from "i18next";
import { CHECK_LIVE_MATCH, CHECK_MATCH_ENDED } from "../lib/queries";
import { Keyframes, animated, config } from "react-spring/renderprops";
import ReactFlagsSelect from "react-flags-select";
import dayjs from "dayjs";
import * as es from "dayjs/locale/es";
import * as en from "dayjs/locale/en";
const drawerWidth = 240;

const Content = Keyframes.Spring(async (next) => {
  while (true) {
    await next({
      from: { opacity: 0 },
      opacity: 1,
    });
    await next({
      from: { opacity: 1 },
      opacity: 0,
    });
  }
});

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const Header = ({ userData }, props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const auth = useAuth();
  const admin = useAdmin();
  const location = useLocation();
  const [selected, setSelected] = useState("ES");
  const query = useQueryParams();

  let id = -1;
  let uuid = userData && userData.uuid;
  const idValue = query.get("id");

  if (idValue) {
    id = idValue;
  }

  if (location && location.state && location.state.id) {
    id = location && location.state && parseFloat(location.state.id);
    uuid = localStorage.getItem("uuid");
  }
  console.log("Valor de uuid en header", uuid);
  const history = useHistory();
  // if (id == null || userData == null) {
  //   console.log("Entra aqui");
  //   localStorage.clear();
  //   history.push("/login");
  // }
  const [showMenu, setShowMenu] = useState(false);
  //const [anchorEl, setAnchorEl] = useState();

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "es");
      setSelected("ES");
    } else if (lang == "es") {
      localStorage.setItem("lang", "es");
      setSelected("ES");
    } else if (lang != "es") {
      localStorage.setItem("lang", "en");
      setSelected("GB");
    }
  }, []);

  const printHeader = () => {
    if (location && location.pathname) {
      switch (true) {
        case location.pathname.indexOf("/matches") != -1:
          return <div>{t("headers.games")}</div>;
        case location.pathname.indexOf("/community") != -1:
          return <div>{t("headers.community")}</div>;
        case location.pathname.indexOf("/profile") != -1:
          return <div>{t("headers.profile")}</div>;
        case location.pathname.indexOf("/shinguards") != -1:
          return <div>{t("headers.shinguards")}</div>;
        case location.pathname.indexOf("/store") != -1:
          return <div>{t("headers.store")}</div>;
        case location.pathname.indexOf("/match") != -1:
          return <div>{t("headers.session")}</div>;
        case location.pathname == "/":
          return <div>{t("headers.games")}</div>;
      }
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [image, setImage] = useState("");
  const { window } = props;
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const checkImgUrl = (url: String) => {
    fetch(url)
      .then((res) => {
        if (res.status == 404) {
          setImage("");
        } else {
          setImage(
            `https://live.humanox.com/usermedia/userpics/${auth.userData.id}.jpg`
          );
        }
      })
      .catch((e) => {
        setImage("");
      });
  };
  const checkImgUrlId = (url: String) => {
    fetch(url)
      .then((res) => {
        if (res.status == 404) {
          setImage("");
        } else {
          setImage(`https://live.humanox.com/usermedia/userpics/${id}.jpg`);
        }
      })
      .catch((e) => {
        setImage("");
      });
  };
  useEffect(() => {
    if (auth && auth.userData) {
      checkImgUrl(
        `https://live.humanox.com/usermedia/userpics/${auth.userData.id}.jpg`
      );
    }
  }, [auth]);
  useEffect(() => {
    if ((id! = -1)) {
      checkImgUrlId(`https://live.humanox.com/usermedia/userpics/${id}.jpg`);
    }
  }, [id]);
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [liveTag, setLiveTag] = useState(false);
  const { data: liveMatchData, refetch: refetchLiveMatchData } = useQuery(
    CHECK_LIVE_MATCH,
    {
      variables: {
        userId:
          userData && userData.id
            ? userData.id
            : id != -1
            ? parseFloat(id)
            : -1,
      },
    }
  );
  const [
    checkMatchEnded,
    { data: matchended, loading, stopPolling },
  ] = useLazyQuery(CHECK_MATCH_ENDED, {
    variables: { matchid: -1, userid: -1 },
    pollInterval: 10000,
  });

  const liveMatch = liveMatchData && liveMatchData.checklivematch;
  useEffect(() => {
    if (liveMatch && liveMatch.id && liveMatch.startTime) {
      setLiveTag(true);
      checkMatchEnded({
        variables: {
          matchid: liveMatch.id,
          userid: id != -1 ? parseFloat(id) : userData.id,
        },
      });
    }
  }, [liveMatch]);

  useEffect(() => {
    if (matchended && matchended.checkmatchended == "OK" && !loading) {
      stopPolling;
      setLiveTag(false);
    }
  });

  useEffect(() => {
    if (selected == "ES") {
      localStorage.setItem("lang", "es");
      i18next.changeLanguage("es");
      dayjs.locale(es);
    } else {
      localStorage.setItem("lang", "en");
      i18next.changeLanguage("en");
      dayjs.locale(en);
    }
  }, [selected]);
  return (
    <div style={{ marginBottom: 20 }}>
      {id == -1 && userData && (
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" className={classes.title}>
              <div className={classes.headerTittleContainer}>
                <div className={classes.titleText}>{printHeader()}</div>
                {liveTag && (
                  <div className={classes.titleLive}>
                    <Content native>
                      {(props) => (
                        <animated.div
                          style={{
                            backgroundColor: "red",
                            padding: 8,
                            borderRadius: "50%",
                            width: 8,
                            height: 8,
                            marginRight: 5,
                            ...props,
                          }}
                        />
                      )}
                    </Content>
                    {t("common.live")}
                  </div>
                )}
              </div>
            </Typography>
            {auth.userData && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {/* {image && <img src={image} className={classes.userIcon} />}
                  {!image && <AccountCircle />} */}
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={() => handleClose()}
                >
                  <MenuItem
                    onClick={() => {
                      setShowMenu(false);
                      handleClose();
                      history.push("/profile");
                    }}
                  >
                    {t("menu.profile")}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setShowMenu(false);
                      handleClose();
                      history.push("/store");
                    }}
                  >
                    {t("menu.store")}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setShowMenu(false);
                      handleClose();
                      auth.logOut();
                    }}
                  >
                    {t("menu.logout")}
                  </MenuItem>
                </Menu>
              </div>
            )}
            {!auth.userData && (
              <div onClick={() => history.push("/login")}>
                {t("loginScreen.login")}
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
      {id == -1 && userData && (
        <Drawer
          container={container}
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <List>
            <ListItem button key={1}>
              <ListItemIcon>
                <img src={logo} className={classes.logo} />
              </ListItemIcon>
            </ListItem>
            <ListItem
              button
              key={"Mis espinilleras"}
              onClick={() => {
                setMobileOpen(!mobileOpen);
                history.push("/shinguards");
              }}
            >
              <ListItemIcon>
                <img src={Shinguards} className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary={t("menu.myshinguards").toUpperCase()} />
            </ListItem>
            <ListItem
              button
              key={"Mis partidos"}
              onClick={() => {
                setMobileOpen(!mobileOpen);
                history.push("/matches");
              }}
            >
              <ListItemIcon>
                <SportsSoccerIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu.mygames").toUpperCase()} />
            </ListItem>
            <ListItem
              button
              key={"Mi comunidad"}
              onClick={() => {
                setMobileOpen(!mobileOpen);
                history.push("/community");
              }}
            >
              <ListItemIcon>
                {/* <img src={comunnityIcon} className={classes.menuIcon} /> */}
                <PeopleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu.mycomunity").toUpperCase()} />
            </ListItem>
          </List>
        </Drawer>
      )}
      {id != -1 && (
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <div className={classes.headerTittleContainer}>
                <div className={classes.titleText}>{printHeader()}</div>
                {liveTag && (
                  <div className={classes.titleLive}>
                    <Content native>
                      {(props) => (
                        <animated.div
                          style={{
                            backgroundColor: "red",
                            padding: 8,
                            borderRadius: "50%",
                            width: 8,
                            height: 8,
                            marginRight: 5,
                            ...props,
                          }}
                        />
                      )}
                    </Content>
                    {t("common.live")}
                  </div>
                )}
              </div>
            </Typography>
            <ReactFlagsSelect
              selected={selected}
              onSelect={(code) => setSelected(code)}
              countries={["ES", "GB"]}
              showSelectedLabel={false}
              showSecondarySelectedLabel={false}
              showOptionLabel={false}
              showSecondaryOptionLabel={false}
              className={classes.flagSelect}
            />
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {/* {image && <img src={image} className={classes.userIcon} />}
              {!image && <AccountCircle />} */}
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={() => handleClose()}
            >
              <MenuItem
                onClick={() => {
                  setShowMenu(false);
                  handleClose();

                  history.push({
                    pathname: `/admin/profile/${id}`,
                    state: { id },
                  });
                }}
              >
                {t("menu.profile")}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShowMenu(false);
                  handleClose();
                  history.push({
                    pathname: `/admin/store/${id}`,
                    state: { id },
                  });
                }}
              >
                {t("menu.store")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowMenu(false);
                  handleClose();
                  admin.logOut();
                  localStorage.clear();
                  history.push("/login");
                }}
              >
                {t("menu.exit")}
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      )}
      {id != -1 && (
        <Drawer
          container={container}
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <List>
            <ListItem button key={1}>
              <ListItemIcon>
                <img src={logo} className={classes.logo} />
              </ListItemIcon>
            </ListItem>
            <ListItem
              button
              key={"Mis espinilleras"}
              onClick={() => {
                setMobileOpen(!mobileOpen);
                history.push({
                  pathname: `/admin/shinguards/${id}`,
                  state: { id },
                });
              }}
            >
              <ListItemIcon>
                <img src={Shinguards} className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText primary={t("menu.myshinguards").toUpperCase()} />
            </ListItem>
            <ListItem
              button
              key={"Mis sesiones"}
              onClick={() => {
                setMobileOpen(!mobileOpen);
                history.push({
                  pathname: `/admin/matches/${id}`,
                  state: { id },
                });
              }}
            >
              <ListItemIcon>
                <SportsSoccerIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu.mygames").toUpperCase()} />
            </ListItem>
            <ListItem
              button
              key={"Mi comunidad"}
              onClick={() => {
                setMobileOpen(!mobileOpen);
                history.push({
                  pathname: `/admin/community/${id}`,
                  state: { id },
                });
              }}
            >
              <ListItemIcon>
                {/* <img src={comunnityIcon} className={classes.menuIcon} /> */}
                <PeopleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary={t("menu.mycomunity").toUpperCase()} />
            </ListItem>
          </List>
        </Drawer>
      )}
    </div>
  );
};
export default Header;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    logo: {
      width: 200,
      height: 50,
    },
    menuIcon: {
      width: 18,
      height: 30,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      "& a": {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: `white`,
      },
    },
    userIcon: {
      width: 24,
      height: "auto",
      borderRadius: "50%",
    },
    headerTittleContainer: {
      display: "flex",
    },
    titleText: {
      width: "50%",
    },
    titleLive: {
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    flagSelect: {
      paddingBottom: 0,
      "& button": {
        border: "none",
        outline: "none",
        "& :focus": {
          border: "none",
          outline: "none",
        },
      },
    },
  })
);
