import React, { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface IRemoveMatchDialogProps {
  name: String;
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

const RemoveMatchDialog: FC<IRemoveMatchDialogProps> = ({
  name,
  open,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t("removeMatchDialog.removeTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("removeMatchDialog.removeText", { name })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="contained">
          {t("common.cancel")}
        </Button>
        <Button onClick={onOk} color="primary" variant="contained">
          {t("common.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoveMatchDialog;
