import React, { FC, useEffect } from "react";
import { Keyframes, animated, config } from "react-spring/renderprops";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const Content = Keyframes.Spring(async (next) => {
  while (true) {
    await next({
      from: { opacity: 0 },
      opacity: 1,
      fontSize: 25,
      height: 15,
      width: 15,
      backgroundColor: "#ab2a3e",
      borderRadius: 50,
    });
    await next({
      from: { opacity: 1 },
      opacity: 0,
    });
  }
});

const LiveTag: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Content native>
        {(props) => <animated.div style={{ ...props }} />}
      </Content>
      <span className={classes.liveText}>Live</span>
    </div>
  );
};

export default LiveTag;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
      position: "relative",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
    },
    liveText: {
      marginLeft: 5,
    },
  })
);
