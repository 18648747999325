import React, { FC, useEffect, useState } from "react";
import { Container, createStyles, Grid, makeStyles } from "@material-ui/core";
import { Keyframes, animated, config } from "react-spring/renderprops";
import delay from "delay";

import ShinguardConnected from "../lib/assets/shinguard.png";
import ShinguardWhite from "../lib/assets/shinguard-white.png";
import ShinguardGreen from "../lib/assets/shinguard-green.png";
import ShinguardDisconnected from "../lib/assets/disconected-shinguards.png";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Battery20Icon from "@material-ui/icons/Battery20";
import Battery50Icon from "@material-ui/icons/Battery50";
import Battery90Icon from "@material-ui/icons/Battery90";
import { useTranslation } from "react-i18next";

export enum ShinguardState {
  notSync,
  Sync,
  On,
}

export enum ShinguardType {
  Left,
  Right,
}

export interface IShinguardsProps {
  state: ShinguardState;
  calibrating?: ShinguardType;
  progressLeft?: number;
  progressRight?: number;
  batteryLeft?: any;
  batteryRight?: any;
}

const Content = Keyframes.Spring(async (next) => {
  while (true) {
    await next({
      from: { opacity: 0 },
      opacity: 1,
    });
    await next({
      from: { opacity: 1 },
      opacity: 0,
    });
  }
});

const Shinguards: FC<IShinguardsProps> = ({
  state,
  calibrating,
  progressLeft,
  progressRight,
  batteryLeft,
  batteryRight,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const hl =
    state !== ShinguardState.notSync && !(calibrating === ShinguardType.Right);
  const hr =
    state !== ShinguardState.notSync && !(calibrating === ShinguardType.Left);
  return (
    <Container>
      <Grid container>
        <Grid item xs={6}>
          {hl && (
            <div className={classes.shinguardContainer}>
              <Content native>
                {(props) => (
                  <animated.img
                    style={{
                      position: "absolute",
                      ...props,
                    }}
                    src={
                      state === ShinguardState.Sync
                        ? ShinguardWhite
                        : ShinguardGreen
                    }
                    className={classes.shinguardConnected}
                    alt="Shinguard"
                  />
                )}
              </Content>
              <img
                src={ShinguardConnected}
                alt="shinguard-connected"
                className={classes.shinguard}
              />
              {progressLeft && progressLeft != -1 && (
                <CircularProgressWithLabel progress={progressLeft} />
              )}

              {parseInt(batteryLeft) < 35 && (
                <div
                  className={classes.batteryContainer}
                  style={{ color: "#ab2a3e" }}
                >
                  <Battery20Icon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                  {batteryLeft}%
                </div>
              )}
              {parseInt(batteryLeft) <= 70 && parseInt(batteryLeft) >= 35 && (
                <div
                  className={classes.batteryContainer}
                  style={{ color: "#ffa600" }}
                >
                  <Battery50Icon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                  {batteryLeft}%
                </div>
              )}
              {parseInt(batteryLeft) > 70 && (
                <div
                  className={classes.batteryContainer}
                  style={{ color: "#3AAA35" }}
                >
                  <Battery90Icon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                  {batteryLeft}%
                </div>
              )}
              <p className={classes.shinguardConnectedText}>
                {t("shinguardsScreen.shinguards.left")}
              </p>
            </div>
          )}
          {!hl && (
            <div className={classes.shinguardContainer}>
              <img
                src={ShinguardDisconnected}
                alt="ShinguardDisconnected"
                className={classes.shinguard}
              />

              <p className={classes.shinguardConnectedText}>
                {t("shinguardsScreen.shinguards.left")}
              </p>
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          {hr && (
            <div className={classes.shinguardContainer}>
              <Content native>
                {(props) => (
                  <animated.img
                    style={{
                      ...props,
                    }}
                    src={
                      state === ShinguardState.Sync
                        ? ShinguardWhite
                        : ShinguardGreen
                    }
                    alt="Shinguard"
                    className={classes.shinguardConnected}
                  />
                )}
              </Content>

              <img
                src={ShinguardConnected}
                alt="shinguard-connected"
                className={classes.shinguard}
              />
              {progressRight && progressRight != -1 && (
                <CircularProgressWithLabel progress={progressRight} />
              )}
              {parseInt(batteryRight) < 35 && (
                <div
                  className={classes.batteryContainer}
                  style={{ color: "#ab2a3e" }}
                >
                  <Battery20Icon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                  {batteryRight}%
                </div>
              )}
              {parseInt(batteryRight) <= 70 && parseInt(batteryRight) >= 35 && (
                <div
                  className={classes.batteryContainer}
                  style={{ color: "#ffa600" }}
                >
                  <Battery50Icon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                  {batteryRight}%
                </div>
              )}
              {parseInt(batteryRight) > 70 && (
                <div
                  className={classes.batteryContainer}
                  style={{ color: "#3AAA35" }}
                >
                  <Battery90Icon
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                  {batteryRight}%
                </div>
              )}
              <p className={classes.shinguardConnectedText}>
                {t("shinguardsScreen.shinguards.right")}
              </p>
            </div>
          )}
          {!hr && (
            <div className={classes.shinguardContainer}>
              <img
                src={ShinguardDisconnected}
                alt="ShinguardDisconnected"
                className={classes.shinguard}
              />

              <p className={classes.shinguardConnectedText}>
                {t("shinguardsScreen.shinguards.right")}
              </p>
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Shinguards;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shinguardContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      height: "100%",
      minHeight: 400,
      [theme.breakpoints.down("sm")]: {
        minHeight: 350,
      },
    },
    batteryContainer: {
      position: "absolute",
      bottom: "40px",
    },
    shinguard: {
      [theme.breakpoints.down("sm")]: {
        height: 225,
        width: 125,
      },
      height: 300,
      width: 150,
      position: "absolute",
    },
    shinguardConnected: {
      [theme.breakpoints.down("sm")]: {
        height: 265,
        width: 180,
      },
      height: 350,
      width: 215,
    },
    shinguardConnectedText: {
      position: "absolute",
      bottom: 0,
    },
    shinguardUpdate: {
      position: "absolute",
      bottom: 0,
    },
  })
);
