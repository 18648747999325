import React, { FC } from "react";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Dialog,
  DialogContentText,
  Modal,
  Theme,
} from "@material-ui/core";
import { Height } from "@material-ui/icons";

export interface ISpinnerProps {
  open: boolean;
  message: String;
  hideSpinner?: boolean;
}

const Spinner: FC<ISpinnerProps> = ({ open, message, hideSpinner }) => {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modal}>
      <div>
        {message && <h2>{message}</h2>}
        {!hideSpinner && (
          <CircularProgress color="primary" className={classes.spinner} />
        )}
      </div>
    </Modal>
  );
};

export default Spinner;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& div:focus": {
        outline: "none",
      },
      textAlign: "center",
    },
    spinner: {
      width: "70px !important",
      height: "70px !important",
    },
  })
);
