import React, { createContext, FC, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    newLogin(email: $email, pwd: $password) {
      id
      uuid
      email
      name
      fullname
      jwt
    }
  }
`;

export const AuthContext = createContext(null);

export interface IAuthProviderProps {
  userData: any;
  loading: boolean;
  onUserDataChange: (userData: any) => void;
}

export const AuthProvider: FC<IAuthProviderProps> = ({
  userData,
  loading,
  onUserDataChange,
  children,
}) => {
  //console.log("Valor de windows", window);
  const [login] = useMutation(LOGIN_MUTATION);
  const authContext = {
    logIn: async (email: string, password: string) => {
      const result = await login({ variables: { email, password } });
      localStorage.setItem("userData", JSON.stringify(result.data.newLogin));
      onUserDataChange(result.data.newLogin);
    },
    logOut: async () => {
      onUserDataChange(null);
      localStorage.removeItem("userData");
    },
    isLoading: loading,
    isLoggedIn: !!(userData && userData.jwt),
    fetch: (url, options = {}) =>
      fetch(url, {
        ...options,
        headers: {
          Authorization: `Bearer ${userData.jwt}`,
        },
      }),
    userData,
  };

  return (
    <AuthContext.Provider value={authContext}>{children} </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export default useAuth;
