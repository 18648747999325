import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import excellent from "../lib/assets/excellentsignal.png";
import goodsignal from "../lib/assets/goodsignal.png";
import fairsignal from "../lib/assets/fairsignal.png";
import poorsignal from "../lib/assets/poorsignal.png";
import nosignal from "../lib/assets/nosignal.png";
import useAuth from "../Services/Auth";
import { useLocation } from "react-router-dom";
import useSocket from "../Services/Sockets";

export interface ISignalShinguardsModalProps {
  open: boolean;
  shinguards: Number;
  onCancel?: () => void;
  onOk?: () => void;
}

const SignalShinguardsModal: FC<ISignalShinguardsModalProps> = ({
  open,
  shinguards,
  onCancel,
  onOk,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const socket = useSocket();
  const location = useLocation();
  let id = auth && auth.userData && auth.userData.id;
  if (location && location.state && location.state.id) {
    id = parseFloat(location.state.id);
  }
  const [index, setIndex] = useState(0);
  const [rssi, setRssi] = useState(-110);
  const [nos, setNos] = useState(0);
  const [sq, setsq] = useState(101);
  const [statusChecked, setStatusChecked] = useState(false);
  const [errorShinguards, setErrorShinguards] = useState(false);
  const [check, setCheck] = useState(false);
  const signalImages = [
    nosignal,
    poorsignal,
    fairsignal,
    goodsignal,
    excellent,
  ];

  useEffect(() => {
    if (open) {
      const images = setInterval(() => {
        if (index >= 4) {
          setIndex(0);
        } else {
          setIndex((index) => index + 1);
        }
      }, 1000);
      return () => {
        clearInterval(images);
      };
    }
  }, [open]);

  const getStatus = async () => {
    try {
      let result = await socket.status(shinguards.toString());
      if (result != null) {
        let cadena = result.split(",");
        setRssi(cadena[4]);
        setsq(cadena[5]);
        setNos(cadena[6]);
        setStatusChecked(true);
      } else if (result == null) {
        setStatusChecked(true);
        setErrorShinguards(true);
      }
    } catch (e) {
      setStatusChecked(true);
      setErrorShinguards(true);
      console.log(e);
    }
  };
  const signalRender = () => {
    if (nos > 0) {
      if (sq <= 15) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={excellent} />
          </p>
        );
      } else if (sq > 15 && sq <= 35) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={goodsignal} />
          </p>
        );
      } else if (sq > 35 && sq <= 60) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={fairsignal} />
          </p>
        );
      } else if (sq > 60 && sq <= 100) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={poorsignal} />
          </p>
        );
      }
    } else if (nos == 0 || nos == null) {
      return (
        <div>
          <p>{t("signalshinguardsmodal.noSignal")}</p>
          <p style={{ textAlign: "center" }}>
            <img src={nosignal} />
          </p>
        </div>
      );
    }
  };
  useEffect(() => {
    if (shinguards && check) {
      getStatus();
    }
  }, [shinguards, check]);

  if (index >= 5) setIndex(0);
  return (
    <Dialog
      open={open}
      onClose={() => {
        onCancel();
        setCheck(false);
        setErrorShinguards(false);
        setStatusChecked(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      {!check && (
        <div>
          <DialogTitle id="responsive-dialog-title">
            {t("signalshinguardsmodal.modalTitle")}
          </DialogTitle>
          <DialogContent>
            <p>{t("signalshinguardsmodal.step1")} </p>
            <p>{t("signalshinguardsmodal.step2")} </p>
            <p>{t("signalshinguardsmodal.step3")} </p>

            <p style={{ textAlign: "center" }}>
              <img src={signalImages[index]} alt="ShinguardsSignal" />
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} variant="contained" color="primary">
              {t("common.cancel")}
            </Button>
            <Button
              onClick={() => setCheck(true)}
              variant="contained"
              color="primary"
            >
              {t("common.check")}
            </Button>
          </DialogActions>
        </div>
      )}
      {check && (
        <div>
          {!statusChecked && !errorShinguards && (
            <div>
              <DialogTitle>{t("signalshinguardsmodal.checking")}</DialogTitle>
              <p style={{ textAlign: "center" }}>
                <img src={signalImages[index]} alt="ShinguardsSignal" />
              </p>
            </div>
          )}
          {statusChecked && errorShinguards && (
            <div>
              <DialogTitle id="responsive-dialog-title">
                {t("signalshinguardsmodal.errorTryingConnectTitle")}
              </DialogTitle>
              <DialogContent>
                <span>
                  {t("signalshinguardsmodal.errorTriyingConnectText")}
                </span>
                <span>{t("signalshinguardsmodal.tryAgainLater")} </span>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel();
                    setCheck(false);
                    setErrorShinguards(false);
                    setStatusChecked(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setErrorShinguards(false);
                    setStatusChecked(false);
                    setCheck(true);
                    getStatus();
                  }}
                >
                  {t("common.retry")}
                </Button>
              </DialogActions>
            </div>
          )}
          {statusChecked && !errorShinguards && (
            <div>
              <DialogTitle id="responsive-dialog-title">
                {t("signalshinguardsmodal.qualitySignal")}
              </DialogTitle>
              <DialogContent>{signalRender()}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onOk();
                    setCheck(false);
                    setErrorShinguards(false);
                    setStatusChecked(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t("common.accept")}
                </Button>
              </DialogActions>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};
export default SignalShinguardsModal;
