import React, { FC, useContext, useEffect, useState } from "react";

import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import excellent from "../lib/assets/excellentsignal.png";
import goodsignal from "../lib/assets/goodsignal.png";
import fairsignal from "../lib/assets/fairsignal.png";
import poorsignal from "../lib/assets/poorsignal.png";
import nosignal from "../lib/assets/nosignal.png";

import { useTranslation } from "react-i18next";

import Spinner from "./Spinner";
import useSocket from "../Services/Sockets";

export interface IStartMatchDialogProps {
  open: boolean;
  shinguards: Number;
  onOk?: (data: any) => void;
  onCancel?: () => void;
}

const StartMacthDialog: FC<IStartMatchDialogProps> = ({
  open,
  shinguards,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();
  //const socket = useContext(SocketContext);
  const classes = useStyles();
  const socket = useSocket();

  const [name, setName] = useState("");
  const [stadium_name, setStadiumName] = useState("");
  const [location, setLocation] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [match_type, setMatchType] = useState();
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(true);
  const [error, setError] = useState();
  const [index, setIndex] = useState(0);
  const [nos, setNos] = useState(0);
  const [sq, setsq] = useState(101);
  const [tryingToRecord, setTryingToRecord] = useState(false);
  const [showStatusError, setShowStatusError] = useState(false);
  const [startingMatch, setStartingMatch] = useState(false);

  const [statusChecked, setStatusChecked] = useState(false);
  const [errorShinguards, setErrorShinguards] = useState(false);
  const [check, setCheck] = useState(false);

  const signalImages = [
    nosignal,
    poorsignal,
    fairsignal,
    goodsignal,
    excellent,
  ];
  useEffect(() => {
    if (open) {
      const images = setInterval(() => {
        if (index >= 4) {
          setIndex(0);
        } else {
          setIndex((index) => index + 1);
        }
      }, 1000);

      return () => {
        clearInterval(images);
      };
    }
  }, [open]);

  const getStatus = async () => {
    try {
      let result = await socket.status(shinguards.toString());
      if (result != null) {
        let cadena = result.split(",");
        setsq(cadena[5]);
        setNos(cadena[6]);
        setStatusChecked(true);
      } else if (result == null) {
        setStatusChecked(true);
        setErrorShinguards(true);
      }
    } catch (e) {
      setStatusChecked(true);
      setErrorShinguards(true);
      console.log(e);
    }
  };

  const signalRender = () => {
    if (nos > 0) {
      if (sq <= 15) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={excellent} />
          </p>
        );
      } else if (sq > 15 && sq <= 35) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={goodsignal} />
          </p>
        );
      } else if (sq > 35 && sq <= 60) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={fairsignal} />
          </p>
        );
      } else if (sq > 60 && sq <= 100) {
        return (
          <p style={{ textAlign: "center" }}>
            <img src={poorsignal} />
          </p>
        );
      }
    } else if (nos == 0 || nos == null) {
      return (
        <div>
          <p>{t("signalshinguardsmodal.noSignal")}</p>
          <p style={{ textAlign: "center" }}>
            <img src={nosignal} />
          </p>
        </div>
      );
    }
  };

  if (index >= 5) setIndex(0);
  let matchData = {};

  const startMatchCheck = async () => {
    setTryingToRecord(true);
    setStartingMatch(true);
    let result = await socket.status(shinguards.toString());
    if (result != null) {
      startMatch();
    } else if (result == null) {
      setTryingToRecord(false);
      setShowStatusError(true);
      setTimeout(() => {
        setShowStatusError(false);
        setStartingMatch(false);

        onCancel();
      }, 2000);
    }
  };

  const startMatch = () => {
    matchData = {
      name: name ? name : "AUTO",
      stadium_name: stadium_name ? stadium_name : null,
      location: location ? location : null,
      team1: team1 ? team1 : null,
      team2: team2 ? team2 : null,
      match_type,
    };
    setTryingToRecord(false);
    onOk(matchData);
  };

  return (
    <div>
      <Spinner
        open={tryingToRecord}
        message={t("matchesScreen.creatingMatch")}
      />
      <Spinner
        open={showStatusError}
        message={t("matchesScreen.errorCreatingMatch")}
        hideSpinner
      />
      {!startingMatch && (
        <Dialog
          open={open}
          onClose={() => {
            onCancel();
            setStep1(false);
            setStep2(true);
            setCheck(false);
            setErrorShinguards(false);
            setStatusChecked(false);
          }}
          aria-labelledby="responsive-dialog-title"
        >
          {step1 && (
            <div>
              <DialogTitle id="responsive-dialog-title">
                {t("startMatchDialog.title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("startMatchDialog.text1")}
                </DialogContentText>
                <DialogContentText>
                  {t("startMatchDialog.text2")}
                </DialogContentText>
                <fieldset className={classes.fieldset}>
                  <legend className={classes.legend}>
                    {t("startMatchDialog.matchData")}
                  </legend>

                  <TextField
                    id="standard-basic"
                    label={t("common.form.name")}
                    style={{ width: "100%", margin: "10px 0px" }}
                    value={name}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setName(event.target.value as string);
                    }}
                  />
                  <TextField
                    id="standard-basic"
                    label={t("common.form.stadiumName")}
                    style={{ width: "100%", margin: "10px 0px" }}
                    value={stadium_name}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setStadiumName(event.target.value as string);
                    }}
                  />
                  <TextField
                    id="standard-basic"
                    label={t("common.form.localization")}
                    style={{ width: "100%", margin: "10px 0px" }}
                    value={location}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setLocation(event.target.value as string);
                    }}
                  />
                  <TextField
                    id="standard-basic"
                    label={t("common.form.team1")}
                    style={{ width: "100%", margin: "10px 0px" }}
                    value={team1}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setTeam1(event.target.value as string);
                    }}
                  />
                  <TextField
                    id="standard-basic"
                    label={t("common.form.team2")}
                    style={{ width: "100%", margin: "10px 0px" }}
                    value={team2}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setTeam2(event.target.value as string);
                    }}
                  />
                  <TextField
                    select
                    label={t("common.form.matchType")}
                    id="demo-simple-select"
                    style={{ width: "100%", margin: "10px 0px" }}
                    value={match_type}
                    onChange={(
                      event: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      setMatchType(event.target.value);
                    }}
                  >
                    <MenuItem value={1}>
                      {t("common.form.officialType")}
                    </MenuItem>
                    <MenuItem value={2}>
                      {t("common.form.friendlyMatch")}
                    </MenuItem>
                    <MenuItem value={3}>
                      {t("common.form.trainingMatch")}
                    </MenuItem>
                  </TextField>
                </fieldset>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel();
                    setStep1(false);
                    setStep2(true);
                    setCheck(false);
                    setErrorShinguards(false);
                    setStatusChecked(false);
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t("common.cancel")}
                </Button>

                <Button
                  onClick={() => {
                    setStep1(false);
                    setStep2(true);
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t("common.next")}
                </Button>
              </DialogActions>
            </div>
          )}
          {step2 && (
            <div>
              {!check && (
                <div>
                  <DialogTitle id="responsive-dialog-title">
                    {t("signalshinguardsmodal.modalTitle2")}
                  </DialogTitle>
                  <DialogContent>
                    <p>{t("signalshinguardsmodal.step1")} </p>
                    <p>{t("signalshinguardsmodal.step2")} </p>
                    <p>{t("signalshinguardsmodal.step3")} </p>

                    <p style={{ textAlign: "center" }}>
                      <img src={signalImages[index]} alt="ShinguardsSignal" />
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        onCancel();
                        setStep1(false);
                        setStep2(true);
                        setCheck(false);
                        setErrorShinguards(false);
                        setStatusChecked(false);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {t("common.cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        getStatus();
                        setCheck(true);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {t("common.check")}
                    </Button>
                    <Button
                      onClick={() => {
                        startMatchCheck();
                        setStep1(false);
                        setStep2(true);
                        setCheck(false);
                        setErrorShinguards(false);
                        setStatusChecked(false);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {t("common.record")}
                    </Button>
                  </DialogActions>
                </div>
              )}
              {check && (
                <div>
                  {!statusChecked && !errorShinguards && (
                    <div>
                      <DialogTitle>
                        {t("signalshinguardsmodal.checking")}
                      </DialogTitle>
                      <p style={{ textAlign: "center" }}>
                        <img src={signalImages[index]} alt="ShinguardsSignal" />
                      </p>
                    </div>
                  )}
                  {statusChecked && errorShinguards && (
                    <div>
                      <DialogTitle id="responsive-dialog-title">
                        {t("signalshinguardsmodal.errorTryingConnectTitle")}
                      </DialogTitle>
                      <DialogContent>
                        {t("signalshinguardsmodal.errorTriyingConnectText")}
                      </DialogContent>
                      <DialogContent>
                        {t("signalshinguardsmodal.tryAgain")}
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            onCancel();
                            setStep1(false);
                            setStep2(true);
                            setErrorShinguards(false);
                            setStatusChecked(false);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          {t("common.cancel")}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setErrorShinguards(false);
                            setStatusChecked(false);
                            setCheck(true);
                            getStatus();
                          }}
                        >
                          {t("common.retry")}
                        </Button>
                      </DialogActions>
                    </div>
                  )}
                  {statusChecked && !errorShinguards && (
                    <div>
                      <DialogTitle id="responsive-dialog-title">
                        {t("signalshinguardsmodal.qualitySignal")}
                      </DialogTitle>
                      <DialogContent>{signalRender()}</DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            onCancel();
                            setStep1(false);
                            setStep2(true);
                            setCheck(false);
                            setErrorShinguards(false);
                            setStatusChecked(false);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          {t("common.cancel")}
                        </Button>
                        <Button
                          onClick={() => {
                            startMatch();
                            setStep1(false);
                            setStep2(true);
                            setCheck(false);
                            setErrorShinguards(false);
                            setStatusChecked(false);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          {t("common.accept")}
                        </Button>
                      </DialogActions>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Dialog>
      )}
    </div>
  );
};

export default StartMacthDialog;

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    fieldset: {
      border: "2px solid",
      borderColor: palette.primary.main,
      borderRadius: 8,
    },
    legend: {
      textTransform: "uppercase",
      fontSize: 14,
    },
  })
);
